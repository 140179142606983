import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import PageTitle from 'components/pageTitle';

import Sort from 'components/sort';
import SearchBar from 'components/searchBar';
import Table from 'components/table';

import { ReactComponent as AddUser } from 'statics/icons/addUser.svg';

import ModalDialogbox from 'components/modals/dialogbox';
import apiServer from 'api/server';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import styles from './styles.module.sass';

function Users() {
  const [rows, setRows] = useState([]);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [openModalSucess, setOpenModalSucess] = useState(false);
  const [openModalError, setOpenModalError] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [userRole, setUserRole] = useState('')

  const navigate = useNavigate();

  useEffect(() => {
    apiServer.get('users/all').then((response) => {

      const userList = response.data.users.map((user) => {
        let role = 'Comum';

        switch (user.role) {
          case 'admin':
            role = 'Administrador';
            break;

          case 'common':
            role = 'Comum';

            break;
          case 'coordinator':
            role = 'Coordenador';
            break;

          default:
            role = 'Comum';
            break;
        }

        return {
          id: user.id,
          name: user.name,
          email: user.email,
          userType: role,
          brands: user.brand
        };
      });

      setRows(userList);
    });

    const token = Cookies.get('token')
    const tokenDecode = jwtDecode(token)

    apiServer.get(`/users/search/${tokenDecode.sub}`)
      .then((response) => {
        setUserRole(response.data.user.role)
      })
    
  }, []);

  const handleSort = (sortRows) => {
    setRows(sortRows);
  };

  const handleSearch = useCallback(
    (event) => {
      const {
        target: { value },
      } = event;

      const regex = new RegExp(value, 'i');

      setRows(rows.filter((row) => regex.test(row.name)));
    },
    [rows],
  );

  const handleEdit = (row) => {
    navigate(`editar/${row.id}`);
  };

  const handleOpenModal = () => {
    setOpenModalConfirm(!openModalConfirm);
    setDeleteId(3);
  };

  const handleCloseModal = () => {
    setOpenModalConfirm(false);
    setOpenModalSucess(false);
    setOpenModalError(false);
  };

  const handleDelete = useCallback(async () => {
    setOpenModalConfirm(!openModalConfirm);
    await apiServer
      .delete(`users/delete/${deleteId}`)
      .then(() => {
        setOpenModalSucess(true);
      })
      .catch(() => {
        setOpenModalError(true);
      });
  }, [deleteId]);

  const handleDisableTableActions = useCallback((row) => userRole === 'admin' || (userRole === 'coordinator' && row.userType !== 'Administrador'), [userRole])

  return (
    <div className={styles.container}>
      <span
        className={styles.addUser}
        onClickCapture={() => navigate('adicionar-usuario')}
      >
        <AddUser className={styles.addUserIcon} />
      </span>

      <PageTitle title="Usuários" />

      <SearchBar rows={rows} onSearch={handleSearch} />

      <Sort hasDate onSort={(sortRows) => handleSort(sortRows)} rows={rows} />

      <Table
        page="users"
        rows={rows}
        numberOfRows={5}
        onClickEdit={handleEdit}
        removeTrash
        onDisableRule={handleDisableTableActions}
        onClickTrash={handleOpenModal}
      />

      <ModalDialogbox
        open={openModalConfirm}
        type="options"
        onClose={handleCloseModal}
        text="Tem certeza de que deseja excluir esse Usuário?"
        buttonsLabel={['VOLTAR', 'EXCLUIR']}
        onLeftButton={handleCloseModal}
        onRightButton={handleDelete}
      />

      <ModalDialogbox
        open={openModalSucess}
        type="success"
        onClose={handleCloseModal}
        text="O usuário foi exluida com sucesso!"
      />

      <ModalDialogbox
        open={openModalError}
        type="error"
        onClose={handleCloseModal}
        text="Erro ao concluir ação. Por favor, tente novamente mais tarde ou entre em contato com o suporte."
      />
    </div>
  );
}

export default Users;
