import React from 'react';

import InputComponent from 'components/input';

import { Controller } from 'react-hook-form';

import styles from './styles.module.sass';

function FormCategory({ control, titleValue }) {
  return (
    <div className={styles.form}>
      <Controller
        name="name"
        control={control}
        rules={{ required: {
          value: true, 
          message: 'É necessario dar um nome a categoria'
        } }}
        render={({ field: { onChange }, fieldState: {error} }) => (
          <InputComponent
            label="Titulo"
            multiline
            defaultValue={titleValue || ''}
            onChange={onChange}
            placeholder="Insira um título para a categoria"
            className={styles.input}
            error={error?.message ?? false}
          />
        )}
      />
    </div>
  );
}

export default FormCategory;
