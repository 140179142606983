import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as Clock } from 'statics/icons/clock.svg';
import { ReactComponent as Eye } from 'statics/icons/visiblePassword.svg';

import Button from 'components/button';
import dateFormat from 'utils/dateFormat';

import brandColors from 'mockdata/brandColors.json';

import apiServer from 'api/server';
import ModalDialogbox from 'components/modals/dialogbox';
import HandleErrors from 'utils/handleErrors';
import styles from './styles.module.sass';

function CardNotification({ data, type, changeCardType }) {
  const [status, setStatus] = useState();
  const [colorStatusLabel, setColorStatusLabel] = useState();

  const [buttonsData, setButtonsData] = useState([]);
  const [disabledButton, setDisabledButton] = useState(false);

  const [isModalOpen, setModalOpen] = useState(false);
  const [isErrorOpen, setErrorOpen] = useState(false);

  const navigate = useNavigate()

  const handleCloseModal = useCallback(() => {
    setModalOpen(false);
  }, [isModalOpen]);

  const handleEncerrarClick = useCallback(async () => {
    setModalOpen(false);

    await apiServer
      .put(`activations/stop/${data.id}`)
      .then(() => {
        changeCardType({
          oldType: data.status,
          newType: 'blocked',
          id: data.id,
        });
      })
      .catch((err) => {
        HandleErrors(err.response.status)
        setErrorOpen(true);
      });
  }, [data]);

  useEffect(() => {
    switch (type) {
      case 'active':
        setStatus('ativa');
        setColorStatusLabel(styles.labelActive);

        setButtonsData([
          {
            id: 0,
            label: 'PAUSAR',
            handleOnClick: async () => {
              setDisabledButton(true);

              await apiServer
                .put(`activations/pause/${data.id}`)
                .then(() => {
                  changeCardType({
                    oldType: 'active',
                    newType: 'paused',
                    id: data.id,
                  });
                })
                .catch((err) => {
                  HandleErrors(err.response.status)
                });

              setDisabledButton(false);
            },
          },
          {
            id: 1,
            label: 'ENCERRAR',
            handleOnClick: async () => {
              setModalOpen(true);
            },
          },
        ]);
        break;

      case 'paused':
        setStatus('pausada');
        setColorStatusLabel(styles.labelPause);

        setButtonsData([
          {
            id: 0,
            label: 'RETOMAR',
            handleOnClick: async () => {
              setDisabledButton(true);

              await apiServer
                .put(`activations/re-activate/${data.id}`)
                .then((response) => {
                  changeCardType({
                    oldType: 'paused',
                    newType: 'active',
                    id: data.id,
                  });
                })
                .catch((err) => {
                  HandleErrors(err.response.status)
                });

              setDisabledButton(false);
            },
          },
          {
            id: 1,
            label: 'ENCERRAR',
            handleOnClick: async () => {
              setModalOpen(true);
            },
          },
        ]);
        break;

      case 'blocked':
        setStatus('encerrada');
        setColorStatusLabel(styles.labelClose);
        setDisabledButton(true);

        setButtonsData([]);
        break;

      case 'unused':
        setStatus('inativa');
        setColorStatusLabel(styles.labelUnused);

        setButtonsData([]);
        break;

      default:
        setStatus('ativa');
        break;
    }
  }, []);

  const handleView = useCallback(() => {
    navigate(`${data.id}`)
  }, [data])

  return (
    <div className={styles.container}>
      <div
        onClickCapture={handleView}
        className={styles.arrow}
      >
        <Eye />
      </div>

      <div className={styles.header}>
        <span
          className={`${styles.label} ${styles.labelStatus} ${colorStatusLabel}`}
        >
          {status}
        </span>
        {data.brands &&
          data.brands.map((cardBrand) => (
            <span
              key={cardBrand.code}
              className={styles.label}
              style={{
                backgroundColor:
                  brandColors.brands.find((e) => e.code === cardBrand.code)
                    ?.color ?? '$white',
              }}
            >
              {cardBrand.name}
            </span>
          ))}
        <p className={styles.title}>{data.title}</p>
      </div>

      <div className={styles.main}>
        <p className={styles.description}>{data.description}</p>

        <p className={styles.tag}>{data.tag?.code ?? ''}</p>

        <div>
          <p className={styles.date}>
            <Clock />
            {`Data de: ${dateFormat.fullDateTime(data.dateStart)}`}
          </p>
          <p className={styles.date}>
            <Clock />
            {`Data até: ${dateFormat.fullDateTime(data.dateEnd)}`}
          </p>
        </div>
      </div>

      <div className={styles.buttonsContainer}>
        {buttonsData &&
          buttonsData.map((button, index) => (
            <Button
              key={button.id}
              label={button.label}
              color={button?.id === 0 ? '#404085' : '#FF3333'}
              disabled={disabledButton}
              className={`
              ${index === 0 ? styles.buttonPause : styles.buttonClose}
              ${disabledButton && styles.buttonDisabled}
            `}
              isButtonWithe={!disabledButton}
              onClickCapture={async () => {
                if (!disabledButton) await button.handleOnClick();
              }}
            />
          ))}
      </div>

      {isModalOpen && (
        <ModalDialogbox
          open={isModalOpen}
          type="options"
          onClose={handleCloseModal}
          text={`Deseja mesmo encerrar a ativação ${data.description}?`}
          buttonsLabel={['VOLTAR', 'CONFIRMAR']}
          onLeftButton={handleCloseModal}
          onRightButton={handleEncerrarClick}
        />
      )}

      {isErrorOpen && (
        <ModalDialogbox
          open={isErrorOpen}
          type="error"
          onClose={() => {
            setErrorOpen(false);
          }}
          text={
            'Erro ao concluir ação. Por favor, \nTente novamente mais tarde ou entre em contato com o suporte.'
          }
        />
      )}
    </div>
  );
}

export default CardNotification;
