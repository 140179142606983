import React from 'react';

import { ReactComponent as Painel } from 'statics/icons/panel.svg';
import { ReactComponent as Notifications } from 'statics/icons/notifications.svg';
import { ReactComponent as Category } from 'statics/icons/category.svg';
import { ReactComponent as Tag } from 'statics/icons/tag.svg';
import { ReactComponent as Message } from 'statics/icons/message.svg';
import { ReactComponent as Center } from 'statics/icons/center.svg';
import { ReactComponent as User } from 'statics/icons/user.svg';

const menusData = [
  {
    id: 0,
    label: 'Painel de Notificações',
    icon: <Painel />,
    redirect: '/painel-de-notificacoes',
  },
  {
    id: 1,
    label: 'Notificações',
    icon: <Notifications />,
    redirect: '/notificacoes',
  },
  {
    id: 2,
    label: 'Categoria',
    icon: <Category />,
    redirect: '/categoria',
  },
  {
    id: 3,
    label: 'Tags',
    icon: <Tag />,
    redirect: '/tags',
  },
  {
    id: 4,
    label: 'Mensagens',
    icon: <Message />,
    redirect: '/mensagens',
  },
  {
    id: 5,
    label: 'Centro de distribuição',
    icon: <Center />,
    redirect: '/centro-de-distribuicao',
  },
  {
    id: 6,
    label: 'Usuários',
    icon: <User />,
    redirect: '/usuarios',
  },
];

export default menusData;
