// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__Olk97 {\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 50px;\n}\n.styles_container__Olk97 .styles_accessForm__ubjCs {\n  flex: 1 1;\n  margin-right: 88px;\n}", "",{"version":3,"sources":["webpack://./src/screens/access-reports/styles.module.sass"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AADF;AAGE;EACE,SAAA;EACA,kBAAA;AADJ","sourcesContent":["@import 'styles/_colors.sass'\n\n.container\n  display: flex\n  justify-content: space-between\n  margin-bottom: 50px\n\n  .accessForm\n    flex: 1\n    margin-right: 88px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__Olk97",
	"accessForm": "styles_accessForm__ubjCs"
};
export default ___CSS_LOADER_EXPORT___;
