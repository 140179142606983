import React from 'react';
import { Controller } from 'react-hook-form';

import InputComponent from 'components/input';

import styles from './styles.module.sass';

function Inputs({ control, defaultValues }) {
  return (
    <div className={styles.addUserForm}>
      <p className={styles.title}>ID DO USUÁRIO</p>

      <div className={styles.form}>
        <Controller
          name="login"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange } }) => (
            <InputComponent
              label="Nome de usuário"
              multiline
              defaultValue={defaultValues?.login}
              onChange={onChange}
              placeholder="Insira um nome de usuário"
              className={styles.inputs}
            />
          )}
        />

        <Controller
          name="name"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange } }) => (
            <InputComponent
              label="Nome completo"
              multiline
              defaultValue={defaultValues?.name}
              onChange={onChange}
              placeholder="Insira o nome completo"
              className={styles.inputs}
            />
          )}
        />

        <Controller
          name="email"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange } }) => (
            <InputComponent
              label="E-mail"
              multiline
              onChange={onChange}
              defaultValue={defaultValues?.email}
              placeholder="Insira o e-mail do usuário"
              className={styles.inputs}
            />
          )}
        />
      </div>
    </div>
  );
}

export default Inputs;
