import React, { useState, useCallback } from 'react';

import { ReactComponent as Filter } from 'statics/icons/filter.svg';
import { ReactComponent as Search } from 'statics/icons/search.svg';

import Button from 'components/button';
import Filters from 'components/modals/Filter';
import styles from './styles.module.sass';

function SearchBar({ onSearch, hasModal, handleModalSubmit }) {
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = useCallback(() => {
    setOpenModal(!openModal);
  }, [openModal]);

  return (
    <div className={styles.container}>
      <div className={styles.searchBar}>
        <div className={styles.leftItens}>
          <Search className={styles.searchIcon} />
          <input
            className={styles.input}
            onChange={onSearch}
            placeholder="Inicie uma busca"
          />
        </div>

        <Filter
          className={styles.filterIcon}
          onClickCapture={handleOpenModal}
        />
        <Filters
          handleOpen={hasModal ? openModal : null}
          handleClose={handleOpenModal}
          className={styles.filterForm}
          handleModalSubmit={handleModalSubmit}
        />
      </div>
      <Button label="PESQUISAR" className={styles.button} />
    </div>
  );
}

export default SearchBar;
