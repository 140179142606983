import React, { useCallback, useState } from 'react';
import { Controller } from 'react-hook-form';
import { DateTimePicker } from '@mui/x-date-pickers';

import { ReactComponent as Calendar } from 'statics/icons/calendar.svg';

import styles from './styles.module.sass';

function filterDates({ control }) {
  const [dateOf, setDateOf] = useState('');
  const [untilDate, setUntilDate] = useState('');

  const calendar = () => <Calendar />;

  const handleDateOf = useCallback(({ element, onChange }) => {
    const date = new Date(element.$d);

    setDateOf(date);
    onChange(date);
  }, []);

  const handleUntilDate = useCallback(({ element, onChange }) => {
    const date = new Date(element.$d);

    setUntilDate(date);
    onChange(date);
  }, []);

  return (
    <div className={styles.dateContent}>
      <h4 className={styles.label}>Por data de/data até:</h4>
      <Controller
        name="dateOf"
        control={control}
        rules={{
          required: true,
          validate: () => new Date(dateOf) < new Date(untilDate),
        }}
        render={({ field: { onChange } }) => (
          <DateTimePicker
            label="Data de"
            ampm={false}
            onChange={(element) => handleDateOf({ element, onChange })}
            sx={{
              fontSize: 15,
              flex: 1,
            }}
            className={styles.dateInputsMargin}
            slots={{
              openPickerIcon: calendar,
            }}
          />
        )}
      />

      <Controller
        name="untilDate"
        control={control}
        rules={{
          required: true,
          validate: () => new Date(dateOf) < new Date(untilDate),
        }}
        render={({ field: { onChange } }) => (
          <DateTimePicker
            label="Data até"
            onChange={(element) => handleUntilDate({ element, onChange })}
            sx={{
              fontSize: 15,
              flex: 1,
            }}
            ampm={false}
            slots={{
              openPickerIcon: calendar,
            }}
          />
        )}
      />
    </div>
  );
}

export default filterDates;
