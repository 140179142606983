import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { useNavigate } from 'react-router-dom';

import PageTitle from 'components/pageTitle';
import FormCategory from 'components/formCategory/index';
import ModalDialogbox from 'components/modals/dialogbox';
import HandleErrors from 'utils/handleErrors';
import apiServer from '../../api/server';

import styles from './styles.module.sass';

function AddCategory() {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const [isModalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState('error');
  const [modalMessage, setModalMessage] = useState('');
  const [isLoading, setLoading] = useState(false);

  const [name, setName] = useState('');

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const onSubmit = async (data) => {
    const requestData = {
      name,
      ...data,
    };
    await apiServer
      .post('category/create', requestData)
      .then((response) => {
        setLoading(false);

        if (!response.data) {
          throw new Error('Error adding the category');
        }

        setModalMessage('Categoria adicionada com sucesso!');

        setModalType('success');
        setModalOpen(true);
      })
      .catch((err) => {
        HandleErrors(err.response.status)


        if (err.response?.status === 409) {
          setModalMessage(
            'Registro já existe no banco de dados!\nPor favor, confirme as informações e tente novamente.',
          );
        } else {
          setModalMessage(
            'Erro ao concluir ação. Por favor, \nTente novamente mais tarde ou entre em contato com o suporte.',
          );
        }

        setModalType('error');
        setModalOpen(true);
        setLoading(false);
      });
  };

  const handleCloseModal = () => {
    navigate('/categoria');
  };

  return (
    <>
      <PageTitle title="Adicionar Categoria" />
      <form
        onSubmit={handleSubmit(onSubmit)}
        id="adicionar-categoria"
        className={styles.container}
      >
        <div className={styles.categoryForm}>
          <p className={styles.title}>CATEGORIA</p>
          <FormCategory
            control={control}
            value={name}
            onChange={handleNameChange}
            errors={errors}
          />
        </div>
      </form>

      {isModalOpen && (
        <ModalDialogbox
          open={isModalOpen}
          type={modalType}
          onClose={handleCloseModal}
          text={modalMessage}
        />
      )}

      {isLoading && (
        <ModalDialogbox
          open={isModalOpen}
          type={modalType}
          onRightButton={() => {}}
          onLeftButton={() => {}}
          text="Por favor, aguarde enquanto concluímos o carregamento..."
        />
      )}
    </>
  );
}

export default AddCategory;
