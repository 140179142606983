import React, { useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import useAuth from 'hooks/useAuth';
import apiServer from 'api/server';

function RequireAuth({ children }) {
  const { token, signout } = useAuth();
  const location = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    const resInterceptor = (response) => response;

    const errInterceptor = (error) => {
      if (error.response.status === 401) {
        signout();
        navigate('/login');
      }

      return Promise.reject(error);
    };

    const interceptor = apiServer.interceptors.response.use(
      resInterceptor,
      errInterceptor,
    );

    return () => apiServer.interceptors.response.eject(interceptor);
  }, [navigate]);

  if (!token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

export default RequireAuth;
