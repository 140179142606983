import apiServer from 'api/server';

async function SearchCategory({ id }) {
  try {
    const response = await apiServer.get(`category/search/${id}`);

    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
}

async function Edit({ id, name }) {
  try {
    await apiServer.put(`category/update/${id}`, {
      name,
    });
  } catch (error) {
    throw new Error(error.message);
  }
}

const EditCategoryRequests = {
  SearchCategory,
  Edit,
};

export default EditCategoryRequests;
