import React, { useState, useEffect } from 'react';
import { Controller } from 'react-hook-form';

import SelectComponent from 'components/select';

import apiServer from 'api/server';

import useAuth from 'hooks/useAuth';
import HandleErrors from 'utils/handleErrors';
import styles from './styles.module.sass';

function Selects({ control, defaultValues }) {
  const [brand, setBrand] = useState('');
  const [brands, setBrands] = useState([]);

  const { userBrands } = useAuth();

  const handleBrand = (event) => {
    setBrand(event.target.value);
  };

  useEffect(() => {
    apiServer
      .get('/brands/all')
      .then((response) => {
        const data = response.data.brands
          .map((b) => ({
            id: b.id,
            content: b.name,
          }))
          .filter((b) => !!userBrands.find((ub) => ub === b.id));

        setBrands(data);

        handleBrand({
          target: {
            value: [data[0].id],
          },
        });
      })
      .catch((err) => {
        HandleErrors(err.response.status)
        setBrands([]);
      });
  }, []);

  useEffect(() => {
    if (brands.length > 0) setBrand([brands[0].id]);
  }, [brands]);

  return (
    <div className={styles.addUserForm}>
      <div className={styles.form}>
        <p className={styles.title}>PERMISSÕES DO USUÁRIO</p>

        <Controller
          name="role"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange } }) => (
            <SelectComponent
              label="Tipo de usuário"
              onChange={onChange}
              defaultValue={defaultValues?.role}
              hasLabels={false}
              placeholder="Selecione o tipo de usuário"
              className={styles.selects}
              listSelects={[
                {
                  id: 'admin',
                  content: 'Administrador',
                },
                {
                  id: 'common',
                  content: 'Comum',
                },
                {
                  id: 'coordinator',
                  content: 'Coordenador',
                },
              ]}
            />
          )}
        />

        <p className={styles.selectionTitle}>
          Selecione a marca que o usuário pode acessar
        </p>

        <Controller
          name="brand"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange } }) => (
            <SelectComponent
              label="Marca"
              multiple
              onChange={onChange}
              placeholder="Selecione a marca"
              className={styles.selects}
              listSelects={brands}
            />
          )}
        />
      </div>
    </div>
  );
}

export default Selects;
