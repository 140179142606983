// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_title__kMIDh {\n  color: #002A67;\n  font-size: 18px;\n  font-weight: 700;\n  margin: 24px;\n}\n\n.styles_addUserForm__KEB-C {\n  flex: 1 1;\n  margin-right: 88px;\n}\n\n.styles_form__xNum2 {\n  flex-direction: column;\n  margin-top: 24px;\n}\n\n.styles_inputs__lnmsH {\n  margin-bottom: 30px !important;\n  display: flex;\n  width: 432px;\n  height: 72px;\n}", "",{"version":3,"sources":["webpack://./src/components/formUserCreation/components/input/styles.module.sass","webpack://./src/styles/_colors.sass"],"names":[],"mappings":"AAEA;EACE,cCOS;EDNT,eAAA;EACA,gBAAA;EACA,YAAA;AADF;;AAGA;EACE,SAAA;EACA,kBAAA;AAAF;;AAEA;EACE,sBAAA;EACA,gBAAA;AACF;;AACA;EACE,8BAAA;EACA,aAAA;EACA,YAAA;EACA,YAAA;AAEF","sourcesContent":["@import 'styles/_colors.sass'\n\n.title\n  color: $blue-800\n  font-size: 18px\n  font-weight: 700\n  margin: 24px\n\n.addUserForm\n  flex: 1\n  margin-right: 88px\n\n.form\n  flex-direction: column\n  margin-top: 24px\n\n.inputs\n  margin-bottom: 30px !important\n  display: flex\n  width: 432px\n  height: 72px","$background-menu: #F8F8FB\n\n$ligth-blue-600: #00559A\n\n$edit-icon: #6661AB\n\n$blue-200: #7973BF\n$blue-500: #404085\n$blue-600: #2D3073\n$blue-700: #182160\n$blue-800: #002A67\n$blue-900: #00174F\n\n$border-bottom-title: #BBBBBB\n\n$dark-50: #717171\n$dark-100: #5A5A5A\n$dark-200: #444444\n$dark-300: #2D2D2D\n\n$green-400: #00D04C\n$green-600: #00AC29\n$green-700: #009A14\n\n$light-300: #B4B4B4\n$light-400: #9E9E9E\n$light-700: #686868\n\n$red-500: #FF3333\n$red-600: #d32f2f\n$red-700: #A72626\n\n$statusLabelActive: #00BE3B\n$statusLabelPause: #535098\n$statusLabelClose: #EF4D43\n$statusLabelUnused: #9E9E9E\n\n$tag-text: #003F80\n\n$white: #fff\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "styles_title__kMIDh",
	"addUserForm": "styles_addUserForm__KEB-C",
	"form": "styles_form__xNum2",
	"inputs": "styles_inputs__lnmsH"
};
export default ___CSS_LOADER_EXPORT___;
