/* eslint-disable global-require */
import React, { useEffect, useRef } from 'react'

import lottie from 'lottie-web'

function Warning({ className }) {
  const ref = useRef(null)

  useEffect(() => {
    const instance = lottie.loadAnimation({
      container: ref.current,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      name: 'loading',
      animationData: require('statics/lottie/warning.json'),
    })

    return () => instance.destroy()
  }, [ref])

  return (
    <div style={{ width: 200 }} className={`container ${className}`} ref={ref} />
  )
}

export default Warning
