import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import apiServer from 'api/server';

import PageTitle from 'components/pageTitle';
import FormActivation from 'components/formActivation';
import FilterAddActivation from 'components/filterAddActivation';
import ModalDialogbox from 'components/modals/dialogbox'; 

import HandleErrors from 'utils/handleErrors';
import styles from './styles.module.sass';

function AddNotification() {
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitted },
    setValue,
    getValues,
    resetField,
    setError,
    clearErrors,
    watch,
  } = useForm();

  const [openModalSucess, setOpenModalSucess] = useState(false)
  const [openModalError, setOpenModalError] = useState(false)
  const [selectedBrands, setUserBrands] = useState([])
  const [file, setFile] = useState()
  
  const navigate = useNavigate()

  const watchField = watch(('activation.brandsId'))

  useEffect(() => {
    setUserBrands(getValues('activation.brandsId') || [])
  }, [watchField, getValues])
  

  useEffect(() =>{
    setError('filters', {type: 'required', message: 'É necessário o preenchimento de pelo menos um filtro.'})
  }, [])

  const onSubmit = async (data) => {
    const { activation } = data;

    if (activation.brands) delete activation.brands;

    await apiServer
      .post('activations/new', {
        ...data.activation
      })
      .then(() => {
        setOpenModalSucess(true)
      })
      .catch((err) => {
        HandleErrors(err.response.status)
        setOpenModalError(true)
      });
  };

  const handleCloseModalSucess = () => {
    navigate(-1)
  }

  const handleCloseModal = () => {
    setOpenModalError(false)
  }

  return (
    <>
      <PageTitle title="Ativar notificação" />
      <form
        onSubmit={handleSubmit(onSubmit)}
        id="ativar-notificacao"
        className={styles.container}
      >
        <div className={styles.activationForm}>
          <p className={styles.title}>ATIVAÇÃO</p>

          <FormActivation
            control={control}
            errors={errors}
            setValue={setValue}
          />
        </div>

        <div className={styles.tabsForm}>
          <div>
            <p className={styles.title}>FILTROS</p>
            {isSubmitted && 
              <p className={styles.errorFilters}>
                {errors.filters?.message}
              </p>
            }
            <FilterAddActivation
              control={control}
              setValue={setValue}
              resetField={resetField}
              clearErrors={clearErrors}
              setError={setError}
              selectedBrands={selectedBrands}
              setFile={setFile}
            />
          </div>
        </div>
      </form>

      <ModalDialogbox
        open={openModalSucess}
        type="success"
        onClose={handleCloseModalSucess}
        text="A notificação foi criada com sucesso!"
      />

      <ModalDialogbox
        open={openModalError}
        type="error"
        onClose={handleCloseModal}
        text="Erro ao concluir ação. Por favor, tente novamente mais tarde ou entre em contato com o suporte."
      />
    </>
  );
}

export default AddNotification;
