import React, { useEffect, useMemo, useState } from 'react';
import * as Tabs from '@radix-ui/react-tabs';

import TabsFilter from 'components/filterDistributionCenter/tabs';

import axios from 'axios';
import styles from './styles.module.sass';

function FilterDistributionCenter({
  control, renderFilters, setValue, resetField, clearErrors, defaultValues, disabledForm, selectedBrands
}) {
  const [focusTab, setFocusTab] = useState(0);
  const [zipcodes, setZipcodes] = useState([])
  const [neighborhood, setNeighborhood] = useState([])
  const [cities, setCities] = useState([])

  const handleTabFocus = (index) => {
    setFocusTab(index);
    resetField('staticNeighborhoods')
    resetField('neighborhoodsExcept')
  };

  const handleZipcodes = (value) => {
    setZipcodes(value)
  }

  const handleNeighborhood = (value) => {
    setNeighborhood(value)
  }

  const handleCities = (value) => {
    setCities(value)
  }

  const defineCitiesTable = async (n) => {
    if(n) {
      const list = n?.map((item, index) => ({
        id: index,
        cities: item.name
      }))

      setCities(list)
    }
  }

  const defineNeighborhoodTable = async (n) => {
    if(n) {
      const list = n.map((item, index) => ({
          id: index,
          neighborhood: item.name,
          city: item.city
        }))
  
        // console.log(list)
        setNeighborhood(list)
    }
  }

  const defineZipcodeTable = async (z) => {
    const list = []

    z?.forEach(async (item, index) => axios.get(`https://viacep.com.br/ws/${item}/json/`)
      .then(({data}) => {
        list.push({
          id: index,
          zipcode: data.cep.replace(/\D/g, ''),
          neighborhood: data.bairro,
          cities: data.localidade
        })

        setZipcodes(list)
      })
    )
  }

  useEffect(() => {
    if(defaultValues) {
      defineCitiesTable(defaultValues?.cities)
      defineZipcodeTable(defaultValues?.zipcodes)
      defineNeighborhoodTable(defaultValues?.neighborhoods)
    }
  }, [defaultValues])

  useEffect(() => {
    const zipcodesArray = []
    const neighborhoodArray = []
    const citiesArray = []

    zipcodes.forEach((item) => (
      zipcodesArray.push(item.zipcode)
    ))

    neighborhood.forEach((item) => (
      neighborhoodArray.push({
        neighborhood: item.neighborhood,
        city: item.city,
      })
    ))

    cities.forEach((item) => (
      citiesArray.push(item.cities)
    ))

    setValue('zipcodes', zipcodesArray)
    setValue('neighborhoods', neighborhoodArray)
    setValue('cities', citiesArray)

    if(zipcodesArray || neighborhoodArray || citiesArray) {
      clearErrors('filters')
    }
  }, [zipcodes, neighborhood, cities])

  const tabsData = [
    {
      id: 0,
      label: 'cep',
      content: <TabsFilter.Cep
        control={control}
        onSelectZipcode={handleZipcodes}
        disabledForm={disabledForm}
        rows={zipcodes}
      />,
    },
    {
      id: 1,
      label: 'bairro',
      content: <TabsFilter.Neighborhood
        control={control}
        onSelectNeighborhood={handleNeighborhood}
        disabledForm={disabledForm}
        rows={neighborhood}
        resetField={resetField}
        selectedBrands={selectedBrands}
      />,
    },
    {
      id: 2,
      label: 'cidade',
      content: <TabsFilter.City
        control={control}
        onSelectCities={handleCities}
        disabledForm={disabledForm}
        resetField={resetField}
        selectedBrands={selectedBrands}
        rows={cities}
      />,
    },
  ];

  const tabs = useMemo(() => {
    if (renderFilters) {
      const list = [];

      renderFilters.forEach((filter) => {
        list.push(tabsData.find((tab) => filter === tab.label));
      });

      return list;
    }
    return tabsData;
  }, [renderFilters, tabsData]);

  return (
    <Tabs.Root
      className={styles.tabsRoot}
      orientation="vertical"
      defaultValue="tab-0"
    >
      <Tabs.List className={styles.tabsList}>
        {tabs.map((item, index) => (
          <Tabs.Trigger
            value={`tab-${index}`}
            key={item.id}
            onFocus={() => handleTabFocus(index)}
            className={`${styles.buttonTab} ${
              focusTab === index && styles.buttonTabFoccus
            }`}
          >
            {item.label}
          </Tabs.Trigger>
        ))}
      </Tabs.List>

      {tabs.map((item, index) => (
        <Tabs.Content value={`tab-${index}`} key={item.id}>
          {item.content}
        </Tabs.Content>
      ))}
    </Tabs.Root>
  );
}

export default FilterDistributionCenter;
