import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import PageTitle from 'components/pageTitle';
import apiServer from 'api/server';
import FormMessage from 'components/formMessage/index';
import ModalDialogbox from 'components/modals/dialogbox';
import { useNavigate } from 'react-router-dom';
import HandleErrors from 'utils/handleErrors';
import styles from './styles.module.sass';

function AddMessage() {
  const [isLoading, setLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState('error');
  const [modalMessage, setModalMessage] = useState('');

  const [selectedTag, setSelectedTag] = useState('');
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      isActive: false,
      dropCall: false,
      numberTimesDrop: 0,
    },
  });

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    setLoading(true);

    await apiServer
      .post('/message/new', {
        ...data,
      })
      .then((response) => {
        setLoading(false);

        if (!response.data) {
          throw new Error('Error adding the message');
        }

        setModalMessage('Mensagem adicionada com sucesso!');

        setModalType('success');
        setModalOpen(true);
      })
      .catch((err) => {
        HandleErrors(err.response.status)

        if (err.response?.status === 409) {
          setModalMessage(
            'Registro já existe no banco de dados!\nPor favor, confirme as informações e tente novamente.',
          );
        } else {
          setModalMessage(
            'Erro ao concluir ação. Por favor, \nTente novamente mais tarde ou entre em contato com o suporte.',
          );
        }

        setModalType('error');
        setModalOpen(true);

        setLoading(false);
      });
  };

  const handleTagSelect = (selectedTagId) => {
    setSelectedTag(selectedTagId);
  };

  const handleCloseModal = () => {
    setModalOpen(false);

    if (modalType === 'success') navigate(-1);
  };

  return (
    <>
      <PageTitle title="Adicionar Mensagem" />
      <form
        onSubmit={handleSubmit(onSubmit)}
        id="adicionar-mensagem"
        className={styles.container}
      >
        <div className={styles.messageForm}>
          <div className={styles.messageForm}>
            <p className={styles.title}>ID MENSAGEM</p>

            <FormMessage
              control={control}
              setValue={setValue}
              getValues={getValues}
              errors={errors}
              onTagSelect={handleTagSelect}
            />

            <input type="hidden" name="tagId" value={selectedTag} />
          </div>
        </div>
      </form>

      {isModalOpen && (
        <ModalDialogbox
          open={isModalOpen}
          type={modalType}
          onClose={handleCloseModal}
          text={modalMessage}
        />
      )}

      {isLoading && (
        <ModalDialogbox
          open={isModalOpen}
          type={modalType}
          onRightButton={() => {}}
          onLeftButton={() => {}}
          text="Por favor, aguarde enquanto concluímos o carregamento..."
        />
      )}
    </>
  );
}

export default AddMessage;
