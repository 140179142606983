import React, { useEffect, useState } from 'react';

import InputComponent from 'components/input';

import SelectComponent from 'components/select';
import { Controller } from 'react-hook-form';
import Button from 'components/button';
import HandleErrors from 'utils/handleErrors';
import apiServer from '../../api/server';

import styles from './styles.module.sass';

export default function FormTag({ control, defaultValues, errors }) {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    apiServer
      .get('category/all')
      .then((response) => {
        if (!response.data || !response.data.categories) {
          throw new Error('Erro ao carregar as categorias');
        }

        const categoriesData = response.data.categories.map((category) => ({
          id: category.id,
          content: category.name,
        }));

        setCategories(categoriesData);
      })
      .catch((err) => {
        HandleErrors(err.response.status)
      });
  }, []);

  return (
    <div className={styles.form}>
      <Controller
        name="code"
        control={control}
        rules={{
          required: {
            value: true,
            message: 'Campo obrigatório!',
          },
        }}
        render={({ field: { onChange } }) => (
          <InputComponent
            label="Código"
            multiline
            defaultValue={defaultValues?.code}
            onChange={(event) => {
              onChange(event);
            }}
            placeholder="Código da TAG"
            className={styles.input}
            error={errors.code?.message ?? false}
          />
        )}
      />
      <Controller
        name="categoryId"
        control={control}
        rules={{
          required: {
            value: true,
            message: 'Uma categoria deve ser selecionada!',
          },
        }}
        render={({ field: { onChange } }) => (
          <SelectComponent
            label="Categoria"
            multiple={false}
            hasLabels={false}
            onChange={onChange}
            placeholder="Selecione a categoria cadastrada"
            className={styles.selects}
            listSelects={categories}
            defaultValue={defaultValues?.categoryId}
            error={errors.categoryId?.message ?? false}
          />
        )}
      />

      <Button type="submit" className={styles.button} label="REGISTRAR" />
    </div>
  );
}
