import React, { useEffect, useState } from 'react';
import { Checkbox } from '@mui/material';

function CheckboxComponent({
  name,
  className,
  onChange,
  defaultValue,
  disabled,
  error,
}) {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleChange = (element) => {
    setValue(!!element.target.checked);

    if (onChange) {
      onChange(element);
    }
  };

  return (
    <Checkbox
      name={name}
      className={className}
      onChange={handleChange}
      checked={value}
      value={value}
      disabled={disabled}
      error={!!error}
    />
  );
}

export { CheckboxComponent };
