// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__2u1nK {\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 50px;\n}", "",{"version":3,"sources":["webpack://./src/screens/create-user/styles.module.sass"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AACF","sourcesContent":[".container\n  display: flex\n  justify-content: space-between\n  margin-bottom: 50px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__2u1nK"
};
export default ___CSS_LOADER_EXPORT___;
