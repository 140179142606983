import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import PageTitle from 'components/pageTitle';
import SearchBar from 'components/searchBar';
import Table from 'components/table';
import Sort from 'components/sort';
import ModalDialogbox from 'components/modals/dialogbox';

import apiServer from 'api/server';

import { ReactComponent as AddNotification } from 'statics/icons/addNotification.svg';

import styles from './styles.module.sass';

function Notifications() {
  const [rows, setRows] = useState([]);
  const [openModalConfirm, setOpenModalConfirm] = useState(false)
  const [openModalSucess, setOpenModalSucess] = useState(false)
  const [openModalError, setOpenModalError] = useState(false)
  const [deleteId, setDeleteId] = useState()

  const navigate = useNavigate();

  const handleSort = (sortRows) => {
    setRows(sortRows);
  };

  const fetchData = () => {
    apiServer.get('activations/all')
      .then((response) => {
        const { data: { activations } } = response
        
        const list = activations.map((activation) => {
          const brands = []
  
          activation.brands.forEach(brand => {
            brands.push(brand.name)
          });
  
          const convertBrandsToString = brands.toString().replace(/,/g, ', ')
          
          let transleteStatus = ''
  
          switch (activation.status) {
            case 'unused':
              transleteStatus = 'Inativo'
              break;
  
            case 'active':
              transleteStatus = 'Ativa'
              break;
  
            case 'paused':
              transleteStatus = 'Pausada'
              break;
  
            case 'blocked':
              transleteStatus = 'Encerrada'
              break;
          
            default:
              break;
          }
  
          return {
            id: activation.id,
            brand: convertBrandsToString,
            message: activation.message.name,
            tag: activation.tag.code,
            category:  activation.category.name,
            dateOf: activation.dateStart,
            untilDate: activation.dateEnd,
            status: transleteStatus,
          }
        })
  
        setRows(list)
      })      
  }

  useEffect(() => {
    fetchData()
  }, [])


  const handleSearch = useCallback(
    (event) => {
      const {
        target: { value },
      } = event;

      const regex = new RegExp(value, 'i');

      setRows(rows?.filter((row) => regex.test(row.message)));
    },
    [rows],
  );

  
  const handleDisableRule = (row) => row.status === 'Ativa' && new Date(row?.dateOf) > new Date()
  
  const handleCloseModal = () => {
    setOpenModalConfirm(false)
    setOpenModalSucess(false)
    setOpenModalError(false)
  }
  
  const handleConfirm = (row) => {
    setOpenModalConfirm(true)
    setDeleteId(row.id)
  }

  const handleRemove = useCallback(() => {
    apiServer.delete(`/activations/delete/${deleteId}`)
      .then(() => {
        handleCloseModal()
        setOpenModalSucess(true)
        
        fetchData()
      })
      .catch(() => {
        handleCloseModal()
        setOpenModalError(true)
      })
  }, [deleteId])

  const handleView = (row) => {
    navigate(`${row.id}`)
  }

  return (
    <div className={styles.container}>
      <AddNotification
        className={styles.addNotification}
        onClickCapture={() => navigate('ativar-notificacao')}
      />

      <PageTitle title="Notificações" />

      <SearchBar rows={rows} onSearch={handleSearch} hasModal />

      <Sort onSort={handleSort} rows={rows} />

      <Table
        page="notificacoes"
        rows={rows}
        numberOfRows={5}
        removeEdit
        addEye
        onClickEye={handleView}
        onDisableRule={handleDisableRule}
        onClickTrash={handleConfirm}
      />

      <ModalDialogbox
        open={openModalConfirm}
        type="options"
        onClose={handleCloseModal}
        buttonsLabel={['voltar', 'excluir']}
        onLeftButton={handleCloseModal}
        onRightButton={handleRemove}
        text="Tem certeza que deseja excluioir a notificação?"
      />

      <ModalDialogbox
        open={openModalSucess}
        type="success"
        onClose={handleCloseModal}
        text="A notificação foi exluida com sucesso!"
      />

      <ModalDialogbox
        open={openModalError}
        type="error"
        onClose={handleCloseModal}
        text="Erro ao concluir ação. Por favor, tente novamente mais tarde ou entre em contato com o suporte."
      />
    </div>
  );
}

export default Notifications;
