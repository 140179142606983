import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import PageTitle from 'components/pageTitle';
import Sort from 'components/sort';
import SearchBar from 'components/searchBar';
import Table from 'components/table';
import ModalDialogbox from 'components/modals/dialogbox';

import { ReactComponent as AddCategory } from 'statics/icons/addCategory.svg';

import apiServer from 'api/server';

import styles from './styles.module.sass';

function Category() {
  const [rows, setRows] = useState([]);
  const [sortDirection, setSortDirection] = useState('asc');
  const [restoreRows, setOriginalRows] = useState([]);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [openModalSucess, setOpenModalSucess] = useState(false);
  const [openModalError, setOpenModalError] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const navigate = useNavigate();

  const fetchData = () => {
    apiServer
      .get('category/all')
      .then((response) => {
        const categoriesWithId = response.data.categories.map((category) => ({
          id: category.id,
          name: category.name,
          activation: true,
        }));
        setRows(categoriesWithId);
        setOriginalRows(categoriesWithId);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSort = (sortRows) => {
    setRows(sortRows);
  };

  const handleSortDirectionChange = useCallback(() => {
    const newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
    setSortDirection(newSortDirection);
    const sortedRows = [...rows].sort((a, b) => {
      if (newSortDirection === 'asc') {
        return a.name.localeCompare(b.name);
      }
      return b.name.localeCompare(a.name);
    });
    setRows(sortedRows);
  }, [rows, sortDirection]);

  const handleSearch = useCallback(
    (event) => {
      const {
        target: { value },
      } = event;
      const regex = new RegExp(value, 'i');
      if (value === '') {
        setRows(restoreRows);
      } else {
        const rowsFilteredRows = restoreRows.filter((row) =>
          regex.test(row.name),
        );
        setRows(rowsFilteredRows);
      }
    },
    [restoreRows],
  );

  const handleSwitchChange = useCallback(
    (id) => {
      const updatedRows = rows.map((row) => {
        if (row.id === id) {
          return {
            ...row,
            activation: true,
          };
        }
        return row;
      });
      setRows(updatedRows);
    },
    [rows],
  );

  const handleEdit = (e) => {
    navigate(`editar/${e.id}`);
  };

  const handleOpenModal = (e) => {
    setOpenModalConfirm(!openModalConfirm);
    setDeleteId(e.id);
  };

  const handleCloseModal = () => {
    setOpenModalConfirm(false);
    setOpenModalSucess(false);
    setOpenModalError(false);
  };

  const handleDelete = useCallback(async () => {
    setOpenModalConfirm(!openModalConfirm);
    try {
      await apiServer.delete(`/category/remove/${deleteId}`);

      setOpenModalSucess(true);
      fetchData();
    } catch (error) {
      setOpenModalError(true);
    }
  }, [deleteId]);

  return (
    <div className={styles.container}>
      <AddCategory
        className={styles.addCategory}
        onClickCapture={() => navigate('adicionar-categoria')}
      />

      <PageTitle title="Categoria" />

      <SearchBar rows={rows} onSearch={handleSearch} />

      <Sort
        hasDate
        onSort={handleSort}
        rows={rows}
        sortDirection={sortDirection}
        onSortDirectionChange={handleSortDirectionChange}
      />

      <Table
        page="categoria"
        rows={rows}
        numberOfRows={5}
        onClickEdit={handleEdit}
        onClickTrash={handleOpenModal}
        renderRow={(row) => (
          <tr key={row.id}>
            <td>{row.tag}</td>
            <td>{row.category}</td>
            <td>
              <input
                type="checkbox"
                checked={row.activation}
                onChange={() => handleSwitchChange(row.id)}
              />
            </td>
          </tr>
        )}
      />

      <ModalDialogbox
        open={openModalConfirm}
        type="options"
        onClose={handleCloseModal}
        text="Tem certeza de que deseja excluir essa categoria?"
        buttonsLabel={['VOLTAR', 'EXCLUIR']}
        onLeftButton={handleCloseModal}
        onRightButton={handleDelete}
      />

      <ModalDialogbox
        open={openModalSucess}
        type="success"
        onClose={handleCloseModal}
        text="A categoria foi exluida com sucesso!"
      />

      <ModalDialogbox
        open={openModalError}
        type="error"
        onClose={handleCloseModal}
        text="Erro ao concluir ação. Por favor, tente novamente mais tarde ou entre em contato com o suporte."
      />
    </div>
  );
}

export default Category;
