// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__8g2G4 {\n  margin-bottom: 100px;\n}\n\n.styles_addTag__3vl8E {\n  background-color: #00D04C;\n  border-radius: 100%;\n  display: flex;\n  align-items: center;\n  width: 2rem;\n  height: 2rem;\n  bottom: 5%;\n  display: flex;\n  padding: 26px;\n  position: fixed;\n  right: 2%;\n  z-index: 10;\n}\n\n.styles_addTag__3vl8E:hover {\n  background-color: #009A14;\n  cursor: pointer;\n  transition: 0.3s all ease;\n}", "",{"version":3,"sources":["webpack://./src/screens/tags/styles.module.sass","webpack://./src/styles/_colors.sass"],"names":[],"mappings":"AAEA;EACE,oBAAA;AADF;;AAGA;EACE,yBCcU;EDbV,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;EACA,aAAA;EACA,aAAA;EACA,eAAA;EACA,SAAA;EACA,WAAA;AAAF;;AAEA;EACE,yBCEU;EDDV,eAAA;EACA,yBAAA;AACF","sourcesContent":["@import 'styles/_colors.sass'\n\n.container\n  margin-bottom: 100px\n\n.addTag\n  background-color: $green-400\n  border-radius: 100%\n  display: flex\n  align-items: center\n  width: 2rem\n  height: 2rem\n  bottom: 5%\n  display: flex\n  padding: 26px\n  position: fixed\n  right: 2%\n  z-index: 10\n\n.addTag:hover\n  background-color: $green-700\n  cursor: pointer\n  transition: 0.3s all ease\n","$background-menu: #F8F8FB\n\n$ligth-blue-600: #00559A\n\n$edit-icon: #6661AB\n\n$blue-200: #7973BF\n$blue-500: #404085\n$blue-600: #2D3073\n$blue-700: #182160\n$blue-800: #002A67\n$blue-900: #00174F\n\n$border-bottom-title: #BBBBBB\n\n$dark-50: #717171\n$dark-100: #5A5A5A\n$dark-200: #444444\n$dark-300: #2D2D2D\n\n$green-400: #00D04C\n$green-600: #00AC29\n$green-700: #009A14\n\n$light-300: #B4B4B4\n$light-400: #9E9E9E\n$light-700: #686868\n\n$red-500: #FF3333\n$red-600: #d32f2f\n$red-700: #A72626\n\n$statusLabelActive: #00BE3B\n$statusLabelPause: #535098\n$statusLabelClose: #EF4D43\n$statusLabelUnused: #9E9E9E\n\n$tag-text: #003F80\n\n$white: #fff\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__8g2G4",
	"addTag": "styles_addTag__3vl8E"
};
export default ___CSS_LOADER_EXPORT___;
