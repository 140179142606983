import React from 'react';
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';

import App from 'screens/App';
import AddNotification from 'screens/add-notification';
import Login from 'screens/login';
import Messages from 'screens/messages';
import Notifications from 'screens/notifications';
import NotificationPanel from 'screens/notification-panel';
import Perfil from 'screens/perfil';
import Category from 'screens/category';
import AddCategory from 'screens/add-category';
import EditCategory from 'screens/edit-category';
import Tags from 'screens/tags';
import DistributionCenter from 'screens/distribution-center';
import Users from 'screens/users';
import AddMessage from 'screens/add-message';
import EditMessage from 'screens/edit-message';
import AddTag from 'screens/add-tag/index';
import AddDistributionCenter from 'screens/add-distribution-center';
import AccessReports from 'screens/access-reports';
import EditUser from 'screens/edit-user';
import ErrorPage from 'screens/errorPage';
import CreateUser from 'screens/create-user';
import EditTag from 'screens/edit-tag';

import ViewNotification from 'screens/view-notification';
import ViewDistributionCenter from 'screens/view-distribution-center';
import RequireAuth from './RequireAuth';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="login" element={<Login />} />
      <Route
        path="/"
        element={
          <RequireAuth>
            <App />
          </RequireAuth>
        }
      >
        <Route path="painel-de-notificacoes" element={<NotificationPanel />} />
        <Route
          path="painel-de-notificacoes/ativar-notificacao"
          element={<AddNotification />}
        />
        <Route
          path="painel-de-notificacoes/:id"
          element={<ViewNotification />}
        />
        <Route path="perfil" element={<Perfil />} />
        <Route path="notificacoes" element={<Notifications />} />
        <Route
          path="notificacoes/ativar-notificacao"
          element={<AddNotification />}
        />
        <Route
          path="notificacoes/:id"
          element={<ViewNotification />}
        />
        <Route path="mensagens" element={<Messages />} />
        <Route path="mensagens/adicionar-mensagem" element={<AddMessage />} />
        <Route path="mensagens/editar/:id" element={<EditMessage />} />
        <Route path="notificacoes" element={<Notifications />} />
        <Route path="categoria" element={<Category />} />
        <Route path="categoria/adicionar-categoria" element={<AddCategory />} />
        <Route path="categoria/editar/:id" element={<EditCategory />} />
        <Route path="tags" element={<Tags />} />
        <Route path="tags/adicionar-tag" element={<AddTag />} />
        <Route path="tags/editar/:id" element={<EditTag />} />
        <Route path="centro-de-distribuicao" element={<DistributionCenter />} />
        <Route path="relatorio-de-acessos" element={<AccessReports />} />
        <Route
          path="centro-de-distribuicao/adicionar-centro-de-distribuicao"
          element={<AddDistributionCenter />}
        />
        <Route
          path="centro-de-distribuicao/:id"
          element={<ViewDistributionCenter />}
        />
        <Route
          path='*'
          element={<ErrorPage/>}
        />
        <Route path="usuarios" element={<Users />} />
        <Route path="usuarios/adicionar-usuario" element={<CreateUser />} />
        <Route path="usuarios/editar/:id" element={<EditUser />} />
      </Route>
    </>,
  ),
);

export default router;
