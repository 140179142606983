import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import Menu from 'components/menu';

import FloatButtonForm from 'components/floatButtonForm';
import styles from './styles.module.sass';

function App() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/painel-de-notificacoes');
    }
  }, []);

  return (
    <main>
      <div className={styles.menu}>
        <Menu />
        <div className={styles.outlet}>
          <Outlet />
        </div>

        <FloatButtonForm />
      </div>
    </main>
  );
}

export default App;
