import React, { useCallback, useMemo, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';

import Button from 'components/button';
import InputComponent from 'components/input';
import PageTitle from 'components/pageTitle';

import perfilForm from 'mockdata/perfilForm.json';
import userData from 'mockdata/userData.json';

import { ReactComponent as CloseToast } from 'statics/icons/close.svg';

import 'react-toastify/dist/ReactToastify.css';
import styles from './styles.module.sass';

const PERFIL_FORM = perfilForm.form;
const USER_DATA = userData.data;

function Perfil() {
  const { handleSubmit, control, setValue } = useForm();
  const toastId = useRef(null);

  const onSubmit = useCallback(
    (e) => {
      if (!toast.isActive(toastId.current)) {
        console.log(e);

        toastId.current = toast.success('Perfil atualizado!', {
          position: 'top-right',
          autoClose: 2000,
          // eslint-disable-next-line react/no-unstable-nested-components
          closeButton: () => <CloseToast />,
          icon: false,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'light',
        });
      }
    },
    [toastId],
  );

  const data = useMemo(() => {
    PERFIL_FORM.forEach((item) => {
      const passwordConfirm = USER_DATA.password;

      const value = USER_DATA[`${item.name}`] || passwordConfirm;

      setValue(item.name, value);
    });

    return PERFIL_FORM;
  }, [PERFIL_FORM, USER_DATA]);

  return (
    <div className={styles.container}>
      <PageTitle title="Perfil" />

      <form
        id="perfil-form"
        className={styles.formContainer}
        onSubmit={handleSubmit(onSubmit)}
      >
        {data.map((item) => (
          <Controller
            control={control}
            name={item.name}
            key={item.id}
            render={({ field: { onChange, value } }) => (
              <InputComponent
                control
                defaultValue={value}
                onChange={() => onChange(item.value)}
                disabled={item.name === 'user'}
                className={styles.input}
                label={item.label}
                placeholder={item.placeholder}
                password={item.password}
              />
            )}
          />
        ))}

        <Button
          label="ATUALIZAR"
          type="submit"
          form="perfil-form"
          className={styles.button}
        />

        <ToastContainer />
      </form>
    </div>
  );
}

export default Perfil;
