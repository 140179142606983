import React from 'react';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import styles from './styles.module.sass';

const brands = [
  {
    label: 'Niu',
  },
  {
    label: 'VIP',
  },
  {
    label: 'Ligue',
  },
];

function brandsCheckBox() {
  return (
    <div className={styles.brandContent}>
      <h4 className={styles.label}>Por marca:</h4>
      <FormGroup>
        {brands.map((b) => (
          <FormControlLabel control={<Checkbox />} label={b.label} />
        ))}
      </FormGroup>
    </div>
  );
}

export default brandsCheckBox;
