/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import apiServer from 'api/server';

import PageTitle from 'components/pageTitle';
import FormDistributionCenter from 'components/formDistributionCenter';
import FilterDistributionCenter from 'components/filterDistributionCenter';

import ModalDialogbox from 'components/modals/dialogbox';
import styles from './styles.module.sass'

function AddDistributionCenter() {
  const {
    handleSubmit,
    control,
    setValue,
    resetField,
    setError,
    clearErrors,
    getValues,
    watch,
    formState: {
      errors,
      isSubmitted,
    }
  } = useForm();

  const [showModalSuccess, setShowModalSuccess] = useState(false)
  const [showModalError, setShowModalError] = useState(false)
  const [selectedBrands, setUserBrands] = useState([])

  const navigate = useNavigate()

  const watchField = watch('brandsId')

  useEffect(() => {
    setUserBrands(getValues('brandsId') || [])
  }, [watchField, getValues])

  useEffect(() =>{
    setError('filters', {type: 'required', message: 'É necessário o preenchimento de pelo menos um filtro.'})
  }, [])

  const onSubmit = (data) => {
    apiServer.post(
      '/distribution-centers/new',
      {
        name: data.name,
        zipcodes: data.zipcodes,
        cities: data.cities,
        neighborhoods: data.neighborhoods,
      },
    ).then(() => {
      setShowModalSuccess(true)
    })
    .catch(() => {
      setShowModalError(true)
    })
  }

  const handleCloseModal = () => {
    navigate(-1)
  }

  const handleCloseModalError = () => {
    setShowModalError(false)
  }

  return (
    <>
      <PageTitle title="Adicionar Centro de Distribuição" />
      <form
        onSubmit={handleSubmit(onSubmit)}
        id="adicionar-centro-de-distribuicao"
        className={styles.container}
      >
        <div className={styles.distributionForm}>
          <p className={styles.title}>NOME</p>

          <FormDistributionCenter
            control={control}
            setValue={setValue}
            errors={errors}
          />
        </div>

        <div className={styles.tabsForm}>
          <div>
            <p className={styles.title}>FILTROS</p>

            {isSubmitted && 
              <p className={styles.errorFilters}>
                {errors.filters?.message}
              </p>
            }
            <FilterDistributionCenter
              control={control}
              setValue={setValue}
              resetField={resetField}
              clearErrors={clearErrors}
              selectedBrands={selectedBrands}
            />
          </div>

        </div>
      </form>

      <ModalDialogbox
        open={showModalSuccess}
        type="success"
        onClose={handleCloseModal}
        text="Centro de distribuição criado com sucesso!"
      />

      <ModalDialogbox
        open={showModalError}
        type="error"
        onClose={handleCloseModalError}
        text="Erro ao concluir ação. Por favor, tente novamente mais tarde ou entre em contato com o suporte."
      />
    </>

  );
}

export default AddDistributionCenter;
