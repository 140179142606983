// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__B4Ozc .styles_formContainer__mv6zn {\n  display: flex;\n  flex-direction: column;\n  max-width: 432px;\n}\n.styles_container__B4Ozc .styles_formContainer__mv6zn .styles_input__JeRUY {\n  margin-bottom: 40px;\n}\n.styles_container__B4Ozc .styles_formContainer__mv6zn .styles_button__xocAi {\n  background-color: #00D04C;\n  margin-top: 8px;\n  padding: 24px 0;\n  transition: 0.2s all ease;\n  width: 100%;\n}\n.styles_container__B4Ozc .styles_formContainer__mv6zn .styles_button__xocAi:hover {\n  background-color: #00AC29;\n}", "",{"version":3,"sources":["webpack://./src/screens/perfil/styles.module.sass","webpack://./src/styles/_colors.sass"],"names":[],"mappings":"AAIE;EACE,aAAA;EACA,sBAAA;EACA,gBAAA;AAHJ;AAKI;EACE,mBAAA;AAHN;AAKI;EACE,yBCOM;EDNN,eAAA;EACA,eAAA;EACA,yBAAA;EACA,WAAA;AAHN;AAKI;EACE,yBCCM;ADJZ","sourcesContent":["@import 'styles/_colors.sass'\n\n.container\n\n  .formContainer\n    display: flex\n    flex-direction: column\n    max-width: 432px\n\n    .input\n      margin-bottom: 40px\n\n    .button\n      background-color: $green-400\n      margin-top: 8px\n      padding: 24px 0\n      transition: 0.2s all ease\n      width: 100%\n\n    .button:hover\n      background-color: $green-600\n","$background-menu: #F8F8FB\n\n$ligth-blue-600: #00559A\n\n$edit-icon: #6661AB\n\n$blue-200: #7973BF\n$blue-500: #404085\n$blue-600: #2D3073\n$blue-700: #182160\n$blue-800: #002A67\n$blue-900: #00174F\n\n$border-bottom-title: #BBBBBB\n\n$dark-50: #717171\n$dark-100: #5A5A5A\n$dark-200: #444444\n$dark-300: #2D2D2D\n\n$green-400: #00D04C\n$green-600: #00AC29\n$green-700: #009A14\n\n$light-300: #B4B4B4\n$light-400: #9E9E9E\n$light-700: #686868\n\n$red-500: #FF3333\n$red-600: #d32f2f\n$red-700: #A72626\n\n$statusLabelActive: #00BE3B\n$statusLabelPause: #535098\n$statusLabelClose: #EF4D43\n$statusLabelUnused: #9E9E9E\n\n$tag-text: #003F80\n\n$white: #fff\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__B4Ozc",
	"formContainer": "styles_formContainer__mv6zn",
	"input": "styles_input__JeRUY",
	"button": "styles_button__xocAi"
};
export default ___CSS_LOADER_EXPORT___;
