import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ThemeProvider, createTheme } from '@mui/material';

import { AuthProvider } from 'context/auth';

import router from './routes';

import 'dayjs/locale/pt-br';
import './index.css';

const theme = createTheme({
  typography: {
    fontFamily: ['Open Sans'],
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale="pt-br"
        localeText={{
          fieldYearPlaceholder: () => '0000',
          fieldMonthPlaceholder: (params) => (params.contentType === 'letter' ? '0000' : '00'),
          fieldDayPlaceholder: () => '00',
          fieldWeekDayPlaceholder: (params) => (params.contentType === 'letter' ? '0000' : '00'),
          fieldHoursPlaceholder: () => '00',
          fieldMinutesPlaceholder: () => '00',
          fieldSecondsPlaceholder: () => '00',
          fieldMeridiemPlaceholder: () => '00',
        }}
      >
        <AuthProvider>
          <RouterProvider router={router} />
        </AuthProvider>
      </LocalizationProvider>
    </ThemeProvider>
  </React.StrictMode>,
);
