import apiServer from 'api/server';

async function SearchMessages({ id }) {
  try {
    const response = await apiServer.get(`message/search/${id}`);

    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
}

async function EditMessages({ id, data }) {
  try {
    await apiServer.put(`message/update/${id}`, {
      ...data,
    });
  } catch (error) {
    throw new Error(error.message);
  }
}

const EditMessageRequests = {
  SearchMessages,
  EditMessages,
};

export default EditMessageRequests;
