import React from 'react';

import Button from 'components/button';

import Selects from './components/selects';
import DateInputs from './components/dateInputs';

import styles from './styles.module.sass';

function FormAccessReports({ control }) {
  return (
    <div className={styles.form}>
      <Selects control={control} />

      <DateInputs control={control} />

      <div>
        <Button
          label="CONSULTAR"
          form="access-reports"
          type="submit"
          className={styles.consultButton}
        />
      </div>
    </div>
  );
}

export default FormAccessReports;
