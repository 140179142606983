import React from 'react';
import { ReactComponent as Seta } from 'statics/icons/seta.svg';

import styles from './style.module.sass';

function PageTitle({ title, hasSubItem, subItemText }) {
  return (
    <p className={styles.title}>
      {title}

      {hasSubItem && (
        <div className={styles.subItem}>
          <Seta className={styles.icon} />
          <p className={styles.subItemText}>{subItemText}</p>
        </div>
      )}
    </p>
  );
}

export default PageTitle;
