import React from 'react';
import { Controller } from 'react-hook-form';

import SelectComponent from 'components/select';

import styles from './styles.module.sass';

function Selects({ control }) {
  const selects = [
    {
      id: 0,
      label: 'URA (TAG)',
      name: 'tag',
      hasLabels: false,
      placeholder: 'Digite o código ou nome da tag',
      list: [
        {
          id: 0,
          content: '0011',
        },
        {
          id: 1,
          content: '0012',
        },
      ],
    },
    {
      id: 1,
      label: 'Mensagem',
      name: 'message',
      hasLabels: false,
      placeholder: 'Digite o título da mensagem cadastrada',
      list: [
        {
          id: 0,
          content: '34 - Rompimento São Miguel',
        },
        {
          id: 1,
          content: '35 - Reajuste de valor',
        },
      ],
    },
  ];

  return (
    <div>
      {selects.map((item, index) => (
        <Controller
          key={item.id}
          name={item.name}
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange } }) => (
            <SelectComponent
              label={item.label}
              multiple
              onChange={onChange}
              hasLabels={item.hasLabels}
              placeholder={item.placeholder}
              className={styles.selects}
              listSelects={item.list}
              index={index}
            />
          )}
        />
      ))}
    </div>
  );
}

export default Selects;
