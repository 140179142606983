import React, { useCallback, useEffect, useState } from 'react';

import { IconButton, InputAdornment, TextField } from '@mui/material';

import { ReactComponent as VisiblePassword } from 'statics/icons/visiblePassword.svg';
import { ReactComponent as HiddenPassword } from 'statics/icons/hiddenPassword.svg';

function InputComponent({
  label,
  placeholder,
  className,
  onChange,
  defaultValue,
  password,
  disabled,
  error,
}) {
  const [labelState, setLabelState] = useState(placeholder);
  const [value, setValue] = useState(defaultValue);
  const [showPassword, setShowPassword] = useState(!password);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleChange = useCallback((element) => {
    if(!disabled) {
      setValue(element.target.value);
  
      if (onChange) {
        onChange(element);
      }
    }
  }, [disabled])

  useEffect(() => {
    if (value) {
      setLabelState(label);
    }
  }, [value, label, labelState]);

  const handleClose = useCallback(() => {
    setLabelState(placeholder);
  }, [placeholder]);

  const handleOpen = useCallback(() => {
    setLabelState(label);
  }, [label]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  return (
    <TextField
      label={labelState}
      className={className}
      focused={defaultValue || false}
      value={value}
      // disabled={disabled}
      defaultValue={value}
      autoComplete="on"
      sx={{
        '& .MuiInputBase-input': {
          textOverflow: 'ellipsis',
          paddingRight: '64px',
          color: disabled ? '#00000061' : '#000'
        },

        '& .MuiInputLabel-root.Mui-focused': {
          color: '#00000099'
        },

        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: !disabled ? '#00000042 1px solid' : '#0000003b 1px solid'
        },

        '& .MuiOutlinedInput-root.Mui-focused:hover .MuiOutlinedInput-notchedOutline': {
          cursor: 'pointer',
          borderColor: disabled ? '#00000042': '#000000de'
        },


      }}
      onChange={handleChange}
      onFocus={handleOpen}
      onBlur={handleClose}
      type={showPassword ? 'text' : 'password'}
      error={!!error}
      helperText={error ?? undefined}
      InputProps={
        password && {
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClickCapture={handleClickShowPassword}
                edge="end"
              >
                {showPassword ? <VisiblePassword /> : <HiddenPassword />}
              </IconButton>
            </InputAdornment>
          ),
        }
      }
    />
  );
}

export default InputComponent;
