// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_dateContent__5nCXu {\n  margin-left: 48px;\n  position: relative;\n  top: -164px;\n}\n\n.styles_dateInputsMargin__7Qykr {\n  margin-right: 16px !important;\n}\n\n.styles_label__16PVp {\n  font-size: 20px;\n  font-weight: 700;\n  color: #2D3073;\n  margin-bottom: 24px;\n  -webkit-user-select: none;\n          user-select: none;\n  margin-top: 40px;\n}", "",{"version":3,"sources":["webpack://./src/components/modals/Filter/components/filterDates/styles.module.sass","webpack://./src/styles/_colors.sass"],"names":[],"mappings":"AAEA;EACE,iBAAA;EACA,kBAAA;EACA,WAAA;AADF;;AAGA;EACE,6BAAA;AAAF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,cCLS;EDMT,mBAAA;EACA,yBAAA;UAAA,iBAAA;EACA,gBAAA;AACF","sourcesContent":["@import 'styles/_colors.sass'\n\n.dateContent\n  margin-left: 48px\n  position: relative\n  top: -164px\n\n.dateInputsMargin\n  margin-right: 16px !important\n\n.label\n  font-size: 20px\n  font-weight: 700\n  color: $blue-600\n  margin-bottom: 24px\n  user-select: none\n  margin-top: 40px\n","$background-menu: #F8F8FB\n\n$ligth-blue-600: #00559A\n\n$edit-icon: #6661AB\n\n$blue-200: #7973BF\n$blue-500: #404085\n$blue-600: #2D3073\n$blue-700: #182160\n$blue-800: #002A67\n$blue-900: #00174F\n\n$border-bottom-title: #BBBBBB\n\n$dark-50: #717171\n$dark-100: #5A5A5A\n$dark-200: #444444\n$dark-300: #2D2D2D\n\n$green-400: #00D04C\n$green-600: #00AC29\n$green-700: #009A14\n\n$light-300: #B4B4B4\n$light-400: #9E9E9E\n$light-700: #686868\n\n$red-500: #FF3333\n$red-600: #d32f2f\n$red-700: #A72626\n\n$statusLabelActive: #00BE3B\n$statusLabelPause: #535098\n$statusLabelClose: #EF4D43\n$statusLabelUnused: #9E9E9E\n\n$tag-text: #003F80\n\n$white: #fff\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dateContent": "styles_dateContent__5nCXu",
	"dateInputsMargin": "styles_dateInputsMargin__7Qykr",
	"label": "styles_label__16PVp"
};
export default ___CSS_LOADER_EXPORT___;
