// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_form__l1B12 {\n  display: flex;\n  margin-top: 24px;\n}\n.styles_form__l1B12 .styles_input__oLBNk {\n  margin-right: 16px;\n  flex: 0.5 1;\n}\n.styles_form__l1B12 .styles_dateInputs__BzvTk {\n  display: flex;\n}\n.styles_form__l1B12 .styles_dateInputs__BzvTk .styles_dateInputsMargin__PXMDu {\n  margin-right: 8px;\n}\n.styles_form__l1B12 .styles_button__LAwEx {\n  background: #00D04C;\n}\n.styles_form__l1B12 .styles_button__LAwEx:active {\n  background: #00AC29;\n}", "",{"version":3,"sources":["webpack://./src/components/formCategory/styles.module.sass","webpack://./src/styles/_colors.sass"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,gBAAA;AADF;AAGE;EACE,kBAAA;EACA,WAAA;AADJ;AAGE;EACE,aAAA;AADJ;AAGI;EACE,iBAAA;AADN;AAGE;EACE,mBCGQ;ADJZ;AAGE;EACE,mBCCQ;ADFZ","sourcesContent":["@import 'styles/_colors.sass'\n  \n.form\n  display: flex\n  margin-top: 24px\n\n  .input\n    margin-right: 16px\n    flex: 0.5\n\n  .dateInputs\n    display: flex\n\n    .dateInputsMargin\n      margin-right: 8px\n\n  .button\n    background: $green-400\n\n  .button:active\n    background: $green-600\n","$background-menu: #F8F8FB\n\n$ligth-blue-600: #00559A\n\n$edit-icon: #6661AB\n\n$blue-200: #7973BF\n$blue-500: #404085\n$blue-600: #2D3073\n$blue-700: #182160\n$blue-800: #002A67\n$blue-900: #00174F\n\n$border-bottom-title: #BBBBBB\n\n$dark-50: #717171\n$dark-100: #5A5A5A\n$dark-200: #444444\n$dark-300: #2D2D2D\n\n$green-400: #00D04C\n$green-600: #00AC29\n$green-700: #009A14\n\n$light-300: #B4B4B4\n$light-400: #9E9E9E\n$light-700: #686868\n\n$red-500: #FF3333\n$red-600: #d32f2f\n$red-700: #A72626\n\n$statusLabelActive: #00BE3B\n$statusLabelPause: #535098\n$statusLabelClose: #EF4D43\n$statusLabelUnused: #9E9E9E\n\n$tag-text: #003F80\n\n$white: #fff\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "styles_form__l1B12",
	"input": "styles_input__oLBNk",
	"dateInputs": "styles_dateInputs__BzvTk",
	"dateInputsMargin": "styles_dateInputsMargin__PXMDu",
	"button": "styles_button__LAwEx"
};
export default ___CSS_LOADER_EXPORT___;
