/* eslint-disable global-require */
import React, { useEffect, useRef } from 'react';

import lottie from 'lottie-web';

function Loading({ className }) {
  const ref = useRef(null);

  useEffect(() => {
    const instance = lottie.loadAnimation({
      container: ref.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      name: 'loading',
      animationData: require('statics/lottie/loading.json'),
    });

    return () => instance.destroy();
  }, [ref]);

  return (
    <div
      style={{ width: 200 }}
      className={`container ${className}`}
      ref={ref}
    />
  );
}

export default Loading;
