import React, { useState, useEffect } from 'react';

import InputComponent from 'components/input';

import { Controller } from 'react-hook-form';
import apiServer from 'api/server';
import { CheckboxComponent } from 'components/checkbox';
import HandleErrors from 'utils/handleErrors';
import TextBoxComponent from '../textBox/index';

import styles from './styles.module.sass';
import SelectComponent from '../select/index';

function FormMessage({
  control,
  onTagSelect,
  errors,
  setValue,
  getValues,
  defaultValues,
}) {
  const [tags, setTags] = useState([]);
  const [, setSelectedTag] = useState({});

  useEffect(() => {
    apiServer
      .get('/tag/all')
      .then((response) => {
        const { data } = response;

        if (!data.tags) {
          throw new Error('Could not find tags');
        }

        const tagsData = data.tags.map((tag) => ({
          id: tag.id,
          content: `${tag.code} - ${tag.category.name}`,
        }));
        setTags(tagsData);
      })
      .catch((err) => {
        HandleErrors(err.response.status)
      });
  }, []);

  const handleCategoryChange = (event) => {
    const newValue = tags.find((tag) => tag.id === event.target.value);

    setSelectedTag(newValue !== undefined ? newValue : '');
    onTagSelect(newValue.id || '');
    setValue('tagId', newValue.id || '');
  };

  return (
    <div className={styles.form}>
      <div className={styles.forminputsId}>
        <Controller
          name="name"
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Campo obrigatório',
            },
          }}
          render={({ field: { onChange } }) => (
            <InputComponent
              label="Título"
              multiline
              onChange={onChange}
              placeholder="Digite um título para a mensagem"
              className={styles.input}
              defaultValue={defaultValues?.name ?? ''}
              error={errors.name?.message ?? undefined}
            />
          )}
        />
        <Controller
          name="description"
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Campo obrigatório',
            },
          }}
          render={({ field: { onChange } }) => (
            <TextBoxComponent
              label="Identificar Mensagem"
              onChange={onChange}
              placeholder="Explique brevemente sobre o que se trata a mensagem"
              className={styles.textBoxId}
              defaultValue={defaultValues?.description ?? ''}
              error={errors.description?.message ?? undefined}
            />
          )}
        />
      </div>
      <div>
        <p className={styles.title}>CONFIGURAÇÃO DA MENSAGEM</p>
      </div>
      <div className={styles.configurationForm}>
        <div className={styles.textBoxConfigurationForm}>
          <Controller
            name="chatMessage"
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Campo obrigatório',
              },
            }}
            render={({ field: { onChange } }) => (
              <TextBoxComponent
                label="Texto de Chat"
                onChange={onChange}
                placeholder="Digite o texto a ser enviado via chat"
                className={styles.textBoxConfiguration}
                defaultValue={defaultValues?.chatMessage ?? ''}
                error={errors.chatMessage?.message ?? undefined}
              />
            )}
          />
          <Controller
            name="voiceMessage"
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Campo obrigatório',
              },
            }}
            render={({ field: { onChange } }) => (
              <TextBoxComponent
                label="Mensagem de voz"
                onChange={onChange}
                placeholder="Digite o texto a ser falado"
                className={styles.textBoxConfiguration}
                defaultValue={defaultValues?.voiceMessage ?? ''}
                error={errors.voiceMessage?.message ?? undefined}
              />
            )}
          />
        </div>
        <div className={styles.selectsForm}>
          <Controller
            name="tagId"
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Campo obrigatório',
              },
            }}
            render={() => (
              <SelectComponent
                label="Tag - Categoria"
                onChange={handleCategoryChange}
                placeholder="Selecione a tag"
                className={styles.selects}
                listSelects={tags}
                error={errors.tagId?.message ?? undefined}
                defaultValue={defaultValues?.tagId ?? ''}
              />
            )}
          />

          <div className={styles.dialogs}>
            <Controller
              name="numberTimesDrop"
              control={control}
              rules={{
                required: {
                  value: !!getValues('dropCall'),
                  message:
                    'Campo obrigatório já que o "Derrubar chamada está marcado"!',
                },
              }}
              render={({ field: { onChange } }) => (
                <InputComponent
                  label="Quantas vezes?"
                  multiline
                  onChange={(e) => {
                    onChange(parseInt(e.target.value, 10));
                  }}
                  placeholder="Insira a quantidade"
                  className={styles.input}
                  error={errors.numberTimesDrop?.message ?? undefined}
                  defaultValue={defaultValues?.numberTimesDrop ?? ''}
                />
              )}
            />
            <Controller
              name="dropCall"
              control={control}
              render={({ field: { onChange } }) => (
                <CheckboxComponent
                  name="dropCall"
                  onChange={onChange}
                  defaultValue={defaultValues?.dropCall ?? false}
                  error={errors.dropCall?.message ?? undefined}
                />
              )}
            />
            <p className={styles.text}>Derrubar chamada?</p>
          </div>
          <Controller
            name="audioCode"
            control={control}
            render={({ field: { onChange } }) => (
              <TextBoxComponent
                label="Código do audio"
                onChange={onChange}
                placeholder="Insira o código do audio"
                className={styles.textBoxConfiguration}
                defaultValue={defaultValues?.audioCode ?? ''}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
}

export default FormMessage;
