import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import FormTag from 'components/formTag';
import PageTitle from 'components/pageTitle';
import ModalDialogbox from 'components/modals/dialogbox';

import apiServer from 'api/server';

import styles from './styles.module.sass';

function EditTag() {
  const [selectedCategory, setSelectedCategory] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState('error');
  const [isLoading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState();

  const { id } = useParams();
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    reset,
    formState: { defaultValues, errors },
  } = useForm({
    defaultValues: async () =>
      apiServer.get(`/tag/find/${id}`).then((response) => {
        setIsActive(response.data.tag.isActive);
        return {
          code: response.data.tag.code,
          categoryId: response.data.tag.categoryId,
        };
      }),
  });

  const onSubmit = useCallback(
    async (data) => {
      setLoading(true);

      await apiServer
        .put(`tag/update/${id}`, {
          ...data,
          isActive,
        })
        .then((response) => {
          setLoading(false);

          if (!response.data) {
            throw new Error('Error adding the tag');
          }

          setSelectedCategory('');
          reset();

          setModalType('success');
          setModalOpen(true);
        })
        .catch((err) => {
          console.error(err);
          setModalType('error');
          setModalOpen(true);
          setLoading(false);
        });
    },
    [isActive],
  );

  const handleCategorySelect = (selectedCategoryId) => {
    setSelectedCategory(selectedCategoryId);
  };

  const handleCloseModal = () => {
    navigate(-1);
  };

  return (
    <>
      <PageTitle title="Adicionar Tag" />
      <form
        onSubmit={handleSubmit(onSubmit)}
        id="adicionar-tag"
        className={styles.container}
      >
        <div className={styles.tagForm}>
          <p className={styles.title}>TAGS</p>
          <FormTag
            control={control}
            onCategorySelect={handleCategorySelect}
            defaultValues={defaultValues}
            errors={errors}
          />
          <input type="hidden" name="categoryId" value={selectedCategory} />
        </div>
      </form>

      {isModalOpen && (
        <ModalDialogbox
          open={isModalOpen}
          type={modalType}
          onClose={handleCloseModal}
          text={
            modalType === 'error'
              ? 'Erro ao concluir ação. Por favor, \nTente novamente mais tarde ou entre em contato com o suporte.'
              : 'Tag adicionada com sucesso!'
          }
        />
      )}

      {isLoading && (
        <ModalDialogbox
          open={isModalOpen}
          type={modalType}
          text="Por favor, aguarde enquanto concluímos o carregamento..."
        />
      )}
    </>
  );
}

export default EditTag;
