import React, { useCallback, useEffect, useState } from 'react';

import InputComponent from 'components/input';
import { Controller } from 'react-hook-form';

import apiServer from 'api/server';
import useAuth from 'hooks/useAuth';
import HandleErrors from 'utils/handleErrors';
import SelectComponent from 'components/select';
import styles from './styles.module.sass';

function FormDistributionCenter({ control, disabledForm, setValue, disableForm, errors, isView }) {
  const [brands, setBrands] = useState([]);
  const { userBrands } = useAuth();

  const handleBrands = useCallback(
    (event) => {
      const list = [];
      const { value } = event.target;

      if (value) {
          const findBrandId = brands.find((b) => b.id === value);
          list.push(findBrandId.brandId);
        
        setValue('brandsId', list);
      }
    },
    [brands, setValue],
  );

  useEffect(() => {
    apiServer
      .get('/brands/all')
      .then((response) => {
        const data = response.data.brands
          .map((b) => ({
            id: b.code,
            brandId: b.id,
            content: b.name,
          }))
          .filter((b) => !!userBrands.find((ub) => ub === b.brandId));

        setBrands(data);

        handleBrands({
          target: {
            value: '',
          },
        });
      })
      .catch((err) => {
        HandleErrors(err.response.status)
        setBrands([]);
      });
  }, [])

  return (
    <form
      className={styles.form}
    >
      {!isView && (
        <Controller
          name="brands"
          control={control}
          rules={{
            required: {
              message: 'É obrigatório selecionar pelo menos uma marca!',
              value: true,
            },
          }}
          render={({ field: { onChange, value } }) => (
            <SelectComponent
              label="Marcas"
              disabled={disableForm}
              defaultValue={value}
              className={styles.select}
              onChange={(e) => {
                onChange(e);
                handleBrands(e);
              }}
              hasLabels
              placeholder="Selecione a(s) marca(s)"
              listSelects={brands}
              error={errors?.brands?.message ?? undefined}
            />
          )}
        />
      )}

      <Controller
        name="name"
        control={control}
        rules={{ required: {value: true, message: 'Coloque um nome no centro de distribuição'} }}
        render={({ field: { onChange, value }, fieldState: {error} }) => (
          <InputComponent
            label="Nome"
            multiline
            disabled={disabledForm}
            defaultValue={value}
            onChange={onChange}
            placeholder="Insira um nome para o centro de distribuição"
            className={styles.input}
            error={error?.message}
          />
        )}
      />

    </form>
  )
}

export default FormDistributionCenter;
