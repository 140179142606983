// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_menu__JigV8 {\n  display: flex;\n  flex-direction: row;\n  overflow: hidden;\n}\n.styles_menu__JigV8 .styles_outlet__7vO4V {\n  margin-top: 130px;\n  margin-bottom: 80px;\n  margin-left: 48px;\n  width: 79%;\n  flex: 1 1;\n}", "",{"version":3,"sources":["webpack://./src/screens/App/styles.module.sass"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,gBAAA;AACF;AACE;EACE,iBAAA;EACA,mBAAA;EACA,iBAAA;EACA,UAAA;EACA,SAAA;AACJ","sourcesContent":[".menu\n  display: flex\n  flex-direction: row\n  overflow: hidden\n\n  .outlet\n    margin-top: 130px\n    margin-bottom: 80px\n    margin-left: 48px\n    width: 79%\n    flex: 1\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu": "styles_menu__JigV8",
	"outlet": "styles_outlet__7vO4V"
};
export default ___CSS_LOADER_EXPORT___;
