import React, { useCallback, useEffect, useState } from 'react'
import { Autocomplete, TextField } from '@mui/material'

function AutocompleteComponent ({options, className, label, placeholder, onChange, value, disabled}) {
  const [labelState, setLabelState] = useState(placeholder)
  const [values, setValues] = useState(value)

  useEffect(() => {
    setValues(value)
  }, [value])

  const handleInputFocus = useCallback(() => {
    setLabelState(label)
  }, [label])

  const handleInputBlur = useCallback(() => {
    setLabelState(placeholder)
  }, [placeholder])

  const handleChange = useCallback((event, val) => {
    if(onChange) { 
      onChange(val)
      setValues(val)
    }
  }, [onChange])

  return (
    <Autocomplete
      disablePortal
      options={options}
      disabled={disabled}
      className={className}
      onChange={handleChange}
      isOptionEqualToValue={(o, v) => o === v}
      value={values || ''}
      sx={{ width: '100%' }}
      renderInput={(params) => 
        <TextField
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          label={labelState}
          {...params}
        />}
    />
  )
}

export default AutocompleteComponent