import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import Table from 'components/table';
import SearchBar from 'components/searchBar';
import PageTitle from 'components/pageTitle';
import ModalDialogbox from 'components/modals/dialogbox';
import Sort from 'components/sort';

import apiServer from 'api/server';

import { ReactComponent as AddMessage } from 'statics/icons/addMessage.svg';
import { ReactComponent as CloseToast } from 'statics/icons/close.svg';

import styles from './styles.module.sass';

function Messages() {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [restoreRows, setOriginalRows] = useState([]);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [openModalSucess, setOpenModalSucess] = useState(false);
  const [openModalError, setOpenModalError] = useState(false);
  const [deleteId, setDeleteId] = useState('');

  const toastId = useRef();


  const fetchData = () => {
    apiServer
      .get('/message/all')
      .then((response) => {
        const updatedRows = response.data.messages.map((message) => ({
          ...message,
          activation: message.isActive,
        }));
  
        setRows(updatedRows);
        setOriginalRows(updatedRows);
      })
      .catch((err) => {
        console.error('Erro na requisição:', err);
      });
  }

  useEffect(() => {
    fetchData()
  }, []);

  const handleSearch = useCallback(
    (event) => {
      const {
        target: { value },
      } = event;
      const regex = new RegExp(value, 'i');
      if (value === '') {
        setRows(restoreRows);
      } else {
        const rowFiltered = restoreRows.filter((row) => regex.test(row.name));
        setRows(rowFiltered);
      }
    },
    [restoreRows],
  );

  const handleEdit = (e) => {
    navigate(`editar/${e.id}`);
  };

  const handleOpenModal = (e) => {
    setOpenModalConfirm(!openModalConfirm);
    setDeleteId(e.id);
  };

  const handleCloseModal = () => {
    setOpenModalConfirm(false);
    setOpenModalSucess(false);
    setOpenModalError(false);
  };

  const handleDelete = useCallback(async () => {
    setOpenModalConfirm(!openModalConfirm);
    try {
      await apiServer.delete(`/message/delete/${deleteId}`);

      setOpenModalSucess(true);
      fetchData()
  } catch (error) {
      setOpenModalError(true);
    }
  }, [deleteId]);
  const handleSort = (sort) => {
    setRows(sort);
  };

  const handleSwicth = async ({row, status}) => {
    const response = await apiServer.put(`message/update/${row.id}`, {
      isActive: status,
      name: row.name,
      description: row.description,
      chatMessage: row.chatMessage,
      voiceMessage: row.voiceMessage,
      tagId: row.tag.id,
      numberTimesDrop: row.numberTimesDrop
    })
      .then(() => {
        toastId.current = toast.success(`Mensagem ${status ? 'ativada' : 'desativada'} com sucesso!`, {
          position: 'top-right',
          autoClose: 2000,
          closeButton: <CloseToast />,
          icon: false,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'light',
        });

        return status;
      })
      .catch(() => {
        toastId.current = toast.error('Não foi possivel concluir a ação', {
          position: 'top-right',
          autoClose: 2000,
          closeButton: <CloseToast />,
          icon: false,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'light',
        });

        return 'error';
      })

      return response;
  }

  return (
    <div className={styles.container}>
      <div className={styles.addMessageWrapper}
       onClickCapture={() => navigate('adicionar-mensagem')}>
        <AddMessage
          className={styles.addMessage}
        />
      </div>

      <PageTitle title="Mensagens" />

      <SearchBar rows={rows} onSearch={handleSearch} />

      <Sort rows={rows} onSort={handleSort} />


    <Table
      page="messages"
      rows={rows}
      numberOfRows={5}
      onSwitchStatus={handleSwicth}
      onClickEdit={handleEdit}
      onClickTrash={handleOpenModal}
    />

      <ModalDialogbox
        open={openModalConfirm}
        type="options"
        onClose={handleCloseModal}
        text="Tem certeza de que deseja excluir essa Mensagem?"
        buttonsLabel={['VOLTAR', 'EXCLUIR']}
        onLeftButton={handleCloseModal}
        onRightButton={handleDelete}
      />

      <ModalDialogbox
        open={openModalSucess}
        type="success"
        onClose={handleCloseModal}
        text="A Mensagem foi exluida com sucesso!"
      />

      <ModalDialogbox
        open={openModalError}
        type="error"
        onClose={handleCloseModal}
        text="Erro ao concluir ação. Por favor, tente novamente mais tarde ou entre em contato com o suporte."
      />

      <ToastContainer />
    </div >
  );
}

export default Messages;
