import React from 'react';
import { useForm } from 'react-hook-form';

import PageTitle from 'components/pageTitle';

import FormAccessReports from 'components/formAccessReports';

import styles from './styles.module.sass';

function AccessReports() {
  const { handleSubmit, control } = useForm();

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <>
      <PageTitle title="Relatório de acessos" />
      <form
        onSubmit={handleSubmit(onSubmit)}
        id="access-reports"
        className={styles.container}
      >
        <div>
          <FormAccessReports control={control} />
        </div>
      </form>
    </>
  );
}

export default AccessReports;
