import React, { useCallback, useEffect, useState } from 'react';
import { DateTimePicker } from '@mui/x-date-pickers';
import { Controller } from 'react-hook-form';
import dayjs from 'dayjs';

import useAuth from 'hooks/useAuth';

import InputComponent from 'components/input';
import SelectComponent from 'components/select';

import { ReactComponent as Calendar } from 'statics/icons/calendar.svg';

import HandleErrors from 'utils/handleErrors';

import apiServer from 'api/server';

import styles from './styles.module.sass';

function FormActivation({ control, errors, setValue, disableForm}) {
  const [brands, setBrands] = useState([]);
  const [tags, setTags] = useState([]);
  const [messages, setMessages] = useState([]);

  const [, setTag] = useState('');
  const [, setMessage] = useState('');
  const [dateOf, setDateOf] = useState('');
  const [untilDate, setUntilDate] = useState('');

  const { userBrands } = useAuth();

  const calendar = () => <Calendar />;

  const handleDateOf = useCallback(({ element, onChange }) => {
    const date = new Date(element.$d);

    setDateOf(date);
    onChange(date);
  }, []);

  const handleUntilDate = useCallback(({ element, onChange }) => {
    const date = new Date(element.$d);

    setUntilDate(date);
    onChange(date);
  }, []);

  const handleBrands = useCallback(
    (event) => {
      const list = [];
      const { value } = event.target;

      if (value) {
          const findBrandId = brands.find((b) => b.id === value);
          list.push(findBrandId.brandId);
        
        setValue('activation.brandsId', list);
      }
    },
    [brands, setValue],
  );

  const handleTag = useCallback(
    (event) => {
      const { value } = event.target;

      if (value) {
        const findTagId = tags.find((b) => b.id === value);

        setValue('activation.tagId', findTagId.id);
      }
    },
    [tags, setValue],
  );

  const handleMessage = useCallback(
    (event) => {
      const { value } = event.target;

      if (value) {
        const findMessageId = messages.find((b) => b.id === value);
        setValue('activation.messageId', findMessageId.id);
        setValue('activation.tagId', findMessageId.tag);
      }
    },
    [messages, setValue, tags],
  );

  useEffect(() => {
    apiServer
      .get('/brands/all')
      .then((response) => {
        const data = response.data.brands
          .map((b) => ({
            id: b.code,
            brandId: b.id,
            content: b.name,
          }))
          .filter((b) => !!userBrands.find((ub) => ub === b.brandId));

        setBrands(data);

        handleBrands({
          target: {
            value: '',
          },
        });
      })
      .catch((err) => {
        HandleErrors(err.response.status)
        setBrands([]);
      });

    apiServer
      .get('/tag/all')
      .then((response) => {
        const data = response.data.tags.map((b) => ({
          id: b.id,
          content: b.code,
        }));
        setTags(data);
        setTag('');

        handleTag({
          target: {
            value: '',
          },
        });
      })
      .catch((err) => {
        HandleErrors(err.response.status)
        setTags([]);
        setTag('');
      });

    apiServer
      .get('/message/all')
      .then((response) => {
        const data = response.data.messages.map((b) => ({
          id: b.id,
          tag: b.tag.id,
          content: `${b.tag.code} - ${b.name}`,
        }));

        setMessages(data);
        setMessage('');
      })
      .catch((err) => {
        HandleErrors(err.response.status)
        setMessages([]);
        setMessage('');
      });
  }, [userBrands]);

  return (
    <div className={styles.form}>
      <Controller
        name="activation.brands"
        control={control}
        rules={{
          required: {
            message: 'É obrigatório selecionar pelo menos uma marca!',
            value: true,
          },
        }}
        render={({ field: { onChange, value } }) => (
          <SelectComponent
            label="Marcas"
            disabled={disableForm}
            defaultValue={value}
            onChange={(e) => {
              onChange(e);
              handleBrands(e);
            }}
            hasLabels
            placeholder="Selecione a(s) marca(s)"
            className={styles.selects}
            listSelects={brands}
            error={errors.activation?.brands?.message ?? undefined}
          />
        )}
      />

      <Controller
        name="activation.tagId"
        control={control}
        rules={{
          required: {
            message: 'É obrigatório selecionar uma URA (TAG)!',
            value: true,
          },
        }}
        render={({ field: { onChange, value } }) => (
          <SelectComponent
            className={styles.selects}
            label="URA (TAG)"
            disabled={disableForm}
            defaultValue={value}
            placeholder="Selecione a URA (TAG)"
            listSelects={tags}
            onChange={(e) => {
              onChange(e);
              handleTag(e);
            }}
            error={errors.activation?.tagId?.message ?? undefined}
          />
        )}
      />

      <Controller
        name="activation.messageId"
        control={control}
        rules={{
          required: {
            message: 'É obrigatório selecionar uma mensagem!',
            value: true,
          },
        }}
        render={({ field: { onChange, value } }) => (
          <SelectComponent
            className={styles.selects}
            label="Mensagem"
            disabled={disableForm}
            defaultValue={value}
            placeholder="Selecione a mensagem"
            listSelects={messages}
            onChange={(e) => {
              onChange(e);
              handleMessage(e);
            }}
            error={errors.activation?.messageId?.message ?? undefined}
          />
        )}
      />

      <Controller
        name="activation.description"
        control={control}
        rules={{
          required: {
            message: 'É obrigatório informar uma descrição para a notificação!',
            value: true,
          },
        }}
        render={({ field: { onChange, value } }) => (
          <InputComponent
            label="Descrição"
            multiline
            disabled={disableForm}
            defaultValue={value}
            onChange={onChange}
            placeholder="Insira uma descrição para a notificação"
            className={styles.input}
            error={errors.activation?.description?.message ?? undefined}
          />
        )}
      />

      <div className={styles.dateInputs}>
        <Controller
          name="activation.dateStart"
          control={control}
          rules={{
            required: {
              message: 'É obrigatório informar uma data!',
              value: true,
            },
          }}
          render={({ field: { onChange, value }, fieldState: {error} }) => (
          <DateTimePicker
            label="Data de"
            ampm={false}
            disabled={disableForm}
            value={dayjs(value)}
            onChange={(element) => handleDateOf({ element, onChange })}
            sx={{
              fontSize: 15,
              flex: 1,
            }}
            className={styles.dateInputsMargin}
            slots={{
              openPickerIcon: calendar,
            }}
            slotProps={{
              textField: {
                error
              }
            }}
          />
          )}
        />

        <Controller
          name="activation.dateEnd"
          control={control}
          rules={{
            required: {
              message: 'A data inicial deve ser maior que a final',
              value: true,
            },
            validate: () => new Date(dateOf) < new Date(untilDate) || 'A data inicial deve ser maior que a final',
          }}
          render={({ field: { onChange, value }, fieldState: {error} }) => (
            <DateTimePicker
              label="Data até"
              disabled={disableForm}
              value={dayjs(value)}
              onChange={(element) => handleUntilDate({ element, onChange })}
              sx={{
                fontSize: 15,
                flex: 1,
              }}
              ampm={false}
              slots={{
                openPickerIcon: calendar,
              }}
              slotProps={{
                textField: {
                  error
                }
              }}
            />
          )}
          />
      </div>
      
      {errors.activation?.dateEnd?.message && (
        <p className={styles.errorDate}>
          {errors.activation?.dateEnd?.message}
        </p>
      )}

    </div>
  );
}

export default FormActivation;
