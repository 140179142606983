import React, { useCallback,  useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import axios from 'axios';

import { Checkbox, FormControlLabel } from '@mui/material';

import apiServer from 'api/server';

import Table from 'components/table';
import InputComponent from 'components/input';
import SelectComponent from 'components/select';
import AutocompleteComponent from 'components/autocomplete';

import { ReactComponent as CircleAdd } from 'statics/icons/circleAdd.svg';
import { ReactComponent as Info } from 'statics/icons/info.svg';
import { ReactComponent as Trash } from 'statics/icons/trash.svg';

import HandleErrors from 'utils/handleErrors';
import styles from './styles.module.sass';

const CEP_LENGTH = 8;

function Cep({ control, onSelectZipcode, rows,disableForm }) {
  const [isValidCep, setIsValidCep] = useState(true);
  const [selectedZipcode, setSelecteZipcode] = useState(rows);
  const [duplicate, setDuplicate] = useState(false);
  const [zipcodeData, setZipcodeData] = useState();

  useEffect(() => {
    setSelecteZipcode(rows)
  }, [rows])

  const viaCep = async (cep) => {
    const clearCep = cep.replace(/\D/g, '');
    try {
      const response = await axios
        .get(`https://viacep.com.br/ws/${clearCep}/json/`)
        .then((r) => r);

      setZipcodeData(response.data);
      return response.data;
    } catch (error) {
      setZipcodeData(undefined);
      setIsValidCep(false);

      return undefined;
    }
  };

  const handleAdd = useCallback(
    async (cep) => {
      try {
        const response = await viaCep(cep);
        if (response.erro) {
          setIsValidCep(false);
        } else if (response) {
          setIsValidCep(true);

          const list = [...selectedZipcode];

          const maskCep = cep.replace(/\D/g, '');

          if (!list.find((e) => e?.zipcode === maskCep)) {
            list.push({
              id: list.length + 1,
              zipcode: maskCep,
              neighborhood: response.bairro,
              cities: response.localidade,
            });
          } else {
            setDuplicate(true);
          }

          setSelecteZipcode(list);

          if (onSelectZipcode) {
            onSelectZipcode(list);
          }
        }
      } catch (error) {
        setIsValidCep(false);
      }
    },
    [selectedZipcode],
  );

  const clearMessages = () => {
    setIsValidCep(true);
    setDuplicate(false);
  };

  const handleChange = (event, onChange) => {
    if (event.target.value.length >= CEP_LENGTH) {
      viaCep(event.target.value);
    }
    onChange(event);
    clearMessages();
  };

  const handleRemove = useCallback(
    (rowToRemove) => {
      const list = [...selectedZipcode];

      const findIndex = selectedZipcode.findIndex(
        (item) => item.id === rowToRemove.id,
      );

      list.splice(findIndex, 1);

      setSelecteZipcode(list);

      if (onSelectZipcode) {
        onSelectZipcode(list);
      }
    },
    [selectedZipcode],
  );

  return (
    <div>
      <Controller
        name="staticZipcode"
        control={control}
        render={({ field: { onChange, value }, formState: { isDirty } }) => {
          if (isDirty && !selectedZipcode) {
            handleAdd(value);
          }
          return (
            <div className={styles.cep}>
              <div className={styles.inputCep}>
                <InputComponent
                  label="CEP"
                  disabled={disableForm}
                  placeholder="Insira o CEP"
                  defaultValue={value || ''}
                  onChange={(event) => handleChange(event, onChange)}
                  multiple
                />
                {!isValidCep && (
                  <p className={styles.cepIsInvalid}>Digite um CEP válido</p>
                )}
                {duplicate && (
                  <p className={styles.cepIsInvalid}>CEP já adicionado</p>
                )}
              </div>
              <CircleAdd
                onClickCapture={() => value && handleAdd(value)}
                className={value ? styles.buttonAdd : styles.buttonAddDisabled}
              />

              {zipcodeData?.localidade && value.length >= CEP_LENGTH && (
                <p className={styles.address}>
                  {`[${zipcodeData.localidade}] ${zipcodeData.logradouro} - ${zipcodeData.bairro}`}
                </p>
              )}
            </div>
          );
        }}
      />
      <Table
        page="distributionCenterZipcode"
        rows={selectedZipcode}
        numberOfRows={2}
        disabledActions={disableForm}
        removeEdit
        onClickTrash={handleRemove}
      />
    </div>
  );
}

function Neighborhood({ control, onSelectNeighborhood, rows, resetField, disableForm, selectedBrands}) {
  const [cities, setCities] = useState([]);
  const [neighborhood, setNeighborhood] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');
  
  const fetchDataCities = useCallback(async () => {
    const list = [];

    selectedBrands.forEach(async (brand) => {
      await apiServer.get(`/cities/all/${brand}`).then((response) => {
        const { data } = response;

        const citieContent = data.cities.map((city) => ({
          id: city.id,
          label: city.name,
        }));

        list.push(...citieContent);
      });
    });

    setCities(list);
  }, [selectedBrands]);

  const fetchDataNeighborhoods = useCallback(
    async (idCity) => {
      const convertCityToUrl = encodeURI(idCity);
      const list = [];

      selectedBrands.forEach(async (brand) => {
        await apiServer
          .get(`/neighborhoods/all/${brand}/${convertCityToUrl}`)
          .then((response) => {
            const { data } = response;
            const neighborhoodsContent = data.neighborhoods.map(
              (neighborhoodContent) => ({
                id: neighborhoodContent.id,
                label: neighborhoodContent.name,
              }),
            );

            list.push(...neighborhoodsContent);
            setNeighborhood(list);
          })
          .catch((err) => {
            HandleErrors(err.response.status)
          });
      });

    },
    [selectedBrands],
  );

  useEffect(() => {
    fetchDataCities();
  }, [selectedBrands]);

  const handleSelectCity = useCallback((value) => {
    setSelectedCity(value?.id);
    
    if (value) {
      fetchDataNeighborhoods(value.label);

      resetField('staticNeighborhoods')
    }

  }, [resetField, cities])

  const handleAdd = useCallback(
    (value) => {
      const list = [...rows];

        if (!list.find((item) => item.id === value.id)) {
          const findIdNeighborhood = neighborhood.find((e) => e.id === value.id);
          const findIdCity = cities.find((e) => e.id === selectedCity);

          list.push({
            id: findIdNeighborhood.id,
            neighborhood: findIdNeighborhood.label,
            cityId: findIdCity.id,
            city: findIdCity.label,
          });
        }

      if (onSelectNeighborhood) {
        onSelectNeighborhood(list);
      }

      resetField('staticNeighborhoods')
    },
    [rows, selectedCity, neighborhood],
  );

  const handleRemove = useCallback(
    (rowToRemove) => {
      const list = [...rows];
      const findIndex = rows.findIndex(
        (item) => item.id === rowToRemove.id,
      );

      list.splice(findIndex, 1);

      if (onSelectNeighborhood) {
        onSelectNeighborhood(list);
      }
    },
    [rows],
  );

  return (
    <div className={styles.neighborhood}>
      <div className={styles.searchNeighborhood}>
        <AutocompleteComponent
          label="Cidades"
          disabled={disableForm}
          placeholder="Pesquise ou selecione a cidade"
          onChange={handleSelectCity}
          options={cities}
        />

        <Controller
          name="staticNeighborhoods"
          control={control}
          rules={{deps: 'activation.brandsId'}}
          render={({ field: { onChange, value }, fieldState: { isDirty } }) => (
            <div className={styles.selectNeighborhoodContainer}>
              <AutocompleteComponent
                label="Bairro"
                placeholder="Pesquise ou selecione o bairro"
                onChange={onChange}
                disabled={disableForm}
                value={value}
                isDirty={isDirty || false}
                className={styles.selectNeighborhood}
                options={neighborhood}
              />
              <CircleAdd
                onClickCapture={() => value && handleAdd(value)}
                className={value ? styles.buttonAdd : styles.buttonAddDisabled}
              />
            </div>
          )}
        />
      </div>
      <Controller
        name="neighborhoodsExcept"
        control={control}
        render={({ field: { onChange, value } }) => (
          <FormControlLabel
            control={
              <Checkbox
                style={styles.checkbox}
                defaultChecked={false}
                checked={!!value}
                onChange={onChange}
              />
            }
            label="Exceto"
            className={styles.checkboxLabel}
          />
        )}
      />

      <Table
        page="distributionCenterNeighborhood"
        rows={rows}
        numberOfRows={2}
        disabledActions={disableForm}
        removeEdit
        onClickTrash={handleRemove}
      />
    </div>
  );
}

function City({ control, onSelectCities, rows, disableForm, selectedBrands, resetField }) {

  const [city, setCity] = useState([]);

  const fetchDataCites = useCallback(() => {
    const list = [];

    selectedBrands.forEach((brand) => {
      apiServer.get(`cities/all/${brand}`).then((response) => {
        const { data } = response;
        const citiesContent = data.cities.map((cityContent) => ({
          id: cityContent.id,
          label: cityContent.name,
        }));

        list.push(...citiesContent);
      });
    });

    setCity(list);
  }, [selectedBrands]);

  useEffect(() => {
    fetchDataCites();
  }, [selectedBrands]);

  const hadleAddCities = useCallback(
    (value) => {
      const list = [...rows];
        if (!list.find((item) => item.cities === value.label)) {
          const findIdCity = city.find((e) => e.id === value.id);

          list.push({ id: findIdCity.id, cities: findIdCity.label });
        }

      if (onSelectCities) {
        onSelectCities(list);
      }

      resetField('staticCity')
    },
    [rows, city],
  );

  const handleRemove = useCallback(
    (rowToRemove) => {
      const list = [...rows];

      const findIndex = rows.findIndex(
        (item) => item.id === rowToRemove.id,
      );

      list.splice(findIndex, 1);

      if (onSelectCities) {
        onSelectCities(list);
      }
    },
    [rows],
  );

  return (
    <div>
      <Controller
        name="staticCity"
        control={control}
        render={({ field: { onChange, value } }) => (
          <div>
            <div className={styles.city}>
              <AutocompleteComponent
                label="Cidade"
                key={city.id}
                value={value}
                disabled={disableForm}
                placeholder="Pesquise ou selecione a cidade"
                onChange={onChange}
                className={styles.select}
                options={city}
              />
              <CircleAdd
                onClickCapture={() => value && hadleAddCities(value)}
                className={value ? styles.buttonAdd : styles.buttonAddDisabled}
              />
            </div>
          </div>
        )}
      />

      <Table
        page="distributionCenterCities"
        rows={rows}
        numberOfRows={2}
        removeEdit
        disabledActions={disableForm}
        onClickTrash={handleRemove}
      />
    </div>
  );
}

function Distribution({ control, onSelectDistribution, rows,disableForm }) {
  const [distribution, setDistribution] = useState([]);
  const [selectedDistribution, setSelectedDistribution] = useState(rows);

  useEffect(() => {
    apiServer
      .get('distribution-centers/all')
      .then((response) => {
        const { data } = response;

        if (!data || data.distributionCenters.length === 0) {
          setDistribution([]);
          return;
        }

        const distributionContent = data.distributionCenters.map(
          (distribuition) => ({
            id: distribuition.id,
            content: distribuition.name,
          }),
        );
        setDistribution(distributionContent);
      })
      .catch((err) => {
        HandleErrors(err.response.status)
        setDistribution([]);
      });
  }, []);

  const hadleAddDistributionCenters = useCallback(
    (value) => {
      const list = [...selectedDistribution];
      value.forEach((element) => {
        if (!list.find((item) => item.distribution === element)) {
          const findIdDistribution = distribution.find((e) => e.id === element);
          list.push({
            id: findIdDistribution.id,
            distribution: findIdDistribution.content,
          });
        }
      });

      setSelectedDistribution(list);

      if (onSelectDistribution) {
        onSelectDistribution(list);
      }
    },
    [selectedDistribution, distribution],
  );

  const handleRemove = useCallback(
    (rowToRemove) => {
      const list = [...selectedDistribution];

      const findIndex = selectedDistribution.findIndex(
        (item) => item.id === rowToRemove.id,
      );

      list.splice(findIndex, 1);

      setSelectedDistribution(list);

      if (onSelectDistribution) {
        onSelectDistribution(list);
      }
    },
    [selectedDistribution],
  );

  return (
    <div>
      <Controller
        name="staticDistribution"
        control={control}
        render={({ field: { onChange, value } }) => (
          <div className={styles.distribution}>
            <SelectComponent
              label="Centro de distribuição"
              key={distribution.id}
              disabled={disableForm}
              defaultValue={value || []}
              multiple
              placeholder="Digite o nome do centro de distribuição"
              onChange={onChange}
              className={styles.select}
              listSelects={distribution}
            />
            <CircleAdd
              onClickCapture={() => value && hadleAddDistributionCenters(value)}
              className={value ? styles.buttonAdd : styles.buttonAddDisabled}
            />
          </div>
        )}
      />

      <Table
        page="distributionCenterDistribution"
        rows={selectedDistribution}
        numberOfRows={2}
        removeEdit
        disabledActions={disableForm}
        onClickTrash={handleRemove}
      />
    </div>
  );
}


function Custom({ control, clearErrors, onSelectCustom, rows, resetField, disableForm, setFile}) {
  const [contracts, setContracts] = useState(rows || [])

  const handleChange = useCallback((e) => {
    const file = e.target.files
    const fileReader = new FileReader()
    setFile(file[0])
        
    if(file.length > 0) {
      fileReader.onload = (event) => {
        const csvOutput = event.target.result;
        const contratctsList = csvOutput.split('\r\n')

        const contractRows = contratctsList.map((contract, index) => ({
          id: index,
          contract
        }))

        setContracts(contractRows)
        onSelectCustom(contractRows)
      };
      
      fileReader.readAsText(file[0]);
    } else {
      setContracts([])
      onSelectCustom([])
      resetField('file')
    }


    clearErrors('filters')
  }, [setFile])

  const handleRemove = () => {
    setContracts([])
    onSelectCustom([])
    resetField('file')
  }

  return (
    <>
    <Controller
      name="file"
      control={control}
      render={({ field: { onChange, value } }) => (
        <form className={styles.custom}>
          <div className={styles.customInputFile}>
            <label htmlFor="custom" className={`${styles.inputLabel} ${disableForm && styles.inputDisabled}`}>
              <input
                id="custom"
                type="file"
                accept='.csv'
                value={!value ? '' : null}
                disabled={disableForm}
                onChange={(e) => {
                  onChange(e)
                  handleChange(e)
                }}
                className={styles.inputFile}
              />
              CARREGAR ARQUIVO
            </label>

            <div className={styles.filenameContainer}>
              <p className={styles.filename}>
                {value}
              </p>

              {value && (
                <div
                  className={styles.iconContainer}
                  onClickCapture={handleRemove}
                > 
                  <Trash className={styles.iconTrash} /> 
                </div>
              )}
            </div>
          </div>

          <p className={styles.text}>
            <Info className={styles.icon} />O formato do arquivo *CSV deve
            conter apenas uma coluna não tem a necessidade de titulo no
            documento.
          </p>
        </form>
      )}
    /> 
    <Table
      page="distributionCustom"
      rows={contracts}
      numberOfRows={2}
      disabledActions={disableForm}
      removeEdit
    />
    </>
  );
}

const TabsAddActivation = {
  Cep,
  City,
  Neighborhood,
  Distribution,
  Custom,
};

export default TabsAddActivation;
