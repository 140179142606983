/* eslint-disable react/no-unstable-nested-components */
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import apiServer from 'api/server';

import PageTitle from 'components/pageTitle';
import CreationForm from 'components/formUserCreation';

import ModalDialogbox from 'components/modals/dialogbox';
import styles from './styles.module.sass';

function EditUser() {
  const [userData, setUserData] = useState({});
  const [userEdit, setUserEdit] = useState(false);
  const [errorUserEdit, setErrorUserEdit] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    formState: { defaultValues },
  } = useForm({
    defaultValues: async () =>
      apiServer.get(`users/search/${id}`).then(({ data }) => {
        setUserData(data.user);

        return {
          email: data.user.email,
          login: data.user.login,
          name: data.user.name,
          role: data.user.role,
        };
      }),
  });

  const onSubmit = useCallback(
    (data) => {
      apiServer
        .put(`users/update/${id}`, {
          access: userData.access,
          brandsId: data.brand,
          email: data.email,
          login: data.login,
          name: data.name,
          role: data.role,
        })
        .then(() => {
          setUserEdit(true);
        })
        .catch(() => {
          setErrorUserEdit(true);
        });
    },
    [userData],
  );

  const handleCloseModal = () => {
    navigate('/usuarios');
  };

  return (
    <>
      <PageTitle title="Usuários" hasSubItem subItemText="Editar" />
      <form
        onSubmit={handleSubmit(onSubmit)}
        id="editar-usuario"
        className={styles.container}
      >
        <CreationForm control={control} defaultValues={defaultValues} />
      </form>

      <ModalDialogbox
        type="success"
        open={userEdit}
        onClose={handleCloseModal}
        text="Usuário editado com sucesso!"
      />

      <ModalDialogbox
        type="error"
        open={errorUserEdit}
        onClose={handleCloseModal}
        text="Não foi possivel editar o usuário"
      />
    </>
  );
}

export default EditUser;
