import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import PageTitle from 'components/pageTitle';
// eslint-disable-next-line import/no-named-as-default
import FormTag from 'components/formTag';

import apiServer from 'api/server';
import ModalDialogbox from 'components/modals/dialogbox';
import HandleErrors from 'utils/handleErrors';
import styles from './styles.module.sass';

function AddTag() {
  const [selectedCategory, setSelectedCategory] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState('error');
  const [modalMessage, setModalMessage] = useState('');
  const [isLoading, setLoading] = useState(false);

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    setLoading(true);

    await apiServer
      .post('tag/new', {
        ...data,
        isActive: true,
      })
      .then((response) => {
        setLoading(false);

        if (!response.data) {
          throw new Error('Error adding the message');
        }

        setModalMessage('Tag adicionada com sucesso!');

        setModalType('success');
        setModalOpen(true);
      })
      .catch((err) => {
        HandleErrors(err.response.status)

        if (err.response?.status === 409) {
          setModalMessage(
            'Registro já existe no banco de dados!\nPor favor, confirme as informações e tente novamente.',
          );
        } else {
          setModalMessage(
            'Erro ao concluir ação. Por favor, \nTente novamente mais tarde ou entre em contato com o suporte.',
          );
        }

        setModalType('error');
        setModalOpen(true);
        setLoading(false);
      });
  };

  const handleCategorySelect = (selectedCategoryId) => {
    setSelectedCategory(selectedCategoryId);
  };

  const handleCloseModal = () => {
    setModalOpen(false);

    if (modalType === 'success') navigate(-1);
  };

  return (
    <>
      <PageTitle title="Adicionar Tag" />
      <form
        onSubmit={handleSubmit(onSubmit)}
        id="adicionar-tag"
        className={styles.container}
      >
        <div className={styles.tagForm}>
          <p className={styles.title}>TAGS</p>
          <FormTag
            control={control}
            setValue={setValue}
            onCategorySelect={handleCategorySelect}
            errors={errors}
          />
          <input type="hidden" name="categoryId" value={selectedCategory} />
        </div>
      </form>

      {isModalOpen && (
        <ModalDialogbox
          open={isModalOpen}
          type={modalType}
          onClose={handleCloseModal}
          text={modalMessage}
        />
      )}

      {isLoading && (
        <ModalDialogbox
          open={isModalOpen}
          type={modalType}
          onRightButton={() => {}}
          onLeftButton={() => {}}
          text="Por favor, aguarde enquanto concluímos o carregamento..."
        />
      )}
    </>
  );
}

export default AddTag;
