import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Button from 'components/button';

import { ReactComponent as CloseIcon } from 'statics/icons/closeIcon.svg';

import BrandsCheckBox from './components/brandsCheckBox';
import FilterSelects from './components/filterSelects';
import FilterDates from './components/filterDates';

import styles from './styles.module.sass';

function Filters({ handleOpen, handleClose, className, handleModalSubmit }) {
  const { control, handleSubmit } = useForm();

  const onSubmit = (data) => {
    console.log(data);

    if (handleModalSubmit) handleModalSubmit(data);

    handleClose();
  };

  return (
    <form
      id="filtrar-notificacoes"
      className={`${styles.container} ${className}`}
      onSubmit={handleSubmit(onSubmit)}
      style={
        !handleOpen
          ? {
              display: 'none',
            }
          : {}
      }
    >
      <div className={styles.form}>
        <Dialog
          open={!!handleOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="lg"
          PaperProps={{
            style: { borderRadius: 16 },
          }}
        >
          <DialogTitle id="alert-dialog-title" className={styles.root}>
            <div>
              <span onClickCapture={handleClose} className={styles.icon}>
                <CloseIcon />
              </span>
              <span className={styles.title}>Filtros</span>
            </div>
          </DialogTitle>
          <DialogContent>
            <Box sx={{ width: '100%' }}>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={6}>
                  <BrandsCheckBox />
                </Grid>
                <Grid item xs={6}>
                  <FilterSelects control={control} />
                </Grid>
                <Grid item xs={8}>
                  <FilterDates control={control} />
                </Grid>
                <Grid item xs={2}>
                  <div>
                    <Button
                      label="FILTRAR NOTIFICAÇÃO"
                      form="filtrar-notificacoes"
                      type="submit"
                      className={styles.filterButton}
                    />
                  </div>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
        </Dialog>
      </div>
    </form>
  );
}

export default Filters;
