import React, { createContext, useMemo, useState } from 'react';
import jwt from 'jwt-decode';
import Cookies from 'js-cookie';
import apiServer from 'api/server';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [token, setToken] = useState(null);
  const [userBrands, setUserBrands] = useState([]);

  const hasToken = ({ authToken, brands }, callback) => {
    setToken(authToken);
    setUserBrands(brands);

    if (authToken) {
      apiServer.defaults.headers.Authorization = `Bearer ${authToken}`;
    }
    callback();
  };

  const signin = ({ authToken, brands }, callback) => {
    setToken(authToken);
    setUserBrands(brands);

    setTimeout(() => {
      callback();

      Cookies.set('token', authToken, { expires: 2 });
      Cookies.set('user_brands', JSON.stringify(brands), { expires: 2 });

      apiServer.defaults.headers.Authorization = `Bearer ${authToken}`;
    }, 100); // Fake async
  };

  const signout = () => {
    setToken(null);
    Cookies.remove('token');
    Cookies.remove('user_brands');
    Cookies.remove('user_brands');
    delete apiServer.defaults.headers.Authorization;
  };

  const value = useMemo(
    () => ({
      token,
      userBrands,
      signin,
      signout,
      hasToken,
    }),
    [token, userBrands, signin, signout, hasToken],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthContext;
