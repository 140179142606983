/* eslint-disable react/button-has-type */
import React from 'react';

import styles from './styles.module.sass';

function Button({
  label,
  color,
  isButtonWithe,
  className,
  bgColor,
  disabled,
  onClickCapture,
  form,
  type,
  ...props
}) {
  return (
    <button
      form={form}
      type={type || 'button'}
      onClickCapture={onClickCapture}
      className={`
      ${isButtonWithe ? styles.buttonWithe : styles.button}
      ${disabled && styles.disabled}
      ${className}
      `}
      style={{
        color: disabled ? '#fff' : color,
        borderColor: color,
        backgroundColor: bgColor,
      }}
      {...props}
    >
      {label}
    </button>
  );
}

export default Button;
