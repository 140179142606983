/* eslint-disable global-require */
import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';

import { Modal as ModalMui } from '@mui/material';

import Button from 'components/button';

import errorGif from 'statics/gifs/caution.gif';

import Loading from 'components/loading'
import Success from 'components/success'
import Warning from 'components/warning'
import styles from './styles.module.sass'

function ModalDialogbox({
  open,
  onClose,
  text,
  type,
  buttonsLabel,
  onLeftButton,
  onRightButton,
}) {
  const ref = useRef(null);

  useEffect(() => {
    const instanceSuccess = lottie.loadAnimation({
      container: ref.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      name: 'success',
      animationData: require('statics/lottie/success.json'),
    });

    instanceSuccess.destroy();
  }, [ref]);
  return (
    <ModalMui
      open={open}
      onClose={onClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      className={styles.container}
    >
      <div
        className={styles.modalBox}
      >
        {type === 'success' && (
          <Success
            className={styles.gif}
          />
        )}

        {type === 'error' && (
          <Warning
            className={styles.gif}
          />
        )}

        {type === 'loading' && (
          <Loading
            src={errorGif}
            alt="error_gif"
            className={styles.gifLoading}
          />
        )}

        {text}

        {type === 'options' && (
          <div className={styles.buttons}>
            <Button
              isButtonWithe
              onClickCapture={onLeftButton}
              label={buttonsLabel[0]}
              className={styles.leftButton}
              color="#f00"
            />
            <Button
              isButtonWithe
              onClickCapture={onRightButton}
              label={buttonsLabel[1]}
              color="#404085"
            />
          </div>
        )}
      </div>
    </ModalMui>
  );
}

export default ModalDialogbox;
