// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_dateInputs__glflJ {\n  display: flex;\n}\n.styles_dateInputs__glflJ .styles_dateInputsMargin__aFqkq {\n  margin-right: 8px;\n}", "",{"version":3,"sources":["webpack://./src/components/formAccessReports/components/dateInputs/styles.module.sass"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AACE;EACE,iBAAA;AACJ","sourcesContent":[".dateInputs\n  display: flex\n\n  .dateInputsMargin\n    margin-right: 8px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dateInputs": "styles_dateInputs__glflJ",
	"dateInputsMargin": "styles_dateInputsMargin__aFqkq"
};
export default ___CSS_LOADER_EXPORT___;
