import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import PageTitle from 'components/pageTitle';
import Table from 'components/table';
import SearchBar from 'components/searchBar';
import Sort from 'components/sort';

import apiServer from 'api/server'

import { ReactComponent as AddCategory } from 'statics/icons/addCategory.svg'

import ModalDialogbox from 'components/modals/dialogbox';
import styles from './styles.module.sass';

function DistributionCenter() {
  const [restoreRow, setRowOriginal] = useState([]);
  const [rows, setRows] = useState([]);
  const [openModalSucess, setOpenModalSucess] = useState(false);
  const [openModalNotDelete, setOpenModalNotDelete] = useState(false);
  const [openModalError, sertOpenModalError] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [idDelete, setIdDelete] = useState();

  const fetchData = () => {
    apiServer
      .get('distribution-centers/all')
      .then((response) => {
        const { data } = response;
        const distributionCentersId = data.distributionCenters.map((distributionCenter) => ({
          id: distributionCenter.id,
          name: distributionCenter.name,
          activation: true,
        }));
        setRowOriginal(distributionCentersId);
        setRows(distributionCentersId);
      })
      .catch((error) => {
        // Trate o erro aqui
        console.error(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleShort = (sortRows) => {
    setRows(sortRows);
  };

  const handleSearch = useCallback(
    (event) => {
      const {
        target: { value },
      } = event;
      const regex = new RegExp(value, 'i');
      if (value === '') {
        setRows(restoreRow);
      } else {
        const filteredRows = restoreRow.filter((row) => regex.test(row.name));
        setRows(filteredRows);
      }
    },
    [restoreRow],
  );

  const navigate = useNavigate();

  const handleEye = (row) => {
    navigate(`${row.id}`)
  }

  const handleConfirmDelete = (row) => {
    setIdDelete(row.id)
    setOpenModalConfirm(true)
  }

  const handleCloseModal = () => {
    setOpenModalSucess(false)
    sertOpenModalError(false)
    setOpenModalConfirm(false)
    setOpenModalNotDelete(false)
  }

  const handleDelete = useCallback(() => {
    apiServer.delete(`distribution-centers/delete/${idDelete}`)
      .then(({data}) => {
        if(data.deleted) {
          handleCloseModal()
          setOpenModalSucess(true)
          fetchData()
        } else {
          handleCloseModal()
          setOpenModalNotDelete(true)
        }
      })
      .catch(() => {
        handleCloseModal()
        sertOpenModalError(true)
      })
  }, [idDelete])

  return (
    <div className={styles.container}>
      <AddCategory
        className={styles.addCategory}
        onClickCapture={() => navigate('adicionar-centro-de-distribuicao')}
      />

      <PageTitle title="Centro de Distribuição" />

      <SearchBar onSearch={handleSearch} />

      <Sort hasDate sortRows={handleShort} />

      <Table
        page="centro-de-distribuicao" 
        rows={rows} 
        numberOfRows={5}
        removeEdit
        addEye
        onClickEye={handleEye}
        onClickTrash={handleConfirmDelete}
      />

      <ModalDialogbox
        open={openModalConfirm}
        type="options"
        buttonsLabel={['excluir', 'voltar']}
        onClose={handleCloseModal}
        onRightButton={handleCloseModal}
        onLeftButton={handleDelete}
        text="Tem certeza que deseja excluir esse centro de distribuição?"
      />

      <ModalDialogbox
        open={openModalSucess}
        type="success"
        onClose={handleCloseModal}
        text="O centro de distribuição foi excluido com sucesso!"
      />

      <ModalDialogbox
        open={openModalNotDelete}
        type="error"
        onClose={handleCloseModal}
        text="Não foi possivel excluir esse centro de distribuição, pois ele já está em uso."
      />

      <ModalDialogbox
        open={openModalError}
        type="error"
        onClose={handleCloseModal}
        text="Erro ao concluir ação. Por favor, tente novamente mais tarde ou entre em contato com o suporte."
      />
    </div>
  );
}

export default DistributionCenter;
