import React, { useCallback, useEffect, useState } from 'react';
import { Switch } from '@mui/material';
import styled from 'styled-components';

const SwitchComponent = styled(Switch)(() => ({
  '& .MuiSwitch-switchBase': {
    color: '#878787',
  },
  '.MuiSwitch-track': {
    backgroundColor: '#878787',
  },
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#43EF68',
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#43EF68',
  },
}));

function GreenSwitch({onSwitchStatus, row, defaultChecked, ...props}) {
  const [checked, setChecked] = useState(defaultChecked)

  const handleChange = useCallback(async (event) => {
    if(onSwitchStatus){
       const data = await onSwitchStatus({
        row,
        status: event.target.checked
      })

      if(data ===  'error') {
        setChecked(false)
      } else {
        setChecked(data)
      }
    }

  }, [onSwitchStatus, row])

  return <SwitchComponent
      onChange={handleChange}
      size="small"
      checked={checked}
      {...props}
    />;
}

export default GreenSwitch;
