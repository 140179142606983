import React, { useCallback, useState } from 'react';

import { ReactComponent as SortArrow } from 'statics/icons/sortArrow.svg';

import styles from './styles.module.sass';

function Sort({ onSort, rows }) {
  const [activeSortIndex, setActiveSortIndex] = useState(0);

  const sortTypes = ['Mais recentes', 'Mais antigas', 'A - Z', 'Z - A'];

  const handleSort = useCallback(() => {
    const index = activeSortIndex === sortTypes.length - 1 ? 0 : activeSortIndex + 1;

    setActiveSortIndex(index);

    const list = [...rows];

    switch (sortTypes[index]) {
      case 'Mais antigas':
        onSort(
          list.sort((a, b) => (new Date(a.dateOf) > new Date(b.dateOf) ? -1 : 1)),
        );
        break;

      case 'Mais recentes':
        onSort(
          list.sort((a, b) => (new Date(a.dateOf) < new Date(b.dateOf) ? -1 : 1)),
        );
        break;

      case 'A - Z':
        onSort(list.sort((a, b) => (a.message < b.message ? -1 : 1)));
        break;

      case 'Z - A':
        onSort(list.sort((a, b) => (a.message > b.message ? -1 : 1)));
        break;

      default:
        onSort(rows);
        break;
    }
  }, [activeSortIndex, sortTypes, rows, onSort]);

  return (
    <div onClickCapture={handleSort} className={styles.container}>
      <SortArrow
        className={styles.sortArrowIcon}
      />
      {sortTypes[activeSortIndex]}
    </div>
  );
}

export default Sort;
