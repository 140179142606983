import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import useAuth from 'hooks/useAuth';

import { ReactComponent as Logo } from 'statics/icons/logoDark.svg';
import { ReactComponent as Info } from 'statics/icons/info.svg';

import InputComponent from 'components/input';

import Cookies from 'js-cookie';
import apiServer from 'api/server';
import SelectComponent from 'components/select';
import styles from './styles.module.sass';

function Login() {
  const [buttonDisable, setButtonDisable] = useState(false);
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const [brand, setBrand] = useState('');
  const [error, setError] = useState('');

  const [brands, setBrands] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();

  const from = location.state?.from?.pathname || '/';

  useEffect(() => {
    const token = Cookies.get('token');
    if (token) {
      auth.hasToken(
        {
          authToken: token,
          brands: JSON.parse(Cookies.get('user_brands')),
        },
        () => {
          navigate(from, { replace: true });
        },
      );
    }
  }, []);

  const handleBrand = (event) => {
    setBrand(event.target.value);
  };

  useEffect(() => {
    apiServer
      .get('/brands/all')
      .then((response) => {
        const data = response.data.brands.map((b) => ({
          id: b.code,
          content: b.name,
          brandId: b.id,
        }));
        setBrands(data);

        handleBrand({
          target: {
            value: data[0].id,
          },
        });
      })
      .catch(() => {
        setBrands([]);
      });
  }, []);

  useEffect(() => {
    if (brands.length > 0) setBrand(brands[0].id);
  }, [brands]);

  const login = useCallback(async () => {
    if (buttonDisable) {
      const data = await apiServer
        .post('auth/login', {
          username: user,
          password,
          brand,
        })
        .then((response) => response.data)
        .catch(() => undefined);

      if (data) {
        auth.signin({ authToken: data.token, brands: data.brands, role: data.role }, () => {
          navigate('/', { replace: true });
        });
        return;
      }

      setError('Usuário ou senha inválidos!');
    }
  }, [buttonDisable, user, password, brand]);

  useEffect(() => {
    setButtonDisable(password && user);
  }, [user, password]);

  const handleUser = (event) => {
    setUser(event.target.value);
  };

  const handlePassword = (event) => {
    setPassword(event.target.value);
  };

  return (
    <div className={styles.container}>
      <div className={styles.loginContainer}>
        <Logo className={styles.logo} />

        <div className={styles.form}>
          <InputComponent
            label="Usuário"
            placeholder="Insira seu usuário"
            onChange={handleUser}
            value={user}
            className={styles.input}
          />
          <InputComponent
            label="Senha"
            placeholder="Insira sua senha"
            onChange={handlePassword}
            value={password}
            password
            className={styles.input}
          />

          <SelectComponent
            label="Marca"
            hasLabels
            onChange={handleBrand}
            listSelects={brands}
            defaultValue={brand}
          />

          <button
            type="button"
            onClickCapture={login}
            label="entrar"
            className={`
              ${styles.button}
              ${buttonDisable ? styles.buttonColor : styles.buttonColorDisable}
            `}
          >
            entrar
          </button>
        </div>
        {error && (
          <span className={styles.error}>
            <Info />
            {error}
          </span>
        )}
      </div>
    </div>
  );
}

export default Login;
