import React, { useEffect, useState } from 'react';

import Button from 'components/button';

import { useLocation } from 'react-router-dom';
import styles from './styles.module.sass';

function FloatButtonForm() {
  const [labelButton, setLabelButton] = useState('');

  const { pathname } = useLocation();

  const screens = [
    {
      id: 0,
      routeName: 'ativar-notificacao',
      labelButton: 'ATIVAR NOTIFICAÇÃO',
      form: 'ativar-notificacao',
    },
    {
      id: 1,
      routeName: 'adicionar-categoria',
      labelButton: 'REGISTRAR',
      form: 'adicionar-categoria',
    },
    {
      id: 3,
      routeName: 'adicionar-mensagem',
      labelButton: 'REGISTRAR',
      form: 'adicionar-mensagem',
    },
    {
      id: 4,
      routeName: 'adicionar-centro-de-distribuicao',
      labelButton: 'REGISTRAR',
      form: 'adicionar-centro-de-distribuicao',
    },
    {
      id: 5,
      routeName: 'usuarios/editar',
      labelButton: 'REGISTRAR',
      form: 'editar-usuario',
    },
    {
      id: 6,
      routeName: 'adicionar-usuario',
      labelButton: 'REGISTRAR',
      form: 'adicionar-usuario',
    },
    {
      id: 7,
      routeName: 'mensagens/editar',
      labelButton: 'REGISTRAR',
      form: 'editar-mensagem',
    },
    {
      id: 7,
      routeName: 'categoria/editar',
      labelButton: 'REGISTRAR',
      form: 'editar-categoria',
    },
  ];

  const FIND_SCREEN = screens.find((screen) =>
    pathname.includes(screen.routeName),
  );

  useEffect(() => {
    setLabelButton(FIND_SCREEN?.labelButton);
  }, [FIND_SCREEN]);

  return (
    FIND_SCREEN && (
      <div className={styles.footerFloat}>
        <Button
          label={labelButton}
          form={FIND_SCREEN.form}
          type="submit"
          className={styles.floatButton}
        />
      </div>
    )
  );
}

export default FloatButtonForm;
