/* eslint-disable react/no-unstable-nested-components */
import React, { useCallback, useEffect, useState } from 'react';

import {
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material';

import brandColors from 'mockdata/brandColors.json';

import { ReactComponent as CheckboxIcon } from 'statics/icons/checkbox.svg';
import { ReactComponent as CheckboxCheckIcon } from 'statics/icons/checkboxCheck.svg';

import styles from './styles.module.sass';

function SelectComponent({
  label,
  index,
  className,
  disabled,
  listSelects,
  placeholder,
  defaultValue,
  resetValue,
  multiple,
  onChange,
  hasLabels,
  selectAll,
  isDirty,
  error,
  ...props
}) {
  const [labelState, setLabelState] = useState(placeholder);
  const [selectedItem, setSelectedItem] = useState(multiple ? [] : '');

  
  useEffect(() => {
    if(resetValue) {
      setSelectedItem(resetValue)
    }
  }, [resetValue])

  useEffect(() => {
    if (defaultValue && listSelects.length > 0) {
      setSelectedItem(defaultValue);
    }
  }, [defaultValue]);

  const handleClose = useCallback(() => {
    if (selectedItem) {
      setLabelState(placeholder);
    }
  }, [placeholder, selectedItem, defaultValue]);

  const handleOpen = useCallback(() => {
    setLabelState(label);
  }, [label]);

  const handleChange = useCallback(
    (event) => {
      const {
        target: { value },
      } = event;

      if (typeof value === 'number') setSelectedItem(`${value}`);
      else setSelectedItem(value);

      if (onChange) {
        onChange(event);
      }
    },
    [listSelects, selectedItem, hasLabels, selectAll],
  );

  const handleSelectHasLabel = useCallback(
    (select) => {
      if (!listSelects) return '';

      if (hasLabels) {
        return (
          <div
            className={styles.labelBrand}
            style={{
              background: brandColors.brands.find(
                (element) => `${element.code}` === `${select}`,
              )?.color,
            }}
          >
            {/* <DeleteSelectedItem /> */}
            <span>
              {listSelects.find((i) => i.id === select)?.content ?? ''}
            </span>
          </div>
        )
      }

      if (multiple) {
        return (
          <span>
            {select.length > 0
              ? select
                  .map((s) => {
                    const c =
                      listSelects.find(
                        (item) =>
                          `${item.id}` ===
                          (typeof s === 'object' ? `${select.id}` : `${s}`),
                      ) ?? '';

                    if (typeof c === 'object') return c.content;

                    return c;
                  })
                  .toString()
              : []}
          </span>
        );
      }

      if (String(select).length === 0 || listSelects.length === 0) return '';

      return String(select).length > 0
        ? listSelects.find(
            (item) =>
              `${item.id}` ===
              (typeof select === 'object' ? `${select.id}` : `${select}`),
          )?.content
        : '';
    },
    [listSelects, multiple, hasLabels],
  );

  const handleCheckbox = useCallback((item) => {
    if(typeof selectedItem === 'object') {
      return selectedItem.find(e => item.id === e) ? (
        <CheckboxCheckIcon
          className={styles.checkbox}
        />
        ) : (
          <CheckboxIcon
          className={styles.checkbox}
          />
          )
        } 
        return `${item.id}`=== `${selectedItem}` ? (
          <CheckboxCheckIcon
            className={styles.checkbox}
          />
        ) : (
          <CheckboxIcon
            className={styles.checkbox}
          />
        )
  }, [selectedItem, multiple])

  return (
    <FormControl sx={{ width: '100%' }} className={className}>
      <InputLabel id={`select-${index}`}>{labelState}</InputLabel>
      <Select
        id={`select-${index}`}
        labelId={`select-${index}`}
        label={labelState}
        multiple={multiple}
        disabled={disabled}
        className={styles.selectsValue}
        renderValue={handleSelectHasLabel}
        onChange={handleChange}
        onFocus={handleOpen}
        onBlur={handleClose}
        error={!!error}
        value={selectedItem}
        {...props}
      >
        {listSelects?.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {handleCheckbox(item)}
            <ListItemText primary={item?.content} />

          </MenuItem>
        ))}
      </Select>

      {!!error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  );
}

export default SelectComponent;
