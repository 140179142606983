import React, { useEffect, useMemo, useState } from 'react';
import * as Tabs from '@radix-ui/react-tabs';
import axios from 'axios';

import TabsAddActivation from './tabs';

import styles from './styles.module.sass';

function FilterAddActivation({
  control,
  renderFilters,
  setValue,
  resetField,
  clearErrors,
  defaultValues,
  disableForm,
  selectedBrands,
  setFile
}) {
  const [focusTab, setFocusTab] = useState(0);
  const [zipcodes, setZipcodes] = useState([])
  const [neighborhood, setNeighborhood] = useState([])
  const [cities, setCities] = useState([])
  const [distribution, setDistribution] = useState([])
  const [custom, setCustom] = useState([])

  const defineCitiesTable = async (n) => {
    const list = n.map((item, index) => ({
        id: index,
        cities: item.name
      }))

      setCities(list)
  }
  const defineDistributionTable = async (d) => {
    const list = d.map((item, index) => ({
        id: index,
        distribution: item.name
      }))

      setDistribution(list)
  }

  const defineNeighborhoodTable = async (n) => {
    const list = n.map((item, index) => ({
        id: index,
        neighborhood: item.name,
        city: item.city
      }))

      setNeighborhood(list)
  }

  const defineZipcodeTable = async (z) => {
    const test = []

    z.forEach(async (item, index) => axios.get(`https://viacep.com.br/ws/${item}/json/`)
      .then(({data}) => {
        test.push({
          id: index,
          zipcode: data.cep.replace(/\D/g, ''),
          neighborhood: data.bairro,
          cities: data.localidade
        })
        setZipcodes(test)
      })
    )
  }

  useEffect(() => {
    const activation = defaultValues?.activation
    if(activation) {
      defineCitiesTable(activation?.cities)
      defineDistributionTable(activation?.distribution)
      defineZipcodeTable(activation?.zipcodes)
      defineNeighborhoodTable(activation?.neighborhood)
      setCustom(activation?.contracts)
    }
  }, [defaultValues])

  const handleTabFocus = (index) => {
    setFocusTab(index);
    resetField('staticNeighborhoods')
    resetField('neighborhoodsExcept')
  };

  const handleZipcodes = (value) => {
    setZipcodes(value)
  }

  const handleNeighborhood = (value) => {
    setNeighborhood(value)
  }

  const handleCities = (value) => {
    setCities(value)
  }

  const handleDistribution = (value) => {
    setDistribution(value)
  }

  const handleCustom = (value) => {
    setCustom(value)
  }


  useEffect(() => {
    const zipcodesArray = []
    const neighborhoodArray = []
    const citiesArray = []
    const distributionArray = []

    zipcodes.forEach((item) => {
      zipcodesArray.push(item.zipcode)
    })

    neighborhood.forEach((item) => {
      neighborhoodArray.push({
        neighborhood: item.id,
        city: item.cityId,
      })
    })

    cities.forEach((item) => {
      citiesArray.push(item.id)
    })

    distribution.forEach((item) => {
      distributionArray.push(item.id)
    })

    setValue('activation.zipcodes', zipcodesArray)
    setValue('activation.neighborhoods', neighborhoodArray)
    setValue('activation.cities', citiesArray)
    setValue('activation.distributionCenter', distributionArray)

    if(zipcodesArray || neighborhoodArray || citiesArray || distributionArray) {
      clearErrors('filters')
    }
  }, [zipcodes, neighborhood, cities, distribution])


  const tabsData = [
    {
      id: 0,
      label: 'cep',
      content: <TabsAddActivation.Cep
        control={control}
        disableForm={disableForm}
        onSelectZipcode={handleZipcodes}
        rows={zipcodes}
      />,
    },
    {
      id: 1,
      label: 'bairro',
      content: <TabsAddActivation.Neighborhood
        control={control}
        disableForm={disableForm}
        onSelectNeighborhood={handleNeighborhood}
        rows={neighborhood}
        selectedBrands={selectedBrands}
        resetField={resetField}
      />,
    },
    {
      id: 2,
      label: 'cidade',
      content: <TabsAddActivation.City
        control={control}
        disableForm={disableForm}
        onSelectCities={handleCities}
        selectedBrands={selectedBrands}
        resetField={resetField}
        rows={cities}
      />,
    },
    {
      id: 3,
      label: 'distribuição',
      content: <TabsAddActivation.Distribution
        control={control}
        disableForm={disableForm}
        onSelectDistribution={handleDistribution}
        rows={distribution}
      />,
    }
  ];

  const tabs = useMemo(() => {
    if (renderFilters) {
      const list = [];

      renderFilters.forEach((filter) => {
        list.push(tabsData.find((tab) => filter === tab.label));
      });

      return list;
    }
    return tabsData;
  }, [renderFilters, tabsData]);

  return (
    <Tabs.Root
      className={styles.tabsRoot}
      orientation="vertical"
      defaultValue="tab-0"
    >
      <Tabs.List className={styles.tabsList}>
        {tabs.map((item, index) => (
          <Tabs.Trigger
            value={`tab-${index}`}
            key={item.id}
            onFocus={() => handleTabFocus(index)}
            className={`${styles.buttonTab} ${
              focusTab === index && styles.buttonTabFoccus
            }`}
          >
            {item.label}
          </Tabs.Trigger>
        ))}
      </Tabs.List>

      {tabs.map((item, index) => (
        <Tabs.Content value={`tab-${index}`} key={item.id}>
          {item.content}
        </Tabs.Content>
      ))}
    </Tabs.Root>
  );
}

export default FilterAddActivation;
