/* eslint-disable react/no-unstable-nested-components */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import PageTitle from 'components/pageTitle';
import FormMessage from 'components/formMessage';

import { ReactComponent as CloseToast } from 'statics/icons/close.svg';

import apiServer from 'api/server';
import ModalDialogbox from 'components/modals/dialogbox';
import EditMessageRequests from './EditMessageRequests';
import styles from './styles.module.sass';

function EditMessage() {
  const { id } = useParams();

  const [isLoading, setLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState('error');
  const [modalMessage, setModalMessage] = useState('');

  const [selectedTag, setSelectedTag] = useState('');

  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors, defaultValues },
  } = useForm({
    defaultValues: async () => {
      setLoading(true);

      return apiServer
        .get(`message/search/${id}`)
        .then((response) => {
          setLoading(false);

          return {
            name: response.data.message.name,
            description: response.data.message.description,
            chatMessage: response.data.message.chatMessage,
            voiceMessage: response.data.message.voiceMessage,
            tagId: response.data.message.tagId,
            numberTimesDrop: response.data.message.numberTimesDrop,
            dropCall: response.data.message.dropCall,
            isActive: response.data.message.isActive,
            audioCode: response.data.message.audioCode,
          };
        })
        .catch((err) => {
          console.log(err);

          setLoading(false);

          return {
            name: '',
            chatMessage: '',
            voiceMessage: '',
            tagId: '',
            numberTimesDrop: 0,
            audioCode: '',
            isActive: false,
            dropCall: false,
          };
        });
    },
  });

  const toastId = useRef(null);

  const { SearchMessages } = EditMessageRequests;

  const [messageParams, setMessageParams] = useState({});

  const onSubmit = useCallback(
    async (data) => {
      console.log(data)
      await apiServer
        .put(`message/update/${id}`, {
          ...data,
        })
        .then((response) => {
          setLoading(false);

          if (!response.data) {
            throw new Error('Error adding the message');
          }

          setModalMessage('Mensagem atualizada com sucesso!');

          setModalType('success');
          setModalOpen(true);
        })
        .catch((err) => {
          console.warn(err);

          setModalMessage(
            'Erro ao concluir ação. Por favor, \nTente novamente mais tarde ou entre em contato com o suporte.',
          );

          setModalType('error');
          setModalOpen(true);

          setLoading(false);
        });
    },
    [toastId],
  );

  const handleSearch = useCallback(async () => {
    const search = await SearchMessages({ id });
    setMessageParams(search);
  }, [id]);

  useEffect(() => {
    handleSearch();
  }, []);

  const handleTagSelect = (selectedTagId) => {
    setSelectedTag(selectedTagId);
  };

  const handleCloseModal = () => {
    setModalOpen(false);

    if (modalType === 'success') navigate(-1);
  };

  return (
    <>
      <PageTitle title="Mensagem" hasSubItem subItemText="Editar" />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={styles.container}
        id="editar-mensagem"
      >
        <div className={styles.messageForm}>
          <FormMessage
            control={control}
            titleValue={messageParams.name}
            setValue={setValue}
            getValues={getValues}
            onTagSelect={handleTagSelect}
            errors={errors}
            defaultValues={defaultValues}
          />
          <input type="hidden" name="tagId" value={selectedTag} />
        </div>
      </form>

      {isModalOpen && (
        <ModalDialogbox
          open={isModalOpen}
          type={modalType}
          onClose={handleCloseModal}
          text={modalMessage}
        />
      )}

      {isLoading && (
        <ModalDialogbox
          open={isModalOpen}
          type={modalType}
          onRightButton={() => {}}
          onLeftButton={() => {}}
          text="Por favor, aguarde enquanto concluímos o carregamento..."
        />
      )}
    </>
  );
}

export default EditMessage;
