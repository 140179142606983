import Cookies from 'js-cookie';

function HandleErrors (errorCode) {

  switch (errorCode) {
    case 401:
      Cookies.remove('token');
      Cookies.remove('user_brands');
      Cookies.remove('user_brands');

      window.location.reload();
      break;
  
    default:
      break;
  }
}


export default HandleErrors