/* eslint-disable react/no-unstable-nested-components */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import PageTitle from 'components/pageTitle';
import FormCategory from 'components/formCategory/index';

import apiServer from 'api/server';
import ModalDialogbox from 'components/modals/dialogbox';
import EditCategoryRequests from './EditCategoryRequests';
import styles from './styles.module.sass';

function EditCategory() {
  const { id } = useParams();

  const navigate = useNavigate();

  const [isModalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState('error');
  const [modalMessage, setModalMessage] = useState('');
  const [isLoading, setLoading] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors, defaultValues },
  } = useForm({
    defaultValues: async () => {
      setLoading(true);

      return apiServer
        .get(`category/search/${id}`)
        .then((response) => {
          setLoading(false);

          return {
            name: response.data.category.name,
          };
        })
        .catch((err) => {
          console.log(err);

          setLoading(false);

          return {
            name: '',
          };
        });
    },
  });

  const onSubmit = useCallback(async (data) => {
    setLoading(true);
    await apiServer
      .put(`category/update/${id}`, {
        name: data.name,
      })
      .then((response) => {
        setLoading(false);

        if (!response.data) {
          throw new Error('Error editing the message');
        }

        setModalMessage('Categoria editada com sucesso!');

        setModalType('success');
        setModalOpen(true);
      })
      .catch((error) => {
        console.warn(error);

        setModalMessage(
          'Erro ao concluir ação. Por favor, \nTente novamente mais tarde ou entre em contato com o suporte.',
        );

        setModalType('error');
        setModalOpen(true);

        setLoading(false);
      });
  }, []);

  const handleCloseModal = () => {
    setModalOpen(false);

    if (modalType === 'success') navigate(-1);
  };

  return (
    <>
      <PageTitle title="Categoria" hasSubItem subItemText="Editar" />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={styles.container}
        id="editar-categoria"
      >
        <div className={styles.categoryForm}>
          <FormCategory
            control={control}
            titleValue={defaultValues?.name ?? ''}
            errors={errors}
          />
        </div>
      </form>

      {isModalOpen && (
        <ModalDialogbox
          open={isModalOpen}
          type={modalType}
          onClose={handleCloseModal}
          text={modalMessage}
        />
      )}

      {isLoading && (
        <ModalDialogbox
          open={isModalOpen}
          type={modalType}
          onRightButton={() => {}}
          onLeftButton={() => {}}
          text="Por favor, aguarde enquanto concluímos o carregamento..."
        />
      )}
    </>
  );
}

export default EditCategory;
