/* eslint-disable react/no-unstable-nested-components */
import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import apiServer from 'api/server'

import PageTitle from 'components/pageTitle'
import CreationForm from 'components/formUserCreation'
import ModalDialogbox from 'components/modals/dialogbox'

import styles from './styles.module.sass'

function CreateUser() {
  const [userCreated, setUserCreated]= useState(false)
  const [errorUserCreate, setErrorUserCreate]= useState(false)

  const { handleSubmit, control } = useForm()

  const navigate = useNavigate()

  const onSubmit = useCallback((data) => {
    apiServer.post('users/new', {
      access: data.name,
      brandId: data.brand,
      email: data.email,
      login: data.login,
      name: data.name,
      role: data.role
    })
      .then(() => {
        setUserCreated(true)
      })
      .catch(() => {
        setErrorUserCreate(true)
      })
  }, [])

  const handleCloseModal = () => {
    navigate('/usuarios')
  }

  return (
    <>
      <PageTitle title="Usuários" hasSubItem subItemText="Adicionar" />
      <form
        onSubmit={handleSubmit(onSubmit)}
        id="adicionar-usuario"
        className={styles.container}
      >
        <CreationForm control={control} />
      </form>

      <ModalDialogbox
        type="success"
        open={userCreated}
        onClose={handleCloseModal}
        text="Usuário criado com sucesso!"
      />

      <ModalDialogbox
        type="error"
        open={errorUserCreate}
        onClose={handleCloseModal}
        text="Não foi possivel criar o usuário"
      />

    </>
  )
}

export default CreateUser