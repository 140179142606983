import React, { useEffect, useRef } from 'react';
import { Grid, Box } from '@mui/material';
import lottie from 'lottie-web';


import styles from './styles.module.sass';

function ErrorPage() {
  const ref = useRef()

  useEffect(() => {
    const instance = lottie.loadAnimation({
      container: ref.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      name: 'loading',
      // eslint-disable-next-line global-require
      animationData: require('statics/lottie/error404.json'),
    });

    return () => instance.destroy();
  }, [ref]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} justifyContent='center' alignItems='center'>
        <Grid item xs={12} md={5}>
          <div ref={ref} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Box display="flex" flexDirection="column" marginTop={5}>
            <p className={styles.title}>Ops! Erro inesperado</p>
            <p className={styles.subtitle}>Recarregue a página ou retorne para a página inicial.</p>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ErrorPage;