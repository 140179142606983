import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import PageTitle from 'components/pageTitle';
import SearchBar from 'components/searchBar';
import Sort from 'components/sort';
import Table from 'components/table';

import { ReactComponent as AddTag } from 'statics/icons/addTag.svg';
import { ReactComponent as CloseToast } from 'statics/icons/close.svg';

import apiServer from 'api/server';

import ModalDialogbox from 'components/modals/dialogbox';
import styles from './styles.module.sass';

function Tags() {
  const [rows, setRows] = useState([]);
  const [sortDirection, setSortDirection] = useState('asc');
  const [restoreRows, setOriginalRows] = useState([]);
  const [openModalSucess, setOpenModalSucess] = useState(false);
  const [openModalNotDelete, setOpenModalNotDelete] = useState(false);
  const [openModalError, sertOpenModalError] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [idDelete, setIdDelete] = useState();

  const navigate = useNavigate();

  const toastId = useRef(null);

  const fetchData = () => {
    apiServer
      .get('tag/all')
      .then((response) => {
        const tagsWithCategory = response.data.tags.map((tag) => ({
          id: tag.id,
          categoryId: tag.category.id,
          category: tag.category.name,
          tag: tag.code,
          activation: tag.isActive,
        }));
        setRows(tagsWithCategory);
        setOriginalRows(tagsWithCategory);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSort = useCallback(
    (sortRows) => {
      setRows(sortRows);
    },
    [rows],
  );

  const handleSortDirectionChange = useCallback(() => {
    const newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
    setSortDirection(newSortDirection);
    const sortedRows = [...rows].sort((a, b) => {
      if (newSortDirection === 'asc') {
        return a.tag.localeCompare(b.tag);
      }
      return b.tag.localeCompare(a.tag);
    });
    setRows(sortedRows);
  }, [rows, sortDirection]);

  const handleSearch = useCallback(
    (event) => {
      const {
        target: { value },
      } = event;
      const regex = new RegExp(value, 'i');
      if (value === '') {
        setRows(restoreRows);
      } else {
        const filteredRows = restoreRows.filter(
          (row) => regex.test(row.tag) || regex.test(row.category),
        );
        setRows(filteredRows);
      }
    },
    [restoreRows],
  );

  const handleSwitchChange = useCallback(
    async ({ row, status }) => {
      const response = await apiServer
        .put(`tag/update/${row.id}`, {
          code: row.tag,
          categoryId: row.categoryId,
          isActive: status,
        })
        .then(() => {
          toastId.current = toast.success(
            `Tag ${status ? 'ativada' : 'desativada'} com sucesso!`,
            {
              position: 'top-right',
              autoClose: 2000,
              closeButton: <CloseToast />,
              icon: false,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: 'light',
            },
          );

          return status;
        })
        .catch(() => {
          toastId.current = toast.error(
            `Não foi possivel ${status ? 'ativar' : 'desativar'} a tag`,
            {
              position: 'top-right',
              autoClose: 2000,
              closeButton: <CloseToast />,
              icon: false,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: 'light',
            },
          );

          return 'error';
        });

      return response;
    },
    [toastId],
  );

  const handleEdit = (row) => {
    navigate(`editar/${row.id}`);
  };

  

  const handleCloseModal = () => {
    setOpenModalSucess(false)
    sertOpenModalError(false)
    setOpenModalConfirm(false)
    setOpenModalNotDelete(false)
  }

  const handleConfirmDelete = (row) => {
    setOpenModalConfirm(true)
    setIdDelete(row.id)
  }

  const handleDelete = useCallback(() => {
    apiServer
      .delete(`/tag/delete/${idDelete}`)
      .then((response) => {
        const {
          data: { status },
        } = response;

        if (status === 'updated') {
          handleCloseModal()
          setOpenModalNotDelete(true);
        } else {
          handleCloseModal()
          setOpenModalSucess(true)
        }
        
        fetchData();
      })
      .catch(() => {
        handleCloseModal()
        sertOpenModalError(true)
      });
  }, [idDelete])

  return (
    <div className={styles.container}>
      <span
        className={styles.addTag}
        onClickCapture={() => navigate('adicionar-tag')}
      >
        <AddTag />
      </span>

      <PageTitle title="Tags" />

      <SearchBar rows={rows} onSearch={handleSearch} />

      <Sort
        hasDate
        onSort={handleSort}
        rows={rows}
        onSortDirectionChange={handleSortDirectionChange}
      />

        <Table
          page="tags"
          rows={rows}
          numberOfRows={5}
          onSwitchStatus={handleSwitchChange}
          onClickEdit={handleEdit}
          onClickTrash={handleConfirmDelete}
        />
      <ToastContainer />

      <ModalDialogbox
        open={openModalConfirm}
        type="options"
        buttonsLabel={['excluir', 'voltar']}
        onClose={handleCloseModal}
        onRightButton={handleCloseModal}
        onLeftButton={handleDelete}
        text="Tem certeza que deseja excluir esse centro de distribuição?"
      />

      <ModalDialogbox
        open={openModalSucess}
        type="success"
        onClose={handleCloseModal}
        text="A Tag foi excluida com sucesso!"
      />

      <ModalDialogbox
        open={openModalNotDelete}
        type="error"
        onClose={handleCloseModal}
        text="Não foi possivel excluir essa tag, pois ele já está em uso."
      />

      <ModalDialogbox
        open={openModalError}
        type="error"
        onClose={handleCloseModal}
        text="Erro ao concluir ação. Por favor, tente novamente mais tarde ou entre em contato com o suporte."
      />
    </div>
  );
}

export default Tags;
