import DateFormat from 'utils/dateFormat';

const columnsPerPage = [
  {
    id: 0,
    page: 'messages',
    columns: [
      {
        id: 0,
        field: 'id',
        headerName: 'ID',
        size: 'small',
      },
      {
        id: 1,
        field: 'name',
        headerName: 'Título',
        size: 'medium',
      },
      {
        id: 2,
        field: 'description',
        headerName: 'Descrição',
        size: 'large',
      },
      {
        id: 3,
        field: 'tag',
        headerName: 'TAG',
        headerAlign: 'center',
        cellClassName: 'alignCenter',
        size: 'small',
        valueGetter: (params) => params.row.tag.code,
      },
      {
        id: 4,
        field: 'tagId',
        headerName: 'Categoria',
        headerAlign: 'center',
        cellClassName: 'alignCenter',
        size: 'small',
        valueGetter: (params) => params.row.tag.category.name,
      },
      {
        id: 5,
        field: 'dropCall',
        headerName: 'Hangup',
        headerAlign: 'center',
        cellClassName: 'alignCenter',
        size: 'small',
      },
      {
        id: 6,
        field: 'audioCode',
        headerName: 'Audio',
        headerAlign: 'center',
        cellClassName: 'alignCenter',
        size: 'small',
      },
      {
        id: 7,
        field: 'activation',
        headerName: 'Ativação',
        headerAlign: 'center',
        cellClassName: 'alignCenter',
        size: 'small',
      },
      {
        id: 8,
        field: 'action',
        headerName: 'Ação',
        headerAlign: 'center',
        cellClassName: 'alignCenter',
        size: 'small',
      },
    ],
  },
  {
    id: 1,
    page: 'notificacoes',
    columns: [
      {
        id: 0,
        field: 'brand',
        headerName: 'Marca',
        size: 'small',
      },
      {
        id: 1,
        field: 'message',
        headerName: 'Mensagem',
        size: 'large',
      },
      {
        id: 2,
        field: 'tag',
        headerName: 'TAG',
        headerAlign: 'center',
        cellClassName: 'alignCenter',
        size: 'small',
      },
      {
        id: 3,
        field: 'category',
        headerName: 'Categoria',
        headerAlign: 'center',
        cellClassName: 'alignCenter',
        size: 'small',
      },
      {
        id: 4,
        field: 'dateOf',
        headerName: 'Data de',
        valueFormatter: ({ value }) => DateFormat.fullDateTime(value),
        size: 'medium',
      },
      {
        id: 5,
        field: 'untilDate',
        headerName: 'Data até',
        valueFormatter: ({ value }) => DateFormat.fullDateTime(value),
        size: 'medium',
      },
      {
        id: 6,
        field: 'status',
        headerName: 'Status',
        headerAlign: 'center',
        cellClassName: 'alignCenter',
        size: 'small',
      },
      {
        id: 7,
        field: 'action',
        headerName: 'Ação',
        headerAlign: 'center',
        cellClassName: 'alignCenter',
        size: 'small',
      },
    ],
  },
  {
    id: 2,
    page: 'categoria',
    columns: [
      {
        id: 0,
        field: 'name',
        headerName: 'Nome',
        size: 'large',
      },
      {
        id: 1,
        field: 'activation',
        headerName: 'Ativação',
        size: 'medium',
      },
      {
        id: 2,
        field: 'action',
        headerName: 'Ação',
        headerAlign: 'center',
        cellClassName: 'alignCenter',
      },
      {
        id: 3,
        field: 'null',
        headerName: '',
        size: 'large',
      },
    ],
  },
  {
    id: 3,
    page: 'tags',
    columns: [
      {
        id: 0,
        field: 'tag',
        headerName: 'Cód. Tag',
        headerAlign: 'center',
        cellClassName: 'alignCenter',
        size: 'large',
      },
      {
        id: 1,
        field: 'category',
        headerName: 'Categoria',
        headerAlign: 'center',
        cellClassName: 'alignCenter',
        size: 'large',
      },
      {
        id: 2,
        field: 'activation',
        headerName: 'Ativação',
        headerAlign: 'center',
        cellClassName: 'alignCenter',
        size: 'large',
      },
      {
        id: 3,
        field: 'action',
        headerName: 'Ação',
        headerAlign: 'center',
        cellClassName: 'alignCenter',
        size: 'small',
      },
    ],
  },
  {
    id: 4,
    page: 'centro-de-distribuicao',
    columns: [
      {
        id: 0,
        field: 'name',
        headerName: 'Nome',
        size: 'large',
      },
      {
        id: 1,
        field: 'action',
        headerName: 'Ação',
        headerAlign: 'center',
        cellClassName: 'alignCenter',
        size: 'small',
      },
      {
        id: 2,
        field: 'null',
        headerName: '',
        size: 'large',
      },
    ],
  },
  {
    id: 5,
    page: 'users',
    columns: [
      {
        id: 0,
        field: 'name',
        headerName: 'Nome completo',
        size: 'large',
      },
      {
        id: 1,
        field: 'email',
        headerName: 'E-mail',
        size: 'large',
      },
      {
        id: 2,
        field: 'userType',
        headerName: 'Tipo de usuário',
        size: 'large',
      },
      {
        id: 3,
        field: 'action',
        headerName: 'Ações',
        headerAlign: 'center',
        cellClassName: 'alignCenter',
        size: 'small',
      },
    ],
  },
  {
    id: 0,
    page: 'addNotification',
    columns: [
      {
        id: 0,
        field: 'cep',
        headerName: 'CEP',
        size: 'small',
      },
      {
        id: 1,
        field: 'bairro',
        headerName: 'Bairro',
        size: 'small',
      },
      {
        id: 2,
        headerAlign: 'center',
        cellClassName: 'alignCenter',
        field: 'cidade',
        headerName: 'Cidade',
        size: 'small',
      },
      {
        id: 3,
        field: 'distribuicao',
        headerName: 'Distribuição',
        size: 'medium',
      },
    ],
  },
  {
    id: 0,
    page: 'distributionCenterCities',
    columns: [
      {
        id: 0,
        field: 'cities',
        headerName: 'Cidade',
        size: 'small',
      },
      {
        id: 1,
        field: 'action',
        headerName: 'Ações',
        headerAlign: 'center',
        cellClassName: 'alignCenter',
        size: 'small',
      },
    ],
  },
  {
    id: 0,
    page: 'distributionCenterNeighborhood',
    columns: [
      {
        id: 0,
        field: 'neighborhood',
        headerName: 'Bairro',
        size: 'small',
      },
      {
        id: 0,
        field: 'city',
        headerName: 'Cidade',
        size: 'small',
      },
      {
        id: 1,
        field: 'action',
        headerName: 'Ações',
        headerAlign: 'center',
        cellClassName: 'alignCenter',
        size: 'small',
      },
    ],
  },
  {
    id: 0,
    page: 'distributionCenterZipcode',
    columns: [
      {
        id: 0,
        field: 'zipcode',
        headerName: 'Cep',
        size: 'small',
      },
      {
        id: 1,
        field: 'neighborhood',
        headerName: 'Bairro',
        size: 'small',
      },
      {
        id: 2,
        field: 'cities',
        headerName: 'Cidade',
        size: 'small',
      },
      {
        id: 3,
        field: 'action',
        headerName: 'Ações',
        headerAlign: 'center',
        cellClassName: 'alignCenter',
        size: 'small',
      },
    ],
  },
  {
    id: 0,
    page: 'distributionCenterDistribution',
    columns: [
      {
        id: 0,
        field: 'distribution',
        headerName: 'Distribuição',
        size: 'medium',
      },
      {
        id: 3,
        field: 'action',
        headerName: 'Ações',
        headerAlign: 'center',
        cellClassName: 'alignCenter',
        size: 'small',
      },
    ],
  },
  {
    id: 0,
    page: 'distributionCustom',
    columns: [
      {
        id: 0,
        field: 'contract',
        headerName: 'Contratos',
        size: 'medium',
      }
    ],
  },
];

export default columnsPerPage;
