import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';

import { Link, useLocation } from 'react-router-dom';

import { ReactComponent as IconeSeta } from 'statics/icons/seta.svg';
import Header from 'components/header';
import menusData from 'mockdata/menu';

import { Box } from '@mui/material';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import apiServer from 'api/server';
import HandleErrors from 'utils/handleErrors';
import styles from './styles.module.sass';

const drawerWidth = '18vw';

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: 0,
});

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',

  '& .MuiPaper-root': {
    border: 'none',
    boxShadow: '0px 4px 30px 5px rgba(0, 0, 0, 0.15)',
  },
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  const location = useLocation();
  const [open, setOpen] = useState(true);
  const [userName, setUserName] = useState('')
  const [userAccess, setUserAccess] = useState('')

  const handleDrawerOpen = useCallback(() => {
    setOpen(!open);
  }, [open]);

  useEffect(() => {
    const token = Cookies.get('token')
    const decodeJwt = jwtDecode(token)

    apiServer.get(`/users/search/${decodeJwt.sub}`)
      .then((response) => {
        const userSplit = response.data.user.name.split(' ')
        const firstName = userSplit[0]

        setUserAccess(response.data.user.role)        
        setUserName(firstName)
      })
      .catch(error => {
        HandleErrors(error.response.status)
      }) 
  }, [])

  const menuAccess = useMemo(() => {
    const list = []

    const adminCanAccess = [
      'Painel de Notificações',
      'Notificações',
      'Categoria',
      'Tags',
      'Mensagens',
      'Centro de distribuição',
      'Usuários'
    ]

    const coordinatorCanAccess = [
      'Painel de Notificações',
      'Notificações',
      'Categoria',
      'Tags',
      'Mensagens',
      'Centro de distribuição',
      'Usuários'
    ]

    const commonCanAccess = [
      'Painel de Notificações',
      'Notificações',
    ]

    switch (userAccess) {
      case 'admin':
        menusData.forEach((itemMenu) => {
          if(adminCanAccess.find(menu => menu === itemMenu.label)) {
            list.push(itemMenu)
          }
        })
        break

      case 'coordinator':
        menusData.forEach((itemMenu) => {
          if(coordinatorCanAccess.find(menu => menu === itemMenu.label)) {
            list.push(itemMenu)
          }
        })
        break
        
      case 'common':
        menusData.forEach((itemMenu) => {
          if(commonCanAccess.find(menu => menu === itemMenu.label)) {
            list.push(itemMenu)
          }
        })
        break
        
      default:
        break
    }

    return list 
  }, [userAccess, menusData])

  return (
    <div style={{ overflow: 'hidden' }}>
      <AppBar position="fixed" open={open}>
        <Header onClickMenu={handleDrawerOpen} />
      </AppBar>
      <Box
        sx={{
          width: open ? drawerWidth : 0,
          transition: '255ms all ease',
        }}
      />

      <Drawer variant="permanent" open={open} style={{ overflow: 'hidden' }}>
        <div className={styles.headerMenu} style={{ marginTop: 87 }}>
          <IconeSeta
            onClick={handleDrawerOpen}
            className={styles.arrowIcon}
          />

          <p className={styles.userName}>
            {`Olá, ${userName}`}
          </p>
        </div>

        <div className={styles.menusGroup} style={{ overflow: 'hidden' }}>
          {menuAccess.map((item) => (
            <Link
              to={item.redirect}
              key={item.id}
              className={`
                ${styles.menusLabel}
                ${
                  !location.pathname.search(item.redirect) && styles.activeLabel
                }
              `}
            >
              <div
                className={
                  !location.pathname.search(item.redirect)
                    ? styles.menuIconActive
                    : styles.menuIcon
                }
              >
                {item.icon}
              </div>
              {item.label}
            </Link>
          ))}
        </div>
      </Drawer>
    </div>
  );
}
