// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_brandContent__0w9vm {\n  margin-left: 48px;\n}\n\n.styles_label__81QSU {\n  font-size: 20px;\n  font-weight: 700;\n  color: #2D3073;\n  margin-bottom: 24px;\n  -webkit-user-select: none;\n          user-select: none;\n  margin-top: 40px;\n}", "",{"version":3,"sources":["webpack://./src/components/modals/Filter/components/brandsCheckBox/styles.module.sass"],"names":[],"mappings":"AAEA;EACE,iBAAA;AADF;;AAGA;EACE,eAAA;EACA,gBAAA;EACA,cAAA;EACA,mBAAA;EACA,yBAAA;UAAA,iBAAA;EACA,gBAAA;AAAF","sourcesContent":["@import 'styles/_colors.sass'\n\n.brandContent\n  margin-left: 48px\n\n.label\n  font-size: 20px\n  font-weight: 700\n  color: $blue-600\n  margin-bottom: 24px\n  user-select: none\n  margin-top: 40px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"brandContent": "styles_brandContent__0w9vm",
	"label": "styles_label__81QSU"
};
export default ___CSS_LOADER_EXPORT___;
