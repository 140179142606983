import React, { useCallback, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { ReactComponent as AddNotification } from 'statics/icons/addNotification.svg';

import SearchBar from 'components/searchBar';
import CardNotification from 'components/cardNotification';
import Loading from 'components/loading';
import PageTitle from 'components/pageTitle';

// import notificationGroupsData from 'mockdata/notificationsGroups.json';

import 'swiper/css';
import apiServer from 'api/server';
import useAuth from 'hooks/useAuth';
import styles from './styles.module.sass';

const notificationGroups = {
  groups: [
    {
      id: uuidv4(),
      title: 'NOTIFICAÇÕES ATIVAS',
      type: 'active',
    },
    {
      id: uuidv4(),
      title: 'NOTIFICAÇÕES PAUSADAS',
      type: 'paused',
    },
    {
      id: uuidv4(),
      title: 'NOTIFICAÇÕES INATIVAS',
      type: 'unused',
    },
    {
      id: uuidv4(),
      title: 'NOTIFICAÇÕES ENCERRADAS',
      type: 'blocked',
    },
  ],
};

function NotificationPanel() {
  const { userBrands } = useAuth();

  const [activeNotifications, setActiveNotifications] = useState([]);
  const [unusedNotifications, setInactiveNotifications] = useState([]);
  const [pausedNotifications, setPausedNotifications] = useState([]);
  const [closedNotifications, setClosedNotifications] = useState([]);

  const [isListLoading, setListLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setListLoading(true);

    apiServer
      .get('activations/all')
      .then((response) => {
        if (!response.data.activations) {
          setActiveNotifications([]);
          setInactiveNotifications([]);
          setPausedNotifications([]);
          setClosedNotifications([]);

          setListLoading(false);

          return;
        }

        setActiveNotifications(
          response.data.activations
            .filter(
              (activation) =>
                activation.status !== notificationGroups.groups[1].type &&
                activation.status !== notificationGroups.groups[3].type &&
                new Date(activation.dateStart) <= new Date() &&
                new Date(activation.dateEnd) >= new Date(),
            )
            .map((activation) => ({ ...activation }))
            .sort((a, b) => {
              const totalMillis =
                new Date(a.dateStart).getTime() -
                new Date(b.dateStart).getTime();

              if (totalMillis !== 0) return totalMillis;

              return a.id - b.id;
            }),
        );

        setPausedNotifications(
          response.data.activations
            .filter(
              (activation) =>
                activation.status === notificationGroups.groups[1].type &&
                new Date(activation.dateStart) <= new Date() &&
                new Date(activation.dateEnd) >= new Date(),
            )
            .map((activation) => ({ ...activation }))
            .sort((a, b) => {
              const totalMillis =
                new Date(a.dateStart).getTime() -
                new Date(b.dateStart).getTime();

              if (totalMillis !== 0) return totalMillis;

              return a.id - b.id;
            }),
        );

        setInactiveNotifications(
          response.data.activations
            .filter((activation) => new Date(activation.dateEnd) < new Date())
            .map((activation) => ({ ...activation }))
            .sort((a, b) => {
              const totalMillis =
                new Date(a.dateStart).getTime() -
                new Date(b.dateStart).getTime();

              if (totalMillis !== 0) return totalMillis;

              return a.id - b.id;
            }),
        );

        setClosedNotifications(
          response.data.activations
            .filter(
              (activation) =>
                activation.status === notificationGroups.groups[3].type,
            )
            .map((activation) => ({ ...activation }))
            .sort((a, b) => {
              const totalMillis =
                new Date(a.dateStart).getTime() -
                new Date(b.dateStart).getTime();

              if (totalMillis !== 0) return totalMillis;

              return a.id - b.id;
            }),
        );

        setListLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setListLoading(false);
      });
  }, [userBrands]);

  useEffect(() => {
    const interval = setInterval(() => {
      const refeshedActives = [...activeNotifications];
      const refreshedPauseds = [...pausedNotifications];

      const overduedActives = refeshedActives.filter(
        (notification) => new Date(notification.dateEnd) < new Date(),
      );
      const overduedPauseds = refreshedPauseds.filter(
        (notification) => new Date(notification.dateEnd) < new Date(),
      );

      setActiveNotifications(
        refeshedActives.filter(
          (notification) => !(new Date(notification.dateEnd) < new Date()),
        ),
      );

      setPausedNotifications(
        refreshedPauseds.filter(
          (notification) => !(new Date(notification.dateEnd) < new Date()),
        ),
      );

      setInactiveNotifications((oldValues) => [
        ...oldValues,
        ...overduedActives,
        ...overduedPauseds,
      ]);
    }, 1000 * 60);

    return () => {
      clearInterval(interval);
    };
  }, [activeNotifications, unusedNotifications, pausedNotifications]);

  const changeCardType = useCallback(
    ({ oldType, newType, id }) => {
      let notification = {};

      switch (oldType) {
        case 'active':
          notification = [...activeNotifications].find(
            (item) => item.id === id,
          );

          setActiveNotifications((old) => old.filter((item) => item.id !== id));
          break;
        case 'paused':
          notification = [...pausedNotifications].find(
            (item) => item.id === id,
          );

          setPausedNotifications((old) => old.filter((item) => item.id !== id));
          break;
        default:
          break;
      }

      switch (newType) {
        case 'active':
          setActiveNotifications((old) =>
            [...old, notification].sort((a, b) => {
              const totalMillis =
                new Date(a.dateStart).getTime() -
                new Date(b.dateStart).getTime();

              if (totalMillis !== 0) return totalMillis;

              return a.id - b.id;
            }),
          );
          break;
        case 'paused':
          setPausedNotifications((old) =>
            [...old, notification].sort((a, b) => {
              const totalMillis =
                new Date(a.dateStart).getTime() -
                new Date(b.dateStart).getTime();

              if (totalMillis !== 0) return totalMillis;

              return a.id - b.id;
            }),
          );
          break;
        case 'blocked':
          setClosedNotifications((old) =>
            [...old, notification].sort((a, b) => {
              const totalMillis =
                new Date(a.dateStart).getTime() -
                new Date(b.dateStart).getTime();

              if (totalMillis !== 0) return totalMillis;

              return a.id - b.id;
            }),
          );
          break;
        default:
          break;
      }
    },
    [
      activeNotifications,
      unusedNotifications,
      pausedNotifications,
      closedNotifications,
    ],
  );

  return (
    <div>
      <AddNotification
        className={styles.addNotification}
        onClickCapture={() => navigate('ativar-notificacao')}
      />

      <PageTitle title="Painel de Notificações" />

      {notificationGroups.groups.map((group) => (
        <div className={styles.notificationGroups} key={group.id}>
          <p className={styles.groupTitle}>{group.title}</p>

          <Swiper
            spaceBetween={40}
            style={{ paddingRight: '3rem' }}
            slidesPerView="auto"
          >
            {!isListLoading &&
              group.type === notificationGroups.groups[0].type &&
              activeNotifications.map((card) => (
                <SwiperSlide key={card.id} style={{ width: 'fit-content' }}>
                  <CardNotification
                    data={card}
                    type={group.type}
                    changeCardType={changeCardType}
                  />
                </SwiperSlide>
              ))}
            {!isListLoading &&
              group.type === notificationGroups.groups[1].type &&
              pausedNotifications.map((card) => (
                <SwiperSlide key={card.id} style={{ width: 'fit-content' }}>
                  <CardNotification
                    data={card}
                    type={group.type}
                    changeCardType={changeCardType}
                  />
                </SwiperSlide>
              ))}
            {!isListLoading &&
              group.type === notificationGroups.groups[2].type &&
              unusedNotifications.map((card) => (
                <SwiperSlide key={card.id} style={{ width: 'fit-content' }}>
                  <CardNotification
                    data={card}
                    type={group.type}
                    changeCardType={changeCardType}
                  />
                </SwiperSlide>
              ))}
            {!isListLoading &&
              group.type === notificationGroups.groups[3].type &&
              closedNotifications.map((card) => (
                <SwiperSlide key={card.id} style={{ width: 'fit-content' }}>
                  <CardNotification
                    data={card}
                    type={group.type}
                    changeCardType={changeCardType}
                  />
                </SwiperSlide>
              ))}

            {isListLoading && (
              <SwiperSlide>
                <div className={styles.loading}>
                  <Loading />
                  <p>Estamos buscando mais notificações</p>
                </div>
              </SwiperSlide>
            )}
            {/* {group.notifications.length === group.totalSize &&
              group.totalPages > 1 && (
                <SwiperSlide>
                  {({ isNext }) => handleLoading(isNext)}
                </SwiperSlide>
              )} */}
          </Swiper>
        </div>
      ))}
    </div>
  );
}

export default NotificationPanel;
