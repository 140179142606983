function fullDateTime(date) {
  const newDate = new Date(date);

  const day = newDate.getUTCDate();
  const month = `${newDate.getUTCMonth() + 1}`.padStart(2, '0');
  const year = newDate.getFullYear();
  const hours = `${newDate.getHours()}`.padStart(2, '0');
  const minutes = `${newDate.getMinutes()}`.padStart(2, '0');

  const dateFormat = `${day}/${month}/${year} ${hours}:${minutes}`;

  return dateFormat;
}

const dateFormat = {
  fullDateTime,
};

export default dateFormat;
