import React from 'react';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Inputs from './components/input';
import Selects from './components/select';

function CreationForm({ control, defaultValues }) {
  return (
    <Box sx={{ width: '100%' }}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
          <Inputs control={control} defaultValues={defaultValues} />
        </Grid>
        <Grid item xs={6}>
          <Selects control={control} defaultValues={defaultValues} />
        </Grid>
      </Grid>
    </Box>
  );
}

export default CreationForm;
