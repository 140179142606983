import React, { useCallback, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import axios from 'axios';

import { Checkbox, FormControlLabel } from '@mui/material';

import apiServer from 'api/server';

import { ReactComponent as CircleAdd } from 'statics/icons/circleAdd.svg';

import Table from 'components/table';
import InputComponent from 'components/input';

import HandleErrors from 'utils/handleErrors';

import AutocompleteComponent from 'components/autocomplete';
import styles from './styles.module.sass';

const CEP_LENGTH = 8;

function Cep({ control, onSelectZipcode, rows, disabledForm }) {
  const [isValidCep, setIsValidCep] = useState(true);
  const [selectedZipcode, setSelecteZipcode] = useState([]);
  const [duplicate, setDuplicate] = useState(false);
  const [zipcodeData, setZipcodeData] = useState();

  useEffect(() => {
    setSelecteZipcode(rows)
  }, [rows, selectedZipcode])

  const viaCep = async (cep) => {
    const clearCep = cep.replace(/\D/g, '');
    try {
      const response = await axios
        .get(`https://viacep.com.br/ws/${clearCep}/json/`)
        .then((r) => r);

      setZipcodeData(response.data);
      return response.data;
    } catch (error) {
      setZipcodeData(undefined);
      setIsValidCep(false);

      return undefined;
    }
  };

  const handleAdd = useCallback(
    async (cep) => {
      try {
        const response = await viaCep(cep);
        if (response.erro) {
          setIsValidCep(false);
        } else if (response) {
          setIsValidCep(true);

          const list = [...selectedZipcode];

          const maskCep = cep.replace(/\D/g, '');

          if (!list.find((e) => e?.zipcode === maskCep)) {
            list.push({
              id: list.length + 1,
              zipcode: maskCep,
              neighborhood: response.bairro,
              cities: response.localidade,
            });
          } else {
            setDuplicate(true);
          }

          setSelecteZipcode(list);

          if (onSelectZipcode) {
            onSelectZipcode(list);
          }
        }
      } catch (error) {
        setIsValidCep(false);
      }
    },
    [selectedZipcode],
  );

  const clearMessages = () => {
    setIsValidCep(true);
    setDuplicate(false);
  };

  const handleChange = (event, onChange) => {
    if (event.target.value.length >= CEP_LENGTH) {
      viaCep(event.target.value);
    }
    onChange(event);
    clearMessages();
  };

  const handleRemove = useCallback(
    (rowToRemove) => {
      const list = [...selectedZipcode];

      const findIndex = selectedZipcode.findIndex(
        (item) => item.id === rowToRemove.id,
      );

      list.splice(findIndex, 1);

      setSelecteZipcode(list);

      if (onSelectZipcode) {
        onSelectZipcode(list);
      }
    },
    [selectedZipcode],
  );

  return (
    <div>
      <Controller
        name="staticZipcode"
        control={control}
        render={({ field: { onChange, value }, formState: { isDirty } }) => {
          if (isDirty && !selectedZipcode) {
            handleAdd(value);
          }
          return (
            <div className={styles.cep}>
              <div className={styles.inputCep}>
                <InputComponent
                  label="CEP"
                  disabled={disabledForm}
                  placeholder="Insira o CEP"
                  defaultValue={value || ''}
                  onChange={(event) => handleChange(event, onChange)}
                  multiple
                />
                {!isValidCep && (
                  <p className={styles.cepIsInvalid}>Digite um CEP válido</p>
                )}
                {duplicate && (
                  <p className={styles.cepIsInvalid}>CEP já adicionado</p>
                )}
              </div>
              <CircleAdd
                onClickCapture={() => value && handleAdd(value)}
                className={value ? styles.buttonAdd : styles.buttonAddDisabled}
              />

              {zipcodeData?.localidade && value.length >= CEP_LENGTH && (
                <p className={styles.address}>
                  {`[${zipcodeData.localidade}] ${zipcodeData.logradouro} - ${zipcodeData.bairro}`}
                </p>
              )}
            </div>
          );
        }}
      />

      <Table
        page="distributionCenterZipcode"
        rows={selectedZipcode}
        numberOfRows={2}
        removeEdit
        disabledActions={disabledForm}
        onClickTrash={handleRemove}
      />
    </div>
  );
}

function Neighborhood({ control, onSelectNeighborhood, rows, resetField, disabledForm, selectedBrands }) {
  const [cities, setCities] = useState([]);
  const [neighborhood, setNeighborhood] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');

  const fetchDataCities = useCallback(async () => {
    const list = [];

    selectedBrands.forEach(async (brand) => {
      await apiServer.get(`/cities/all/${brand}`).then((response) => {
        const { data } = response;

        const citieContent = data.cities.map((city) => ({
          id: city.id,
          label: city.name,
        }));

        list.push(...citieContent);
      });
    });

    setCities(list);
  }, [selectedBrands]);

  const fetchDataNeighborhoods = useCallback(
    async (idCity) => {
      const convertCityToUrl = encodeURI(idCity);
      const list = [];

      await selectedBrands.forEach(async (brand) => {
        await apiServer
          .get(`/neighborhoods/all/${brand}/${convertCityToUrl}`)
          .then((response) => {
            const { data } = response;
            const neighborhoodsContent = data.neighborhoods.map(
              (neighborhoodContent) => ({
                id: neighborhoodContent.id,
                label: neighborhoodContent.name,
              }),
            );

            list.push(...neighborhoodsContent);
          })
          .catch((err) => {
            HandleErrors(err.response.status)
          });
      });

      setNeighborhood(list);
    },
    [selectedBrands],
  );

  useEffect(() => {
    fetchDataCities();
  }, [selectedBrands]);

  const handleSelectCity = (value) => {
    setSelectedCity(value?.id);

    if (value) {
      fetchDataNeighborhoods(value?.label);
    }

    resetField('staticNeighborhoods');
  };

  const handleAdd = useCallback(
    (value) => {
      const list = [...rows];

      if (!list.find((item) => item.neighborhood === value.label)) {
        const findIdNeighborhood = neighborhood.find((e) => e.id === value.id);
        const findIdCity = cities.find((e) => e.id === selectedCity);

        list.push({
          id: findIdNeighborhood.id,
          neighborhood: findIdNeighborhood.label,
          cityId: findIdCity.id,
          city: findIdCity.label,
        });
      }

      if (onSelectNeighborhood) {
        onSelectNeighborhood(list);
      }

      resetField('staticNeighborhoods');
    },
    [rows, selectedCity, neighborhood],
  );

  const handleRemove = useCallback(
    (rowToRemove) => {
      const list = [...rows];
      const findIndex = rows.findIndex(
        (item) => item.id === rowToRemove.id,
      );

      list.splice(findIndex, 1);

      if (onSelectNeighborhood) {
        onSelectNeighborhood(list);
      }
    },
    [rows],
  );

  return (
    <div className={styles.neighborhood}>
      <div className={styles.searchNeighborhood}>
        <AutocompleteComponent
          label="Cidades"
          disabled={disabledForm}
          placeholder="Pesquise ou selecione a cidade"
          onChange={handleSelectCity}
          options={cities}
        />

        <Controller
          name="staticNeighborhoods"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div className={styles.selectNeighborhoodContainer}>
              <AutocompleteComponent
                label="Bairro"
                disabled={disabledForm}
                placeholder="Pesquise ou selecione o bairro"
                onChange={onChange}
                value={value}
                className={styles.selectNeighborhood}
                options={neighborhood}
              />
              <CircleAdd
                onClickCapture={() => value && handleAdd(value)}
                className={value ? styles.buttonAdd : styles.buttonAddDisabled}
              />
            </div>
          )}
        />
      </div>
      <Controller
        name="neighborhoodsExcept"
        control={control}
        render={({ field: { onChange, value } }) => (
          <FormControlLabel
            control={
              <Checkbox
                style={styles.checkbox}
                defaultChecked={false}
                checked={!!value}
                onChange={onChange}
              />
            }
            label="Exceto"
            className={styles.checkboxLabel}
          />
        )}
      />

      <Table
        page="distributionCenterNeighborhood"
        rows={rows}
        numberOfRows={2}
        disabledActions={disabledForm}
        removeEdit
        onClickTrash={handleRemove}
      />
    </div>
  );
}

function City({ control, onSelectCities, rows, disabledForm, selectedBrands, resetField }) {

  const [city, setCity] = useState([]);

  const fetchDataCites = useCallback(() => {
    const list = [];

    selectedBrands.forEach((brand) => {
      apiServer.get(`cities/all/${brand}`).then((response) => {
        const { data } = response;
        const citiesContent = data.cities.map((cityContent) => ({
          id: cityContent.id,
          label: cityContent.name,
        }));

        list.push(...citiesContent);
      });
    });

    setCity(list);
  }, [selectedBrands]);

  useEffect(() => {
    fetchDataCites();
  }, [selectedBrands]);

  const hadleAddCities = useCallback(
    (value) => {
      const list = [...rows];

      if (!list.find((item) => item.cities === value.label)) {
        const findIdCity = city.find((e) => e.id === value.id);

        list.push({ id: findIdCity.id, cities: findIdCity.label });
      }

      if (onSelectCities) {
        onSelectCities(list);
      }

      resetField('staticCity')
    },
    [rows, city],
  );

  const handleRemove = useCallback(
    (rowToRemove) => {
      const list = [...rows];

      const findIndex = rows.findIndex(
        (item) => item.id === rowToRemove.id,
      );

      list.splice(findIndex, 1);

      if (onSelectCities) {
        onSelectCities(list);
      }
    },
    [rows],
  );

  return (
    <div>
      <Controller
        name="staticCity"
        control={control}
        render={({ field: { onChange, value } }) => (
          <div>
            <div className={styles.city}>
              <AutocompleteComponent
                label="Cidade"
                key={city.id}
                disabled={disabledForm}
                value={value}
                placeholder="Pesquise ou selecione a cidade"
                onChange={onChange}
                className={styles.select}
                options={city}
              />
              <CircleAdd
                onClickCapture={() => value && hadleAddCities(value)}
                className={value ? styles.buttonAdd : styles.buttonAddDisabled}
              />
            </div>
          </div>
        )}
      />

      <Table
        page="distributionCenterCities"
        rows={rows}
        numberOfRows={2}
        removeEdit
        disabledActions={disabledForm}
        onClickTrash={handleRemove}
      />
    </div>
  );
}

const FilterDistributionCenter = {
  Cep,
  City,
  Neighborhood,
};

export default FilterDistributionCenter;
