import React, { useCallback, useState } from 'react';

import ModalDialogbox from 'components/modals/dialogbox';

import { ReactComponent as Logo } from 'statics/icons/logo.svg';
import { ReactComponent as Exit } from 'statics/icons/exit-icon.svg';
import { ReactComponent as Menu } from 'statics/icons/menu.svg';

import useAuth from 'hooks/useAuth';

import styles from './styles.module.sass';

function Header({ onClickMenu }) {
  const [openModal, setOpenModal] = useState(false);

  const { signout } = useAuth();

  const handleOpenModal = useCallback(() => {
    setOpenModal(!openModal);
  }, [openModal]);

  const handleLogout = () => {
    signout();
  };

  return (
    <div className={styles.container}>
      <div className={styles.menuContainer} onClickCapture={onClickMenu}>
        <Menu className={styles.meunIcon} alt="menu" />
        <Logo className={styles.logo} alt="logo" title="Logo Me Notifique" />
      </div>

      <Exit
        className={styles.exit}
        onClickCapture={handleOpenModal}
        alt="sair"
      />

      <ModalDialogbox
        open={openModal}
        type="options"
        onClose={handleOpenModal}
        text="Tem certeza que deseja sair?"
        buttonsLabel={['sair', 'VOLTAR']}
        onLeftButton={handleLogout}
        onRightButton={handleOpenModal}
      />
    </div>
  );
}

export default Header;
