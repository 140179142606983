import React from 'react';
import { Controller } from 'react-hook-form';

import SelectComponent from 'components/select';

import styles from './styles.module.sass';

const selects = [
  {
    id: 0,
    title: 'Por mensagem:',
    label: 'Mensagem',
    name: 'message',
    hasLabels: false,
    placeholder: 'Selecione a mensagem cadastrada',
    list: [
      {
        id: 0,
        content: '34 - Rompimento São Miguel',
      },
      {
        id: 1,
        content: '35 - Reajuste de valor',
      },
    ],
  },
  {
    id: 1,
    title: 'Por categoria:',
    label: 'Categoria',
    name: 'category',
    hasLabels: false,
    placeholder: 'Selecione a categoria cadastrada',
    list: [
      {
        id: 0,
        content: 'Categoria 1',
      },
      {
        id: 0,
        content: 'Categoria 2',
      },
      {
        id: 0,
        content: 'Categoria 3',
      },
    ],
  },
  {
    id: 2,
    title: 'Por TAG:',
    label: 'URA (TAG)',
    name: 'tag',
    hasLabels: false,
    placeholder: 'Digite o código da tag cadastrada',
    list: [
      {
        id: 0,
        content: '0011',
      },
      {
        id: 1,
        content: '0012',
      },
    ],
  },
];

function filterSelects({ control }) {
  return (
    <div className={styles.selection}>
      {selects.map((item, index) => (
        <Controller
          key={item.id}
          name={item.name}
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange } }) => (
            <>
              <h4 className={styles.label}>{item.title}</h4>
              <SelectComponent
                label={item.label}
                multiple
                onChange={onChange}
                hasLabels={item.hasLabels}
                placeholder={item.placeholder}
                className={styles.selects}
                listSelects={item.list}
                index={index}
              />
            </>
          )}
        />
      ))}
    </div>
  );
}

export default filterSelects;
