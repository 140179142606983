// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__tgDVQ {\n  align-items: center;\n  color: #002A67;\n  display: flex;\n  font-size: 20px;\n  font-weight: 600;\n  margin-bottom: 20px;\n  -webkit-user-select: none;\n          user-select: none;\n}\n\n.styles_container__tgDVQ:hover {\n  cursor: pointer;\n}\n\n.styles_sortArrowIcon__20ufT {\n  fill: #002A67;\n}", "",{"version":3,"sources":["webpack://./src/components/sort/styles.module.sass","webpack://./src/styles/_colors.sass"],"names":[],"mappings":"AAEA;EACE,mBAAA;EACA,cCMS;EDLT,aAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,yBAAA;UAAA,iBAAA;AADF;;AAGA;EACE,eAAA;AAAF;;AAEA;EACE,aCLS;ADMX","sourcesContent":["@import 'styles/_colors.sass'\n\n.container\n  align-items: center\n  color: $blue-800\n  display: flex\n  font-size: 20px\n  font-weight: 600\n  margin-bottom: 20px\n  user-select: none\n\n.container:hover\n  cursor: pointer\n\n.sortArrowIcon\n  fill: $blue-800\n","$background-menu: #F8F8FB\n\n$ligth-blue-600: #00559A\n\n$edit-icon: #6661AB\n\n$blue-200: #7973BF\n$blue-500: #404085\n$blue-600: #2D3073\n$blue-700: #182160\n$blue-800: #002A67\n$blue-900: #00174F\n\n$border-bottom-title: #BBBBBB\n\n$dark-50: #717171\n$dark-100: #5A5A5A\n$dark-200: #444444\n$dark-300: #2D2D2D\n\n$green-400: #00D04C\n$green-600: #00AC29\n$green-700: #009A14\n\n$light-300: #B4B4B4\n$light-400: #9E9E9E\n$light-700: #686868\n\n$red-500: #FF3333\n$red-600: #d32f2f\n$red-700: #A72626\n\n$statusLabelActive: #00BE3B\n$statusLabelPause: #535098\n$statusLabelClose: #EF4D43\n$statusLabelUnused: #9E9E9E\n\n$tag-text: #003F80\n\n$white: #fff\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__tgDVQ",
	"sortArrowIcon": "styles_sortArrowIcon__20ufT"
};
export default ___CSS_LOADER_EXPORT___;
