import React, { useCallback, useEffect, useState } from 'react';

import { TextField } from '@mui/material';

function TextBoxComponent({
  label,
  placeholder,
  className,
  onChange,
  defaultValue,
  disabled,
  error,
}) {
  const [labelState, setLabelState] = useState(placeholder);
  const [value, setValue] = useState(defaultValue);

  const handleChange = (element) => {
    setValue(element.target.value);

    if (onChange) {
      onChange(element);
    }
  };

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (value) {
      setLabelState(label);
    }
  }, [value, label, labelState]);

  const handleClose = useCallback(() => {
    setLabelState(placeholder);
  }, [placeholder]);

  const handleOpen = useCallback(() => {
    setLabelState(label);
  }, [label]);

  return (
    <TextField
      label={labelState}
      className={className}
      disabled={disabled}
      value={value}
      autoComplete="on"
      sx={{
        '& .MuiInputBase-input': {
          textOverflow: 'ellipsis',
        },
      }}
      onChange={handleChange}
      onFocus={handleOpen}
      onBlur={handleClose}
      multiline
      maxRows={7}
      error={!!error}
      helperText={error || ''}
    />
  );
}

export default TextBoxComponent;
