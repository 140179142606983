import React, { useCallback, useMemo } from 'react';
import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import GreenSwitch from 'components/GreenSwitch';
import TableActions from 'components/tableActions';

import columnsPerPage from 'mockdata/columnsPerPage';

function Table({
  page,
  rows,
  numberOfRows,
  onClickEdit,
  onClickTrash,
  onClickEye,
  onSwitchStatus,
  addEye,
  removeTrash,
  removeEdit,
  disabledActions,
  onDisableRule
}) {
  const actionFieldSlot = useCallback(
    ({ row }) => (
      <TableActions
        onClickEdit={onClickEdit}
        onClickTrash={onClickTrash}
        addEye={addEye}
        onClickEye={onClickEye}
        removeTrash={removeTrash}
        removeEdit={removeEdit}
        disabledActions={disabledActions}
        onDisableRule={onDisableRule}
        row={row}
      />
    ),
    [onClickEdit, onClickTrash, removeTrash, removeEdit, disabledActions, onDisableRule, onClickEye, addEye],
  );
  
  const activationFieldSlot = useCallback(({row}) => (
    <GreenSwitch
      defaultChecked={row.activation}
      onSwitchStatus={onSwitchStatus}
      row={row}
    />
  ), [onSwitchStatus])

  const columns = useMemo(() => {
    const data = columnsPerPage.find((item) => item.page === page);
    const columnsList = [];


    data.columns.forEach((column) => {
      columnsList.push({
        ...column,
        editable: false,
        sortable: false,
        renderCell:
          (column.field === 'activation' && activationFieldSlot)
          || (column.field === 'action' && actionFieldSlot),
        flex:
          (column.size === 'small' && 1)
          || (column.size === 'medium' && 2)
          || (column.size === 'large' && 3),
      });
    });

    return columnsList;
  }, [columnsPerPage, page, actionFieldSlot, activationFieldSlot]);

  const noRows = () => (
    <p
      style={{
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      Nenhum resultado encontrado...
    </p>
  )

  return (
    <Box
      sx={{
        width: '96%',

        '& .MuiDataGrid-root': {
          borderWidth: '0 0px 0.5px 0',
          borderColor: 'var(--border-table-color)',
          color: 'var(--text-table-color)',
          filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.1))',
        },
      }}
    >
      <DataGrid
        rows={rows || []}
        style={rows.length === 0 ? { height: 160 }: null}
        slots={{
          noRowsOverlay: noRows
        }}
        sx={{
          '& .MuiDataGrid-withBorderColor': {
            borderColor: 'var(--border-table-color)',
          },

          '& .MuiDataGrid-columnHeadersInner, .MuiDataGrid-columnHeaderRow ': {
            flex: 1,
          },

          '& .MuiDataGrid-columnHeaderRow ': {
            wdith: '100%',
          },

          '& .alignCenter': {
            justifyContent: 'center',
          },

          '& .MuiDataGrid-row:nth-last-of-type(1) .MuiDataGrid-cell': {
            border: 'none',
          },

          '& .MuiButtonBase-root': {
            color: 'var(--arrows-table-color)',
          },

          '& .Mui-disabled': {
            color: 'var(--arrows-table-color-disabled)',
          },

          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold',
          },
        }}
        columns={columns}
        localeText={{
          MuiTablePagination: {
            labelDisplayedRows: ({ to, count }) => `Páginas: ${Math.ceil(to / numberOfRows)} de ${Math.ceil(
              count / numberOfRows,
            )}`,
          },
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: numberOfRows,
            },
          },
        }}
        disableColumnMenu
        pageSizeOptions={[numberOfRows]}
        rowHeight={57}
        disableRowSelectionOnClick
      />
    </Box>
  );
}

export default Table;
