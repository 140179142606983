import React, { useCallback, useEffect, useState } from 'react';

import { ReactComponent as Edit } from 'statics/icons/edit.svg';
import { ReactComponent as Trash } from 'statics/icons/trash.svg';
import { ReactComponent as Eye } from 'statics/icons/visiblePassword.svg';

import styles from './styles.module.sass';

function TableActions({
  removeEdit,
  removeTrash,
  row,
  onClickEdit,
  addEye,
  onClickTrash,
  onClickEye,
  disabledActions,
  onDisableRule,
}) {
  const [disabled, setDisabled] = useState(disabledActions || false)

  useEffect(() => {
    if(onDisableRule) {
      setDisabled(!onDisableRule(row))
    }
  }, [row])

  const handleEdit = useCallback(() => {
    if (onClickEdit) {
      onClickEdit(row);
    }
  }, [row, onClickEdit]);

  const handleTrash = useCallback(() => {
    if (onClickTrash) {
      onClickTrash(row);
    }
  }, [row, onClickTrash]);

  const handleEye = useCallback(() => {
    if (onClickEye) {
      onClickEye(row);
    }
  }, [row, onClickEye]);

  return (
    <div className={styles.container}>
      {addEye && (
        <Eye
          className={`${disabled ? styles.iconDisabled : styles.icon} ${styles.eyeIcon}`}
          onClickCapture={handleEye}
        />
      )}

      {!removeEdit && (
        <Edit
          className={`${disabled ? styles.iconDisabled : styles.icon} ${styles.iconEdit}`}
          onClickCapture={!disabled ? handleEdit : null} 
        />
      )}

      {!removeTrash && (
        <Trash
          className={`${disabled ? styles.iconDisabled : styles.icon}`}
          onClickCapture={!disabled ? handleTrash : null}
        />
      )}
    </div>
  );
}

export default TableActions;
