import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import apiServer from 'api/server';

import PageTitle from 'components/pageTitle';
import FormActivation from 'components/formActivation';
import FilterAddActivation from 'components/filterAddActivation';
import ModalDialogbox from 'components/modals/dialogbox'; 

import styles from './styles.module.sass';

function ViewNotification() {
  const [openModalSucess, setOpenModalSucess] = useState(false)
  const [openModalError, setOpenModalError] = useState(false)
  const [brandId, setBrandId] = useState([])

  const {id} = useParams()

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitted, defaultValues },
    setValue,
    resetField,
    setError,
    clearErrors,
  } = useForm({
    defaultValues: async () => apiServer.get(`/activations/search/${id}`)
      .then(({data}) => {
        const {notification} = data

        const brands = notification.brands.map(brand => ({code: brand.code, id: brand.id}))
        setBrandId(brands[0].id)
        return {
          activation: {
            brands:brands[0].code, 
            tagId: notification.tag.id,
            messageId: notification.message.id,
            description: notification.description,
            dateEnd: notification.dateEnd,
            dateStart: notification.dateStart, 
            zipcodes: notification.zipcodes,
            neighborhood: notification.neighborhoods,
            cities: notification.cities,
            distribution: notification.distributionCenters,
            contracts: notification.contracts
          }
        }
      })
  });



  const navigate = useNavigate()

  useEffect(() =>{
    setError('filters', {type: 'required', message: 'É necessário o preenchimento de pelo menos um filtro.'})
  }, [])

  const onSubmit = async (data) => {
    const { activation } = data;

    if (activation.brands) delete activation.brands;

    await apiServer
      .post('activations/new', {
        status: 'active',
        ...activation,
      })
      .then(() => {
        setOpenModalSucess(true)
      })
      .catch(() => {
        setOpenModalError(true)
      });
  };

  const handleCloseModalSucess = () => {
    navigate(-1)
  }

  const handleCloseModal = () => {
    setOpenModalError(false)
  }

  return (
    <>
      <PageTitle title="Visualizar notificação" />
      <form
        onSubmit={handleSubmit(onSubmit)}
        id="ativar-notificacao"
        className={styles.container}
      >
        <div className={styles.activationForm}>
          <p className={styles.title}>ATIVAÇÃO</p>

          <FormActivation
            control={control}
            errors={errors}
            disableForm
            setValue={setValue}
            defaultValues={defaultValues}
          />
        </div>

        <div className={styles.tabsForm}>
          <div>
            <p className={styles.title}>FILTROS</p>
            {isSubmitted && 
              <p className={styles.errorFilters}>
                {errors.filters?.message}
              </p>
            }
            <FilterAddActivation
              control={control}
              setValue={setValue}
              disableForm
              resetField={resetField}
              clearErrors={clearErrors}
              defaultValues={defaultValues}
              setError={setError}
              selectedBrands={[brandId]}
            />
          </div>
        </div>
      </form>

      <ModalDialogbox
        open={openModalSucess}
        type="success"
        onClose={handleCloseModalSucess}
        text="A notificação foi criada com sucesso!"
      />

      <ModalDialogbox
        open={openModalError}
        type="error"
        onClose={handleCloseModal}
        text="Erro ao concluir ação. Por favor, tente novamente mais tarde ou entre em contato com o suporte."
      />
    </>
  );
}

export default ViewNotification;
